<template id="AnaCampagneWeb">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <h2 class="text-center mt-3 mb-2">
            Campagne Web
            <b-button title="Nuova Campagna" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="NuovaCampagna()" v-b-modal.modal-edit><font-awesome-icon size="sm" icon="plus" /></b-button>
        </h2>
        <!--Spinner-->
        <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div>
        <div v-else>
            <div class="container-fluid">
                <b-table :items="CampagneWeb" :fields="fields" no-footer-sorting sort-icon-left responsive class="table col-sm-8 offset-sm-2">
                    <!-- A virtual composite column -->
                    <template v-slot:cell(swiread)="data">
                        <b-form-checkbox :id="'cb_swiread'+data.item.id"
                                         v-model="data.item.swiread"
                                         :name="'cb_swiread-'+data.item.id"
                                         value="true"
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(swiwri)="data">
                        <b-form-checkbox :id="'cb_swiwri'+data.item.id"
                                         v-model="data.item.swiwri"
                                         :name="'cb_swiwri-'+data.item.id"
                                         value="true"
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(datini)="data">
                        <span>{{GetDataFormatted(data.item.datini)}}</span>
                    </template>

                    <template v-slot:cell(datfin)="data">
                        <span>{{GetDataFormatted(data.item.datfin)}}</span>
                    </template>

                    <template v-slot:cell(id)="data">
                        <b-button class="iconlist" size="sm" @click="data.toggleDetails" variant="outline-secondary">
                            <font-awesome-icon v-if="data.detailsShowing" size="lg" icon="angle-up" />
                            <font-awesome-icon v-else size="lg" icon="angle-down" />
                        </b-button>


                        <font-awesome-icon size="lg" icon="edit" @click="OpenModal(data.item)" v-b-modal.modal-edit style="color:darkorange" />

                        <font-awesome-icon class="icon" size="lg" icon="times" @click="Delete(data.item)" />
                    </template>

                    <template v-slot:row-details="data">
                        <b-card>
                            <CampagneWeb_TipiObiettivi :idcampagna="data.item.id"></CampagneWeb_TipiObiettivi>
                        </b-card>
                    </template>


                </b-table>


            </div>
            <b-modal id="modal-edit" title="Campagna Web" ref="modal" size="lg"
                     @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Nome"
                                  :state="State"
                                  label-for="name-input"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  invalid-feedback="Nome is required"
                                  :horizontal="true">
                        <b-form-input id="name-input" v-model="CampagnaSel.descam" :state="State" required></b-form-input>
                    </b-form-group>

                    <b-form-group label="Abilitata"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="swiread_chkbox" :horizontal="true">
                        <b-form-checkbox id="swiread_chkbox" v-model="CampagnaSel.swiread"></b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="Editabile"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="swiwri_chkbox" :horizontal="true">
                        <b-form-checkbox id="swiwri_chkbox" v-model="CampagnaSel.swiwri"></b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="Livello"
                                  :state="State"
                                  label-for="livello-input"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  invalid-feedback="Nome is required"
                                  :horizontal="true">
                        <b-form-input id="livello-input" v-model="CampagnaSel.livcam" :state="State" required></b-form-input>
                    </b-form-group>


                    <b-form-group label="Inizio"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="Inizio" :horizontal="true">
                        <datetime v-model="CampagnaSel.datini"
                                  id="Inizio"
                                  class="form-control"
                                  type="date"
                                  value-zone="Europe/Rome">
                        </datetime>
                    </b-form-group>

                    <b-form-group label="Fine"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="Inizio" :horizontal="true">
                        <datetime v-model="CampagnaSel.datfin"
                                  id="Fine"
                                  class="form-control"
                                  type="date"
                                  value-zone="Europe/Rome">
                        </datetime>
                    </b-form-group>




                </form>
            </b-modal>
        </div>
    </div>
</template>

<style>

    .icon {
        color: red;
        margin-left: 10px;
    }
    .iconlist {
        color: darkslategray;
        margin-left: 10px;
        margin-right: 10px;
    }
    .vdatetime-input {
        border: 0;
        text-align: center;
        width: 100%;
    }
</style>

<script>    
    
    import axios from 'axios';
    import moment from 'moment';
    //Attenzione : temporaneamente bloccato "luxon": "^1.25.0" per un bug
    import { Datetime } from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css'
    import CampagneWeb_TipiObiettivi from './components/CampagneWeb_TipiObiettivi.vue';

    export default {
        name: 'AnaCampagneWeb',
        data() {
            return {
                errors: [],
                CampagnaSel: {},
                CampagneWeb: [],
                fields: [                    
                    {
                        key: 'descam',
                        label: 'Descrizione',
                        sortable: true
                    },
                    {
                        key: 'swiread',
                        label: 'Abilitata',
                        sortable: true                       
                    },
                    {
                        key: 'swiwri',
                        label: 'Editabile',
                        sortable: true
                    },
                    {
                        key: 'datini',
                        label: 'Inizio',
                        sortable: true
                    },
                    {
                        key: 'datfin',
                        label: 'Fine',
                        sortable: true
                    },
                    {
                        key: 'id',
                        sortable: false
                    }],
                isLoading: false,
                //SelectedEntity: Object,
                //classdef: Object,
                //KeyPropDef: Object,                
                //search: '',
                //awaitingSearch: false,
                //SelectedRowId: {
                //    type: String,
                //    default: ''
                //},
                //SwiEdit: false,                                
                //showModal:false,
                State:null,
                isNew: false
            };
        },
        components: {        
            Datetime,
            CampagneWeb_TipiObiettivi
        },
        watch: {            
        },

        methods: {
            GetCampagnewWeb() {
                let self = this;
                var Link = 'api/v1/CampagneWeb';
                axios.get(Link)
                    .then(response => {
                        self.CampagneWeb = response.data.result;
                    }).catch(e => {
                        self.errors.push(e)
                    }); 
            },
            GetDataFormatted(parData) {                
                return parData !== null ? moment(parData).format('YYYY-MM-DD') : '';
            },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.State = valid                
                return valid
            },
            resetModal() {
                this.CampagnaSel = {};
                this.State = null;
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                let self=this;
                // Exit when the form isn't valid
                if (!self.checkFormValidity()) {
                return
                }               
                this.SaveRec();
            },
            SaveRec() {
                let self = this;
                if (self.isNew) {                    
                    axios.post('api/v1/CampagneWeb/', self.CampagnaSel)
                        .then(resp => {
                            if (resp.data.result > 0) {
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.isNew = false;
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")                                    
                                })
                            }
                            else {
                                alert("Campagna Web non salvata");
                            }
                            self.GetCampagnewWeb();

                        })
                        .catch(e => {
                            if (e.response) {                                
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                    
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {                                    
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });

                }
                else {
                    
                    axios.put('api/v1/CampagneWeb/' + self.CampagnaSel.id, self.CampagnaSel)
                        .then(resp => {
                            if (resp.data.result) {                                
                                self.GetCampagnewWeb();
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")
                                })
                            }
                            else {
                                alert("Campagna Web non salvata");
                            }
                        })
                        .catch(e => {
                            if (e.response) {                                
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {                                    
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });
                }
            },

            OpenModal(Campagna){
                this.CampagnaSel = {
                    id: Campagna.id,
                    descam: Campagna.descam,
                    swiread: Campagna.swiread,
                    swiwri: Campagna.swiwri,
                    datini: Campagna.datini,
                    datfin: Campagna.datfin,
                    livcam: Campagna.livcam
                };                                
            },
            NuovaCampagna(){
                 this.CampagnaSel={
                     id: 0,
                     descam: '',
                     swiread: false,
                     swiwri: false,
                     datini: null,
                     datfin: null,
                     livcam: 0
                };
                this.isNew=true;
            },
            Delete(Campagna){
                let self=this;

                if (confirm("Cancellare la campagna " + Campagna.descam+" ?"))
                {
                    axios.delete('api/v1/CampagneWeb/' + Campagna.id)
                        .then(resp => {
                            if(!resp.data.result)
                            {                             
                                self.errors.push({ message: resp.data.errors.message});
                            }
                            this.GetCampagnewWeb();
                        })
                        .catch(e =>{
                            if (e.response) {                                 
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                    
                                    self.errors.push({ message:  e.response.data.detail});
                                }
                                else {                                  
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                        }
                    );
                }
            }
        },
        mounted() {            
            if(!this.$store.state.LoggedIn){
                this.$router.push('/');
            }
            this.GetCampagnewWeb();
        },
        updated() {
            
        }

        
    }


</script>