<template id="ElencoFileImport">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>

        <b-modal id="id_modal_edit"
                 ref="modal"
                 size="lg">

            <div slot="modal-title">
                Import Fatturato Fornitori                
            </div>

            <div>Seleziona un file e premi Upload per procedere all'import.</div>
            <div v-if="currentFile" class="progress">
                <div class="progress-bar progress-bar-info progress-bar-striped"
                     role="progressbar"
                     :aria-valuenow="progress"
                     aria-valuemin="0"
                     aria-valuemax="100"
                     :style="{ width: progress + '%' }">
                    {{ progress }}%
                </div>
            </div>

            <label class="btn btn-default">
                <input type="file" id="files" ref="files" @change="selectFile" />
            </label>

            <div class="alert alert-light" role="alert">{{ message }}</div>

            <b-card-group columns>
                <template v-if="IdFileUploaded !==0">                
                    
                    <div :title="'Id File : ' + IdFileUploaded" class="text-center col-12">
                        <font-awesome-icon  size="3x"  :class="GetStatusColor(JobStatus.file_status)" :icon="GetStatusIcon(JobStatus.file_status)" />                 
                        {{JobStatus.file_message}}                        
                    </div>
                </template>
            </b-card-group>

            <template v-slot:modal-footer="{ cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button v-if="IdFileUploaded ==0" variant="success" @click="UploadFile">
                    Upload
                </b-button>
                <b-button variant="secondary" @click="cancel()">
                    Cancel
                </b-button>
            </template>

        </b-modal>


        <h2 class="text-center mt-3 mb-2">
            File Import Fatturato
            <b-button title="Nuovo Record" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="NewUpload()" v-b-modal.modal-edit><font-awesome-icon size="sm" icon="plus" /></b-button>
        </h2>
        <div class="container">
            <b-table :items="Files" :primary-key="'file_id'" :fields="fields" class="table_Cell">
                
                <!-- File Status -->
                <template v-slot:cell(file_status)="data">
                    <font-awesome-icon size="lg" :class="GetStatusColor(data.item.file_status)" :icon="GetStatusIcon(data.item.file_status)" />
                </template>

                <!-- File Length -->
                <template v-slot:cell(file_length)="data">
                    {{GetPrettyBytes(data.item.file_length)}}
                </template>

                <!-- File Data Upload -->
                <template v-slot:cell(file_dateupd)="data">
                    {{FormatDate(data.item.file_dateupd)}}
                </template>

                <template #cell(file_id)="data">                    
                    <font-awesome-icon size="lg" class="icon_azure" icon="info-circle" @click="data.toggleDetails" :title="data.item.file_id"/>

                </template>

                <template #row-details="data">
                    <b-card>
                        <b-row class="mb-8">
                            <b-col sm="3" class="text-sm-right"><b>Status:</b></b-col>
                            <b-col>{{ data.item.file_status }}</b-col>
                        </b-row>
                        <b-row class="mb-8">
                            <b-col sm="3" class="text-sm-right"><b>Message:</b></b-col>
                            <b-col>{{ data.item.file_message }}</b-col>
                        </b-row>
                  
                        <b-row class="mb-8">
                            <b-col sm="3" class="text-sm-right"><b>File Id:</b></b-col>
                            <b-col>{{ data.item.file_id }}</b-col>
                        </b-row>

                    </b-card>


                    
                </template>

            </b-table>
        </div>


    </div>
</template>

<style>
    .icon_red {
        color: red;
    }

    .icon_orange {
        color: orange;
    }

    .icon_green {
        color: forestgreen;
    }

    .icon_azure {
        color: darkturquoise;
    }
</style>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import UploadService from "../UploadFilesService";
    //import JobStatusCard from './JobStatusCard.vue';
    //import { ModelListSelect } from 'vue-search-select';


    export default {
        name: 'ElencoFileImport',        
        data() {
            return {
                errors: [],                
                fields: [
                    {
                        key: 'file_name',
                        label: 'File Name',
                        sortable: true
                    },
                    {
                        key: 'file_length',
                        label: 'Dimensione',
                        thClass: 'text-center',
                        tdClass: 'text-right',
                        sortable: true
                    },
                    {
                        key: 'file_status',
                        label: 'Status',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        sortable: true
                    },
           
                    {
                        key: 'file_dateupd',
                        label: 'Data Caricamento',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        sortable: true
                    },
                    {
                        key: 'file_id',
                        label: 'Id',
                        sortable: false
                    },

                ],
                Files: [],
                IdFileUploaded: 0,
                JobStatus: {},
                //SelectedLayout: undefined,
                selectedFiles: undefined,
                sIcons: [
                    {
                        id: 0,
                        icon: 'clock',
                        class: 'icon_azure'
                    },
                    {
                        id: 5,
                        icon: 'play',
                        class: 'icon_green'
                    },
                    {
                        id: 11,
                        icon: 'question-circle',
                        class: 'icon_orange'
                    },
                    {
                        id: 98,
                        icon: 'exclamation',
                        class: 'icon_orange'
                    },
                    {
                        id: 99,
                        icon: 'exclamation-triangle',
                        class: 'icon_red'
                    },
                    {
                        id: 240,
                        icon: 'exclamation',
                        class: 'icon_orange'
                    },
                    {
                        id: 10,
                        icon: 'check-circle',
                        class: 'icon_green'
                    },
                ],
                currentFile: undefined,
                progress: 0,
                message: '',                
                timer: '',
            }
        },
        computed: {           
        },
        components: {
            //JobStatusCard,
            //ModelListSelect
        },
        methods: {
            selectFile() {
                console.log('selectFile');
                this.selectedFiles = this.$refs.files.files;
                console.log(this.selectedFiles.length);
            },
            FormatDate: function (val) {
                if (val) {
                    return moment(String(val)).format('DD/MM hh:mm');
                }
            },
            GetFileList() {
                let self = this;
                self.isLoading = true;
                self.Files = [];
                //self.SelectedRowId = '';
                var Link = 'api/v1/ImportFatturato';
                axios.get(Link)
                    .then(response => {
                        self.Files = response.data.result;                  
                                                
                        self.isLoading = false;
                    }).catch(e => {
                        this.errors.push(e)
                        self.isLoading = false;
                    });


            },
            GetJobStatus() {                
                console.log('GetJobStatus : ' + this.IdFileUploaded);
                let self = this;
                var Link = '/api/v1/ImportFatturato/' + self.IdFileUploaded;
                axios.get(Link)
                    .then(response => {
                        self.JobStatus = response.data.result;
                        self.message = self.JobStatus.file_message;
                        
                        if (self.JobStatus){
                                
                            if (self.JobStatus.file_status == 10) {
                                clearInterval(this.timer);
                            }

                            if (self.JobStatus.file_status == 99) {
                                clearInterval(this.timer);
                            }
                            
                        }
                        else {
                            clearInterval(this.timer);                            
                        }


                    }).catch(e => {
                        this.errors.push(e);
                    });
                

            },
            GetStatusColor: function (idstatus) {
                var tIcon = this.sIcons.find(e => e.id == idstatus);
                if (tIcon) {
                    return tIcon.class;
                }
                else {
                    return 'icon_green';
                }
            },
            GetStatusIcon: function (idstatus) {
                //console.log('icona');
                //console.log(idstatus);
                var tIcon = this.sIcons.find(e => e.id == idstatus);
                //console.log(tIcon.icon);
                if (tIcon) {
                    return tIcon.icon;
                }
                else {
                    return 'bug';
                }
            },
            UploadFile() {
                this.IdFileUploaded = 0;
                this.progress = 0;
                this.message = '';
                let self = this;
                this.currentFile = this.selectedFiles[0];

                // Parametri import
                var tParams = {};

                var Link ='api/v1/ImportFatturato/UploadFile'                
                UploadService.upload(Link, this.currentFile, tParams, this.$store.state.Token, event => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                })
                    .then(response => {
                        self.message = response.data.message;
                        self.IdFileUploaded = response.data.result;                                                
                        self.GetFileList();                                              
                        self.currentFile = undefined;
                        if (self.IdFileUploaded > 0) {
                            self.GetJobStatus();
                            self.timer = setInterval(this.GetJobStatus, 10000)
                        }
                        
                    })
                    .catch(() => {
                        self.progress = 0;
                        self.message = "Could not upload the file!";
                        self.currentFile = undefined;
                    });

                this.selectedFiles = undefined;                
            },                                    
            GetPrettyBytes: function (num) {
                // jacked from: https://github.com/sindresorhus/pretty-bytes
                if (typeof num !== 'number' || isNaN(num)) {
                    throw new TypeError('Expected a number');
                }

                var exponent;
                var unit;
                var neg = num < 0;
                var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                if (neg) {
                    num = -num;
                }

                if (num < 1) {
                    return (neg ? '-' : '') + num + ' B';
                }

                exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
                num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
                unit = units[exponent];

                return (neg ? '-' : '') + num + ' ' + unit;
            },
            NewUpload() {
                clearInterval(this.timer);
                this.$bvModal.show('id_modal_edit');
            }

        },
        mounted() {
                 
            //this.SelectedLayout = {};
            this.GetFileList();            
        },
    }
</script>