<template id="AnaTipiOb">
<div>
            <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <h2 class="text-center mt-3 mb-2">
            Tipi Obiettivi
            <b-button title="Aggiungi Tipo Obiettivo" pill variant="outline-success" style="margin-left: 12px;" align="right" 
            :to="{ path: 'AnaTipoObEdit', query: { riftipoob: 0 }}"
            ><font-awesome-icon size="sm" icon="plus" /></b-button>
        </h2>
   
        <!--Spinner-->
        <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div>
        <div v-else>
            <div class="container">
                
                <Filtri  class="offset-2"  :LoggedIn="this.$store.state.LoggedIn" v-on:change="onChangeFiltri" :SoloClasseSerie="true"></Filtri>   
                <b-row class="mb-2 mt-2 offset-2 px-5">
                    <b-col lg="8" class="my-1">
                        <b-form-group
                        label="Filtra"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            v-model="filter"
                            type="search"
                            id="filterInput"
                            placeholder="Digitare un testo da ricercare"
                            ></b-form-input>
                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''" variant="outline-secondary">
                                <font-awesome-icon icon="eraser"  />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                </b-row>
  
                <!-- 
                    <div class="col-md-12  offset-md-1">
                        <label class="col-form-label font-weight-bold " for="inpute_search">Cerca</label>
                        <input id="inpute_search" class="col-md-6 search-query st-default-search-input aa-input" aria-label="Search" type="text" v-model="search" placeholder="Search..." />
                    </div> 
                -->
                <!-- <div class="row">
                     <b-button variant="outline-primary" class="col-8 offset-2" :to="{ path: 'AnaTipoObEdit', query: { riftipoob: 0 }}" >Nuova</b-button> 
                     <router-link class="col-8 offset-2 outline-primary"  :to="{ path: 'AnaTipoObEdit', query: { riftipoob: 0 }}">
                         Nuova
                     </router-link>
                </div> -->
                <div class="row">
                    <b-table 
                        :items="this.tipiOb" :fields="fields" no-footer-sorting sort-icon-left responsive class="table "
                        :filter="filter"
                        :filterIncludedFields="filterOn">
                        <template v-slot:cell(id)="data">
                            <!-- <font-awesome-icon size="lg" icon="edit" @click="OpenModal(data.item)"  v-b-modal.modal-edit style="color:darkorange" /> -->
                            <router-link :to="{ path: 'AnaTipoObEdit', query: { riftipoob: parseInt(data.item.id, 10) }}">
                                    <font-awesome-icon size="lg" icon="edit" style="color:darkorange" />
                            </router-link>
                            <font-awesome-icon class="icon" size="lg" icon="times" @click="Delete(data.item)" />
                        </template>
                    </b-table>
                </div>
            </div>
            <!-- <b-modal id="modal-edit" title="TipoOb" ref="modal"
                
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Nome"  :state="State" label-for="name-input" invalid-feedback="Codice is required" >
                        <b-form-input id="name-input" v-model="currentTipoOb.codcon" :state="State" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Nome"  :state="State" label-for="name-input" invalid-feedback="Nome is required" >
                        <b-form-input id="name-input" v-model="currentTipoOb.descon" :state="State" required></b-form-input>
                    </b-form-group>
                </form>
            </b-modal> -->
        </div>
</div>
</template>

<style>

    .search-query {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        padding: 0 15px 0 30px;
        border: 1px solid #e3e3e3;
        color: #273849;
        outline: none;
        border-radius: 15px;
        margin-right: 10px;
        transition: border-color 0.2s ease;
        /*background: #fff url("search.png") 8px 5px no-repeat; background-size: auto;*/
        background-size: 20px;
        vertical-align: middle !important;
    }

</style>

<script>    
    //require("@/assets/search.png")
    import axios from 'axios';
    import Filtri from './components/Filtri.vue';

    export default {

        data() {
            return {
                filter:null,
                filterOn:[],
                errors: [],  
                isLoading: true,
                SelectedEntity: Object,
                classdef: Object,
                KeyPropDef: Object,
                tipiOb: [],
                fields: [                    
                    {
                        key: 'codcon',
                        label: 'Codice',
                        sortable: true
                    },
                    {
                        key: 'descon',
                        label: 'Descrizione',
                        sortable: true                       
                    },
                    {
                        key: 'datini', 
                        label:'Data Inizio', 
                        formatter: (value) => {
                                return (value)?value.substr(8,2)+'/' + value.substr(5,2)+'/'+value.substr(0,4):""
                                },
                        sortable: true
                    },
                    {
                        key: 'datfin',
                        label: 'Data Fine',
                        formatter: (value) => {
                                return (value)? value.substr(8,2)+'/' + value.substr(5,2)+'/'+value.substr(0,4):""
                                },
                        sortable: true
                    },
                    {
                        key: 'id',
                        label: '',
                        sortable: false
                    }
                    
                    ],
                search: '',
                awaitingSearch: false,
                SelectedRowId: {
                    type: String,
                    default: ''
                },
                SwiEdit: false,
                currentTipoOb:{},
                showModal:false,
                State:null,
                isNew: false
            };
        },
        components: {
            Filtri
        },
        watch: {            
        },

        methods: {
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.State = valid
                //if(!valid){console.log("form non valida :" +valid)}
                return valid
            },
            resetModal() {
                this.currentTipoOb = {}
                this.State = null
            },
            // handleOk(bvModalEvt) {
            //     // Prevent modal from closing
            //     bvModalEvt.preventDefault()
            //     // Trigger submit handler
            //     this.handleSubmit()
            // },
            // handleSubmit() {
            //     let self=this;
            //     // Exit when the form isn't valid
            //     if (!self.checkFormValidity()) {
            //     return
            //     }
            //     // Push the name to submitted names
            //    // this.submittedNames.push(this.name)
            //    if(self.isNew)
            //    { 
            //         var tnuovo={
            //                         id: 0,
            //                         descon: self.currentTipoOb.descon,
            //                         codcon: self.currentTipoOb.codcon
            //                    };
            //         //console.log("post tipoob: " + self.currentTipoOb.descon);
            //         axios.post('api/v1/TipiObiettivi/',self.currentTipoOb)
            //           .then(resp => {
            //                if(resp.data.result>0)
            //                { 
                               
            //         //console.log("post tipoob dopo response: " + tnuova.descon);
            //                     tnuovo.id= resp.data.result;
            //                     self.tipiOb.push(tnuovo); 
            //                     self.$store.commit('SetTipiOb');

            //                     // Hide the modal manually
            //                     self.$nextTick(() => {
            //                         self.isNew=false;
            //                         self.resetModal();
            //                         self.$bvModal.hide("modal-edit")
            //                     })
            //                }
            //                else
            //                {
            //                    alert("tipo obiettivo non salvata");
            //                }

            //           })
            //           .catch(e => {
            //             if (e.response) {
            //                 //console.log("e.response");
            //                 if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
            //                     //console.log("Errore in catch 1:" + e.response.data.detail);
            //                     self.errors.push({ message:  e.response.data.detail});
            //                 }
            //                 else {
            //                     //console.log("Errore in catch 2:" + e.message);
            //                     self.errors.push(e)
            //                 }
            //             }
            //             else {
            //                 //console.log("Errore in catch 3:" + e.message);
            //                 self.errors.push(e)
            //             }
            //             self.isLoading = false;
            //         });

            //    }
            //    else
            //    {
            //        var tEdit={
            //                         id: self.currentTipoOb.id,
            //                         descon: self.currentTipoOb.descon,
            //                         codcon: self.currentTipoOb.codcon
            //                    };
            //         axios.put('api/v1/TipiObiettivi/'+self.currentTipoOb.id,self.currentTipoOb)
            //           .then(resp => {
            //                if(resp.data.result)
            //                { 
            //                     var tTipoOb = self.tipiOb.find(s => s.id === tEdit.id);
                
            //                     if(tTipoOb)
            //                     {
            //                         tTipoOb.codcon= tEdit.codcon;
            //                         tTipoOb.descon= tEdit.descon;
            //                     }
                                
            //                     self.$store.commit('SetTipiOb');

            //                     // Hide the modal manually
            //                     self.$nextTick(() => {
            //                         self.resetModal();
            //                         self.$bvModal.hide("modal-edit")
            //                     })
            //                }
            //                else
            //                {
            //                    alert("Tipo obiettivo non salvato");
            //                }
            //           })
            //           .catch(e => {
            //             if (e.response) {
            //                 //console.log("e.response");
            //                 if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
            //                     //console.log("Errore in catch 1:" + e.response.data.detail);
            //                     self.errors.push({ message:  e.response.data.detail});
            //                 }
            //                 else {
            //                     //console.log("Errore in catch 2:" + e.message);
            //                     self.errors.push(e)
            //                 }
            //             }
            //             else {
            //                 //console.log("Errore in catch 3:" + e.message);
            //                 self.errors.push(e)
            //             }
            //             self.isLoading = false;
            //         });
            //    }
            // },
            // OpenModal(tipoob){
            //     this.currentTipoOb={
            //         id: tipoob.id,
            //         descon: tipoob.descon,
            //         codcon: tipoob.codcon
            //     };
            //     //this.showModal=true;
            //     //this.$bvModal.show('modal-edit');
            // },
            NuovaTipoOb(){
                 this.currentTipoOb={
                    id: 0,
                    descon: '',
                    codcon: ''
                };
                this.isNew=true;
            },
            Delete(tipoob){
                let self=this;

                if (confirm("Cancellare il tipo  "+ tipoob.descon+"?"))
                {
                    axios.delete('api/v1/TipiObiettivi/'+ tipoob.id)
                        .then(resp => {
                            if(!resp.data.result)
                            { 
                                //console.log("Errore in response:" + resp.data.errors.message);
                                self.errors.push({ message: resp.data.errors.message});
                            }
                            else
                            {
                                self.tipiOb = self.tipiOb.filter(function (item) {
                                                    return item.id !== tipoob.id
                                                });
                            }
                        })
                        .catch(e =>{
                            if (e.response) {
                                 //console.log("e.response");
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                    //console.log("Errore in catch 1:" + e.response.data.detail);
                                    self.errors.push({ message:  e.response.data.detail});
                                }
                                else {
                                    //console.log("Errore in catch 2:" + e.message);
                                    self.errors.push(e)
                                }
                            }
                            else {
                                //console.log("Errore in catch 3:" + e.message);
                                self.errors.push(e)
                            }
                        }
                    );
                }
            },

            onChangeFiltri(classe, tipoob, serie){
                let self=this;
                // console.log('classe '+classe );
                // console.log('tipoob '+tipoob );
                // console.log('serie '+serie );

                self.tipiOb=[];
                    
                var telencoClassi=[];
                if(classe=='999999'){
                    this.$store.state.Classi.forEach(c=>{telencoClassi.push(c.codclasse)});
                }
                else if(classe=='888888'){
                    this.$store.state.Classi.filter(el =>  el.abilitata ).forEach(c=>{telencoClassi.push(c.codclasse)});
                }
                else if(classe!='888888'){
                    telencoClassi.push(classe);
                }
                var telencoSerie=[];
                if(serie=='999999'){
                    this.$store.state.Series.forEach(s=>{telencoSerie.push(s.id)});
                }
                else if(serie=='888888'){
                    this.$store.state.Series.filter(el =>  el.abilitata ).forEach(s=>{telencoSerie.push(s.id)});
                }
                else if(serie!='888888'){
                    telencoSerie.push(serie);
                } 
                if(self.$store.state.TipiOb.length>0)
                {                         
                    self.$store.state.TipiOb.forEach(el => {
                        if(el.tipint==self.$store.state.TipoInt
                            &&
                            (telencoClassi.length==0 || telencoClassi.includes(el.classe)) //controllo le classi abilitate se includono quella del tipo ob
                            && 
                            (telencoSerie.length==0 || telencoSerie.includes(el.rifserie))
                            ){
                            self.tipiOb.push(el);
                        }
                    });
                }

            }
        },
        mounted() {
            if(!this.$store.state.LoggedIn){
                this.$router.push('/');
            }
            //console.log('mounted anatipiob');
            //
            if(!this.$store.state.Filtri.selSerie && !this.$store.state.Filtri.selClasse){
                //console.log('filtri default');
                this.onChangeFiltri('888888', '', '888888');
            }
            else{
                //console.log('elenco tipi ob');
                this.onChangeFiltri(this.$store.state.Filtri.selClasse, '', this.$store.state.Filtri.selSerie);
            }
            this.isLoading = false;
        },
        updated() {
            ////console.log(this.$route)
        }

        
    }


</script>