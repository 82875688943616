<template id="AnaTipoObEdit">
    <div class="container">
                <!-- Errori -->
        <b-alert v-if="successMessage" variant="success" show dismissible>
            <ul>
                <li >
                    {{successMessage}}
                </li>
            </ul>
        </b-alert>
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>

        <div class="row mr-2 ml-2 mt-3"> 
            <b-input-group  class="col-3">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled=""> Codice </b-button>
                </b-input-group-prepend>
                <b-form-input ref="inputcodcon" v-model="TipoObiettivo.codcon" type="text" append></b-form-input>
            </b-input-group>
            <b-input-group  class="col-7">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled=""> Descrizione </b-button>
                </b-input-group-prepend>
                <b-form-input v-model="TipoObiettivo.descon" type="text" append></b-form-input>
            </b-input-group>
            <b-button variant="success" v-on:click="Salva()"> Salva </b-button>
            <b-button variant="outline-warning" href="#/AnaTipiOb"> Annulla </b-button>
        </div>
        
        <b-tabs class="mr-4 ml-4 mt-3" content-class="mt-3" v-on:input="onChangeTab" >
            <b-tab title="Proprietà" class="ml-4"> 
                <div class="row">
                    <b-input-group  class="col-4">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled=""> Tipo Intestatario </b-button>
                        </b-input-group-prepend>
                        <b-form-select ref="seltipint" v-model="TipoObiettivo.tipint" >
                            <b-form-select-option value="0">Clienti</b-form-select-option>
                            <b-form-select-option value="1">Fornitori</b-form-select-option>
                            <b-form-select-option value="2">Agenti</b-form-select-option>
                            <b-form-select-option value="3">Area Manager</b-form-select-option>
                            <b-form-select-option value="99">Altro</b-form-select-option>
                        </b-form-select>
                    </b-input-group>
                    <b-input-group  class="col-4">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled=""> Serie </b-button>
                        </b-input-group-prepend>
                        <b-form-select ref="selserie" v-model="TipoObiettivo.rifserie" 
                                        :options="this.$store.state.Series"
                                        value-field="id"
                                        text-field="nomeserie" > 
                        </b-form-select>
                    </b-input-group>
                    <b-input-group  class="col-4">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled=""> Classe </b-button>
                        </b-input-group-prepend>
                        <b-form-select ref="selclasse" v-model="TipoObiettivo.classe" 
                                        :options="this.$store.state.Classi" 
                                        value-field="codclasse"
                                        text-field="desclasse"> 
                        </b-form-select>
                    </b-input-group>
                </div>
                <div class="row mt-3">
                    <b-form-checkbox-group name="tipoc" class="col-4">
                        <b-form-checkbox
                                id="checkbox-1"
                                v-model="TipoObiettivo.tipcom"
                                name="checkbox-1" 
                                :disabled="TipoObiettivo.id!=0">
                            Contratto Composto
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <h5>Data validità </h5>
                        <div  class="row mt-1 align-items-center" > 
                                <div class="col-1 px-0 text-right text-primary"> 
                                    Da 
                                </div>
                                <div class="col-4">         
                                    <b-form-input v-model="TipoObiettivo.datinitxt" type="date"></b-form-input> 
                                </div>
                                <div class="col-1 px-0 text-right text-primary"> 
                                    A
                                </div>
                                <div class="col-4">         
                                    <b-form-input v-model="TipoObiettivo.datfintxt" type="date"></b-form-input> 
                                </div> 
                        </div>
                        <!-- <b-input-group  class="col-10">
                            <b-input-group-prepend>
                                <b-button variant="outline-primary" disabled=""> da </b-button>
                            </b-input-group-prepend> 
                            <b-form-input v-model="TipoObiettivo.datinitxt" type="date"></b-form-input> 
                        </b-input-group>
                        <b-input-group  class="col-10">
                            <b-input-group-prepend>
                                <b-button variant="outline-primary" disabled="">  a&nbsp; </b-button>
                            </b-input-group-prepend> 
                            <b-form-input v-model="TipoObiettivo.datfintxt" type="date"></b-form-input> 
                        </b-input-group> -->
                    </div>
                    <div class="col-6">
                        <b-form-group name="opzioni-blocco" stacked>
                            <b-form-checkbox id ="checkbox-swiblo" v-model="TipoObiettivo.swiblo" name="checkbox-swiblo">
                                Bloccato
                            </b-form-checkbox>
                            <b-form-checkbox id="checkbox-swibloord" v-model="TipoObiettivo.swibloord" name="checkbox-swibloord" >
                                Blocco Agg. Ordinato
                            </b-form-checkbox>
                            <b-form-checkbox id="checkbox-swiblocon" v-model="TipoObiettivo.swiblocon" name="checkbox-swiblocon">
                                Blocco Agg. Consegne
                            </b-form-checkbox>
                            <b-form-checkbox id="checkbox-bitnas" v-model="TipoObiettivo.bitnas" name="checkbox-bitnas">
                                Nascosto
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                </div>
                
                
                <div class="row mt-3">
                    <b-input-group  class="">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled="">  Descrizione Estesa </b-button>
                        </b-input-group-prepend>
                        <b-form-textarea  id="txta-desestesa" v-model="TipoObiettivo.desestesa" rows="3" max-rows="6" />
                    </b-input-group>
                </div>
                <template v-if="TipoObiettivo.id!=0">
                    <div v-if="!TipoObiettivo.tipcom" class=" mt-3 mb-4">
                        <h4> Condizioni Tipo Obiettivo </h4>
                        <TipoObDet  class="row mb-2" v-for="det in TipoObDettagli" 
                                    :key="det.id" 
                                    :TipoObDet="det" 
                                    :IdTipoOb="IdTipoOb"
                                    v-on:delete="onDeleteTipoDet" />
                        <b-button variant="primary" v-on:click="AggiungiDet"> Aggiungi
                        </b-button>
                    </div>
                    <div v-else class=" mt-3 mb-4">
                        <h4> Tipo Obiettivo Composto - Tipi collegati </h4>
                        <div class="row mb-3" v-for="det in TipoObDetComposti" :key="det.id"  >
                            <!-- {{det.rifcontrattobase}}
                            <b-form-select v-model="det.rifcontrattobase"
                                        :options="TipiObiettivi"
                                        class=""
                                        value-field="id"
                                        :text-field="codcon+' - '+descon"/> -->
                            <b-input-group  class="col-6">       
                                <b-input-group-prepend>
                                    <b-button variant="outline-primary" disabled="">  Contratto collegato </b-button>
                                </b-input-group-prepend>
                                <b-select v-model="det.rifcontrattobase">
                                        <option v-for="t in TipiObiettivi" :value="t.id" :key="t.id">{{ t.codcon +' - '+ t.descon}} </option>
                                </b-select>
                                <b-input-group-append>
                                    <b-button variant="outline-danger" v-on:click="DeleteDetComposti(!det.id? det.rowKey : det.id)"> X  </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <b-button variant="primary" v-on:click="AggiungiDetComposti"> Aggiungi </b-button>
                    </div>
                </template>
            </b-tab>
            <!-- ************************ tab delle impostastazioni calcoli *********************** -->
            <b-tab title="Impostazione Calcoli">
                <div class="row">
                    <div class="col-5 mt-3">
                        <div class="d-flex">
                            <b-form-group  stacked>
                                <b-form-checkbox id ="checkbox-swipercval" v-model="TipoObiettivo.swipercval" name="checkbox-swipercval">
                                    Premio a Valore Fisso
                                </b-form-checkbox>
                                <b-form-checkbox v-if="TipoObiettivo.tipcom" id="checkbox-swiripcom" v-model="TipoObiettivo.swiripcom" name="checkbox-swiripcom" >
                                    Salva Premi Dettagliati
                                </b-form-checkbox>
                                <b-form-checkbox id="checkbox-swiqtaval" v-model="TipoObiettivo.swiqtaval" name="checkbox-swiqtaval">
                                    Considera le quantità
                                </b-form-checkbox>
                                <b-form-checkbox id="checkbox-swiqtasco" v-model="TipoObiettivo.swiqtasco" name="checkbox-swiqtasco">
                                    Solo qta di vendita
                                </b-form-checkbox>
                                <h5  v-if="TipoObiettivo.tipcom"  class="mt-2" >Tipologia</h5> 
                                <b-form-checkbox  v-if="TipoObiettivo.tipcom" ref="cbSwiComC" id ="cbSwiComC" v-model="TipoObiettivo.swicomc" name="checkbox-swicomc">
                                    Se tutti obiettivi raggiunti
                                </b-form-checkbox>
                                <b-form-checkbox  v-if="TipoObiettivo.tipcom" id="checkbox-swicoma" v-model="TipoObiettivo.swicoma" name="checkbox-swicoma" >
                                    Se Cumulato raggiunge Target
                                </b-form-checkbox>
                                <b-form-checkbox  v-if="TipoObiettivo.tipcom" id="checkbox-swicomaperori" v-model="TipoObiettivo.swicomaperori" name="checkbox-swicomaperori">
                                    Applica Percentuale Originale
                                </b-form-checkbox>
                                <b-form-checkbox id="checkbox-swiprevaluni" v-model="TipoObiettivo.swiprevaluni" name="checkbox-swiprevaluni">
                                    Premio Valore Unitario
                                </b-form-checkbox> 
                            </b-form-group>
                        </div>
                        <!-- <div class="row mt-3">
                    <div class="col-6">
                        <b-form-checkbox id ="checkbox-swicalperdiff" v-model="TipoObiettivo.swicalperdiff" name="checkbox-swicalperdiff">
                                Calcola premi su periodo differente
                        </b-form-checkbox>
                    </div>
                </div> -->
                <!-- <div  class="row mt-1 mb-1" v-if="TipoObiettivo.swicalperdiff"> -->
                    <div class="d-flex mt-5 mb-2" v-if="TipoObiettivo.swicalperdiff">
                        Date Periodo Differente
                    </div>
                    <div class="d-flex  align-items-center" v-if="TipoObiettivo.swicalperdiff">
                        <!-- <div class="row  align-items-center"> -->
                            <div class="col-1 px-0 text-right text-primary"> 
                                Da 
                            </div>
                            <div class="col-5">         
                                <b-form-input v-model="TipoObiettivo.datinipremitxt" type="date"></b-form-input> 
                            </div>
                            <div class="col-1 px-0 text-right text-primary"> 
                                A
                            </div>
                            <div class="col-5 ">         
                                <b-form-input v-model="TipoObiettivo.datfinpremitxt" type="date"></b-form-input> 
                            </div>
                        <!-- </div> -->
                    </div> 
                <!-- </div> -->
                    </div>
                    <div class="col-6 mt-3">
                        <table class="table b-table table-bordered table-borderless table-light table-sm text-center">
                            <thead>
                                <tr>    
                                    <th>&nbsp;</th>
                                    <th>Target</th>
                                    <th :class="TipoObiettivo.swicalprediff?'':'text-secondary'">Premio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-right">Fatturato :</td>
                                    <td><b-form-checkbox id="checkbox-trgswifatt" v-model="TipoObiettivo.trgswifatt" name="checkbox-trgswifatt"/></td>
                                    <td><b-form-checkbox id="checkbox-swifatt" v-model="TipoObiettivo.swifatt" name="checkbox-swifatt" v-if="TipoObiettivo.swicalprediff"/></td>
                                </tr>
                                <tr>
                                    <td class="text-right">Consegnato :</td>
                                    <td><b-form-checkbox id="checkbox-trgswicons" v-model="TipoObiettivo.trgswicons" name="checkbox-trgswicons"/></td>
                                    <td><b-form-checkbox id="checkbox-swicons" v-model="TipoObiettivo.swicons" name="checkbox-swicons" v-if="TipoObiettivo.swicalprediff"/></td>
                                </tr>
                                <tr>
                                    <td class="text-right">Ordinato :</td>
                                    <td><b-form-checkbox id="checkbox-trgswiord" v-model="TipoObiettivo.trgswiord" name="checkbox-trgswiord"/></td>
                                    <td><b-form-checkbox id="checkbox-swiord" v-model="TipoObiettivo.swiord" name="checkbox-swiord" v-if="TipoObiettivo.swicalprediff"/></td>
                                </tr>
                                <tr>
                                    <td class="text-right">Fatturato Lordo :</td>
                                    <td><b-form-checkbox id="checkbox-trgswifattlordo" v-model="TipoObiettivo.trgswifattlordo" name="checkbox-trgswifattlordo"/></td>
                                    <td><b-form-checkbox id="checkbox-swifattlordo" v-model="TipoObiettivo.swifattlordo" name="checkbox-swifattlordo" v-if="TipoObiettivo.swicalprediff"/></td>
                                </tr>
                                <tr>
                                    <td class="text-right">Fatturato Premi :</td>
                                    <td><b-form-checkbox id="checkbox-trgswifattpremi" v-model="TipoObiettivo.trgswifattpremi" name="checkbox-trgswifattpremi"/></td>
                                    <td><b-form-checkbox id="checkbox-swifattpremi" v-model="TipoObiettivo.swifattpremi" name="checkbox-swifattpremi" v-if="TipoObiettivo.swicalprediff"/></td>
                                </tr>
                                <tr>
                                    <td class="text-right">Fatturato periodo diff. :</td>
                                    <td>&nbsp;</td>
                                    <td><b-form-checkbox id="checkbox-swicalperdiff" v-model="TipoObiettivo.swicalperdiff" name="checkbox-swifattpremi" v-if="TipoObiettivo.swicalprediff"/></td>
                                </tr>
                                <tr>
                                    <td class="text-right">&nbsp;</td>
                                    <td colspan="2">
                                            <b-form-checkbox id="checkbox-swicalprediff" v-model="TipoObiettivo.swicalprediff" name="checkbox-swicalprediff">
                                                Calcola Premi su Valori Differenti
                                            </b-form-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <b-input-group class="col-3">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled="">  % Target 1 : </b-button>
                        </b-input-group-prepend>
                        <b-form-input type="number"  id="nb-perctarget1" v-model="TipoObiettivo.perctarget1" max="100" min="0"/>
                    </b-input-group>
                    <b-input-group class="col-3">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled=""> Tolleranza : </b-button>
                        </b-input-group-prepend>
                        <b-form-input type="number"  id="nb-toll" v-model="TipoObiettivo.toll" max="100" min="0"/>
                    </b-input-group>
                    <b-input-group class="col-3">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled="">  % Target 2 : </b-button>
                        </b-input-group-prepend>
                        <b-form-input type="number"  id="nb-perctarget2" v-model="TipoObiettivo.perctarget2" max="100" min="0"/>
                    </b-input-group>
                    <b-input-group class="col-3">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled="">  % Target 3 : </b-button>
                        </b-input-group-prepend>
                        <b-form-input type="number"  id="nb-perctarget3" v-model="TipoObiettivo.perctarget3" max="100" min="0"/>
                    </b-input-group>
                </div>
                <div class="row mt-2">
                    <b-input-group class="col-3">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled="">  % Target Ridotto : </b-button>
                        </b-input-group-prepend>
                        <b-form-input type="number"  id="nb-perctargetrid" v-model="TipoObiettivo.perctargetrid" max="100" min="0"/>
                    </b-input-group>
                    <b-input-group class="col-3">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled=""> Tolleranza Ridotta : </b-button>
                        </b-input-group-prepend>
                        <b-form-input type="number"  id="nb-toll2" v-model="TipoObiettivo.toll2" max="100" min="0"/>
                    </b-input-group>
                    <b-input-group v-if="TipoObiettivo.tipcom" class="col-3">
                        <b-input-group-prepend>
                            <b-button variant="outline-primary" disabled=""> Contratto Master : </b-button>
                        </b-input-group-prepend>
                        <b-select v-model="TipoObiettivo.rifconmas">
                            <option v-for="t in TipiObiettivi" :value="t.id" :key="t.id">{{ t.codcon +' - '+ t.descon}} </option>
                        </b-select>
                    </b-input-group>
                    <b-input-group class="col-2">
                        <b-button  variant="primary"> Sconti
                        </b-button>
                    </b-input-group>
                    <!--campo non più usato <b-input-group class="col-1">
                        <b-form-input type="number"  id="nb-scontolistino" v-model="TipoObiettivo.scontolistino" max="100" min="0"/>
                    </b-input-group> -->
                </div>
            </b-tab>

            <!-- ************************ tab delle regole liquidazioni *********************** -->
            <b-tab title="Regole Liquidazione" lazy>
                   <h4> Condizioni liquidazioni </h4>
                <div class="container">
                    <TipoObDetLiq  class="row mb-2" v-for="det in TipoObDetLiquidazioni" 
                                :key="det.keytipliq" 
                                :TipoObDetLiq="det" 
                                :IdTipoOb="IdTipoOb"
                                v-on:delete="onDeleteTipoDetLiq" />
                </div>
                    <b-button variant="primary" v-on:click="AggiungiDetLiq"> Aggiungi </b-button>
            </b-tab>
        </b-tabs>
        
    </div>
</template>
<script >
    import axios from 'axios';
    import TipoObDet from './components/TipoObDet.vue';
    import TipoObDetLiq from './components/TipoObDetLiq.vue';
 

export default {      
    props: {
        IdTipoOb: {
            type: Number,
            required: true
        } 
    },  
    components:{
        TipoObDet, TipoObDetLiq
    },

    data() {
        return {
            successMessage:'',
            errors: [],  
            Username: '',
            Password: '',
            TipoObiettivo: {}, //oggetto in modifica
            TipoObDettagli:[],
            TipoObDetComposti:[],
            TipiObiettivi:{},       //elenco dei tipi per popolare il contratto composto, della stessa serie del principale, TODO: popolarlo solo quando serve davvero
            TipoObDetLiquidazioni:{},
            pListDetDelete:[],
            pListDetComDelete:[],
            pListDetLiqDelete:[],
        };
    }, 
    watch: { 
        'TipoObiettivo.rifserie': {
                immediate: true,
                deep: true,
                handler(newVal) {
                    if(this.TipoObiettivo.tipcom){
                        this.TipiObiettivi= this.$store.state.TipiOb.filter(t=> t.rifserie===newVal && t.id!==this.IdTipoOb);
                    }
                }
        },
        'TipoObiettivo.datinitxt':function(newval) {
            this.TipoObiettivo.datini=newval;
        },
        'TipoObiettivo.datfintxt':function(newval) {
            this.TipoObiettivo.datfin=newval;
        },
        'TipoObiettivo.datinipremitxt':function(newval) {
            this.TipoObiettivo.datinipremi=newval;
        },
        'TipoObiettivo.datfinpremitxt':function(newval) {
            this.TipoObiettivo.datfinpremi=newval;
        },
        'TipoObiettivo.swicalperdiff':function(newval){
            if(newval){
                if(this.TipoObiettivo.datinipremitxt=='0001-01-01'){
                    this.TipoObiettivo.datinipremitxt=this.TipoObiettivo.datinitxt;
                    this.TipoObiettivo.datinipremi=this.TipoObiettivo.datini;
                }
                if(this.TipoObiettivo.datfinpremi=='0001-01-01'){
                    this.TipoObiettivo.datfinpremitxt=this.TipoObiettivo.datfintxt;
                    this.TipoObiettivo.datfinpremi=this.TipoObiettivo.datfin;
                }
                this.TipoObiettivo.swifatt= false;
                this.TipoObiettivo.swicons= false;
                this.TipoObiettivo.swiord= false;
                this.TipoObiettivo.swifattlordo= false;
                this.TipoObiettivo.swifattpremi= false;
            }
        },
        'TipoObiettivo.swifatt':function(newval){
            if(newval){
                this.TipoObiettivo.swifattlordo= false;
                this.TipoObiettivo.swifattpremi= false;
                this.TipoObiettivo.swicalperdiff=false;
            }
        },
        'TipoObiettivo.swifattlordo':function(newval){
            if(newval){
                this.TipoObiettivo.swifatt= false;
                this.TipoObiettivo.swifattpremi= false;
                this.TipoObiettivo.swicalperdiff=false;
            }
        },
        'TipoObiettivo.swifattpremi':function(newval){
            if(newval){
                this.TipoObiettivo.swifattlordo= false;
                this.TipoObiettivo.swifatt= false;
                this.TipoObiettivo.swicalperdiff=false;
            }
        }
    },
    methods:{
        AggiungiDet(){
            var tNewDet={
                campofiltro:0,
                rifcontratto:this.TipoObiettivo.id,
                tipocond:0,
                val1:'',
                id:0,
                rowKey: -1* this.TipoObDettagli.length
            };
            this.TipoObDettagli.push(tNewDet);
        },
        onDeleteTipoDet(idTipoDet){
            
            console.log( 'onDeleteTipoDet '+idTipoDet)
            if(idTipoDet>0){
                this.TipoObDettagli= this.TipoObDettagli.filter(d=> d.id !== idTipoDet);
                this.pListDetDelete.push(idTipoDet);
            }
            else{
                this.TipoObDettagli= this.TipoObDettagli.filter(d=> d.rowKey !== idTipoDet);
            }
        },
        AggiungiDetLiq(){
            var tNewDet={
                datsca: "",
                perliq: 0,
                keytipliq: 0,
                rifobbmix: 0,
                riftipobb: this.TipoObiettivo.id,
                tiporegola: 0,
                rowKey: -1* this.TipoObDetLiquidazioni.length
            };
            this.TipoObDetLiquidazioni.push(tNewDet);
        },
        onDeleteTipoDetLiq(idTipoDetLiq){
            
            console.log( 'onDeleteTipoDetLiq '+idTipoDetLiq)
            if(idTipoDetLiq>0){
                this.TipoObDetLiquidazioni = this.TipoObDetLiquidazioni.filter(d=> d.keytipliq !== idTipoDetLiq);
                this.pListDetLiqDelete.push(idTipoDetLiq);
            }
            else{
                this.TipoObDetLiquidazioni= this.TipoObDetLiquidazioni.filter(d=> d.rowKey !== idTipoDetLiq);
            }
        },
        AggiungiDetComposti(){
            var tNewDet={
                id: 0,
                rifcontrattobase: 0,
                rifcontrattocom: this.TipoObiettivo.id,
                rowKey: -1* this.TipoObDetComposti.length
            };
            this.TipoObDetComposti.push(tNewDet);
        },
        DeleteDetComposti(idTipoDetC){
            console.log( 'DeleteDetComposti '+idTipoDetC)
            if(idTipoDetC>0){
                this.TipoObDetComposti= this.TipoObDetComposti.filter(d=> d.id !== idTipoDetC);
                this.pListDetComDelete.push(idTipoDetC);
            }
            else{
                this.TipoObDetComposti= this.TipoObDetComposti.filter(d=> d.rowKey !== idTipoDetC);
            }
        },
        pCheckData(){
            var tValRet = true;

            if (!this.TipoObiettivo.codcon)
            {
                alert("Inserire un valore per il codice tipo obiettivo.");
                this.$refs.inputcodcon.$el.focus();
                return false;
            }
            
            if (!this.TipoObiettivo.tipint && this.TipoObiettivo.tipint!==0)
            {
                alert("Inserire il tipo di intestatario.");
                this.$refs.seltipint.$el.focus();
                return false;
            }
            if (!this.TipoObiettivo.rifserie)
            {
                alert("Inserire la serie.");
                this.$refs.selserie.$el.focus();
                return false;
            }
            if (!this.TipoObiettivo.classe)
            {
                alert("Inserire la classe.");
                this.$refs.selclasse.$el.focus();
                return false;
            }

            if (this.TipoObiettivo.tipcom)
            {
                if (!this.TipoObiettivo.swicoma  && !this.TipoObiettivo.swicomc)
                {
                    alert("Selezionare almeno una tipologia di calcolo del contratto composto.");
                    this.$refs.cbSwiComC.$el.Focus();
                    return false;
                }
            }
            return tValRet;
        },
        Salva(){
            let self=this;

            if(!this.pCheckData()){return;}
            
            // //forzatura per errata gestione del tipo campo, lo forzo con un valore int perchè nel DB è memorizzato così
            // this.TipoObiettivo.swipercval = this.TipoObiettivo.swipercvalbool?1:0; 
            // this.TipoObiettivo.swiqtaval = this.TipoObiettivo.swiqtavalbool?1:0; 
            // this.TipoObiettivo.swiprevaluni= this.TipoObiettivo.swiprevalunibool?1:0;

            //salvo il TipoObiettivo
            axios.post('api/v1/TipiObiettivi/',this.TipoObiettivo)
                .then(resp => {
                    if(resp.data.result)
                    { 
                        this.TipoObiettivo.id=resp.data.result;
                        console.log('Tipo obiettivo salvato id : ' + this.TipoObiettivo.id);
                        this.SalvaDettagli();
                        setTimeout(() => {   
                            this.successMessage="Tipi Obiettivo Salvato";
                        },600);

                        this.$store.commit('SetTipiOb');
                    }
                    else {
                        alert("Testata Tipo obiettivo NON salvata")
                        return;
                    }
                })
                .catch(e => {
                if (e.response) {
                    //console.log("e.response");
                    if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                        //console.log("Errore in catch 1:" + e.response.data.detail);
                        self.errors.push({ message:  e.response.data.detail});
                    }
                    else {
                        //console.log("Errore in catch 2:" + e.message);
                        self.errors.push(e)
                    }
                }
                else {
                    //console.log("Errore in catch 3:" + e.message);
                    self.errors.push(e)
                } 
            });
        },
        SalvaDettagli(){
            if(this.TipoObiettivo.tipcom){
                //faccio pulizia di quelli eliminati, se erano degli id validi
                this.pListDetComDelete.forEach(d =>{
                    axios.delete('api/v1/TipiObiettiviDetComposti/'+d)
                    .catch(e=>{
                        this.errors.push(e);
                        return;
                    });
                });
                if(Object.keys(this.TipoObDetComposti).length > 0){
                    this.TipoObDetComposti.forEach(d=>{
                        axios.post('api/v1/TipiObiettiviDetComposti/',d)
                        .then(r=>{
                            //console.log("obiettivo detcomposto salvato " + d.id + "     resp-> " + r.result);
                            d.id = r.data.result;
                        })
                        .catch(e=>{
                            this.errors.push(e);
                            return;
                        });
                    });
                }
            }
            else{
                //faccio pulizia di quelli eliminati, se erano degli id validi
                this.pListDetDelete.forEach(d =>{
                    axios.delete('api/v1/TipiObiettiviDet/'+d)
                    .catch(e=>{
                        this.errors.push(e);
                        return;
                    });
                });
                if(Object.keys(this.TipoObDettagli).length > 0){
                    axios.put('api/v1/TipiObiettiviDet/Multiput',this.TipoObDettagli)
                        .then(resp=>{
                            if(!resp.data.isError){
                                var Link = 'api/v1/TipiObiettiviDet/childof/'+this.IdTipoOb;
                                axios.get(Link,)//, state.Headers)
                                    .then(response => {
                                            this.TipoObDettagli = response.data.result;
                                    }).catch(e => {
                                        this.errors.push(e)
                                    });
                            }
                        })
                        .catch(e=>{
                            this.errors.push(e);
                        });
                    // this.TipoObDettagli.forEach(d=>{
                    //     setTimeout(() => {                        
                    //         axios.post('api/v1/TipiObiettiviDet/',d)
                    //             .then(r=>{
                    //                 //console.log("obiettivo det salvato " + d.id + "     resp-> " + r.data.result);
                    //                 d.id = r.data.result;
                    //             })
                    //             .catch(e=>{
                    //                 this.errors.push(e);
                    //                 return;
                    //             });
                    //     }, 700);
                       
                    // });
                }
            }
            
            //cancello e salvo le liquidazioni
            //faccio pulizia di quelli eliminati, se erano degli id validi
            this.pListDetLiqDelete.forEach(d =>{
                axios.delete('api/v1/TipoObiettivoLiquidazioni/'+d)
                .then(r=>{
                    console.log("liquidazione det cancellata " + d.id + "     resp-> " + r.data.result);
                })
                .catch(e=>{
                    this.errors.push(e);
                    return;
                });
            });
            if(Object.keys(this.TipoObDetLiquidazioni).length > 0){
                this.TipoObDetLiquidazioni.forEach(d=>{
                    axios.post('api/v1/TipoObiettivoLiquidazioni/',d)
                    .then(r=>{
                        console.log("obiettivo det salvato " + d.id + "     resp-> " + r.data.result);
                        d.id = r.data.result;
                    })
                .catch(e=>{
                        this.errors.push(e);
                        return;
                    });
                });
            }
        },
        onChangeTab(tabindex){ 
            //carico alla prima apertura l'elenco liquidazioni
            if(tabindex==2){ 
                if(Object.keys(this.TipoObDetLiquidazioni).length === 0){
                    var Link = 'api/v1/TipoObiettivoLiquidazioni/childof/'+this.IdTipoOb;
                    axios.get(Link,)//, state.Headers)
                        .then(response => {
                                this.TipoObDetLiquidazioni = response.data.result;
                        }).catch(e => {
                            this.errors.push(e)
                        });
                }
            }
        }
    },
    mounted (){
        if(!this.$store.state.LoggedIn){
            this.$router.push('/');
        }

        if(this.IdTipoOb==0){
            this.TipoObiettivo={
                "bitnas": false,
                "swiblo": false,
                "swiblocon": false,
                "swibloord": false,
                "swicalprediff": false,
                "swicoma": false,
                "swicomaperori": false,
                "swicomc": false,
                "swifattlordo": false,
                "swifattpremi": false,
                "swiripcom": false,
                "trgswicons": false,
                "trgswifatt": false,
                "trgswifattlordo": false,
                "trgswifattpremi": false,
                "trgswiord": false,
                "swicons": false,
                "swifatt": false,
                "swiord": false,
                "tipcom": false,
                "datfintxt": new Date().getFullYear()+ "-12-31",
                "datinitxt":  new Date().getFullYear()+ "-01-01",
                "scontolistino": 0,
                "perctarget1": 0,
                "perctarget2": 0,
                "perctarget3": 0,
                "perctargetrid": 0,
                "toll": 0,
                "toll2": 0,
                "id": 0,
                "rifcamweb": 0,
                "rifserie": 0,
                "swiqtaval": 0,
                //'swiqtavalbool':false,
                "tipint": this.$store.state.TipoInt,
                "rifconmas": 0,
                "swipercval": 0,
                //"swipercvalbool":false,
                "swiprevaluni": 0,
                //'swiprevalunibool':false,
                "swiqtasco": 0,
                "swivalpreuni": 0,
                "classe": "",
                "codcon": "",
                "descon": "",
                "desestesa": "",
                "swicalperdiff": false,
                "datfinpremitxt": new Date().getFullYear()+ "-12-31",
                "datinipremitxt":  new Date().getFullYear()+ "-01-01",
                }
        }
        else
        {
            //leggo l'anagrafica INTERA dal server
            var Link = 'api/v1/TipiObiettivi/'+this.IdTipoOb;
            axios.get(Link,)//, state.Headers)
                .then(response => {
                        this.TipoObiettivo = response.data.result;
                        
                        // this.TipoObiettivo.swipercvalbool = this.TipoObiettivo.swipercval!=0;
                        // this.TipoObiettivo.swiprevalunibool=this.TipoObiettivo.swiprevaluni!=0;
                        // this.TipoObiettivo.swiqtavalbool=this.TipoObiettivo.swiqtaval!=0;

                        if(!this.TipoObiettivo.tipcom){
                            Link = 'api/v1/TipiObiettiviDet/childof/'+this.IdTipoOb;
                            axios.get(Link,)//, state.Headers)
                                .then(response => {
                                        this.TipoObDettagli = response.data.result;
                                }).catch(e => {
                                    this.errors.push(e)
                                });
                        }
                        else{
                            Link = 'api/v1/TipiObiettiviDetComposti/childof/'+this.IdTipoOb;
                            axios.get(Link,)//, state.Headers)
                                .then(response => {
                                        this.TipoObDetComposti = response.data.result;
                                }).catch(e => {
                                    this.errors.push(e)
                                });
                        }
                }).catch(e => {
                    this.errors.push(e)
                });
        }
       
    }
}
</script>