<template id="GenericoElenco">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <h2 class="text-center mt-3 mb-2">
            {{Title}}
            <b-button title="Nuovo Record" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="NuovoRecord()" v-b-modal.modal-edit><font-awesome-icon size="sm" icon="plus" /></b-button>
        </h2>
        <!--Spinner-->
        <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div>
        <div v-else>
            <div class="container">
                <b-table :items="Elements" :fields="fields"  hover  no-footer-sorting sort-icon-left responsive class="table col-sm-10 offset-sm-1">
                    <template v-slot:cell(_id)="data">                        
                        <font-awesome-icon  icon="edit" @click="OpenModal(data.item)" style="color:#adb5bd; cursor:pointer;" />
                    </template>
                </b-table>                
            </div>
            <b-modal id="modal-edit" title="Edit" ref="modal" size="lg"
                     @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit">

                    <b-form-group v-for="col of cols"
                                  :key="col"
                                  :label="col"
                                  :state="State"
                                  label-for="name-input"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  invalid-feedback="Nome is required"
                                  :horizontal="true">
                        <b-form-input id="name-input" v-model="RecordSel[col]"  :disabled="(col == KeyProp) && isNew==false" :state="State" required></b-form-input>
                    </b-form-group>
                </form>


                <template v-slot:modal-footer>

                    <div v-if="SwiDel">
                        <font-awesome-icon variant="danger" size="lg" icon="exclamation-triangle" style="color:red" /> <b>Are you sure ?  </b>
                        <b-button @click="Close">
                            Cancel
                        </b-button>
                    </div>
                    <b-button v-if="!isNew" variant="danger" class="mr-auto" @click="Delete">
                        <font-awesome-icon size="sm" icon="trash-alt" /> Delete
                    </b-button>
                                       

                    <b-button v-if="!SwiDel" variant="success" @click="SaveRec">
                        <font-awesome-icon size="sm" icon="save" /> Save
                    </b-button>
                    <b-button v-if="!SwiDel" @click="Close">
                        Cancel
                    </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<style>

    .grayelement {
        color: rgb(50, 49, 48);        
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: orange;
        background-color: orange;
    }

    .icon {
        color: red;
        margin-left: 10px;
    }
    .iconlist {
        color: darkslategray;
        margin-left: 10px;
        margin-right: 10px;
    }
    .vdatetime-input {
        border: 0;
        text-align: center;
        width: 100%;
    }

    .table_header
    {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
        font-size: 0.8rem;        
        color: rgb(50, 49, 48);
        white-space: nowrap;
        text-align: left;
        cursor: default;
    }
        
</style>

<script>    
    
    import axios from 'axios';
    //import moment from 'moment';
    
    export default {
        name: 'GenericoElenco',
        props: {
            EntityType: String,
            Title: String,
            KeyProp: String
        },
        data() {
            return {
                cols: [],
                errors: [],
                RecordSel: {},
                Elements: [],
                fields: [],
                isLoading: false,                   
                State: null,
                SwiDel: false,                
                isNew: false
            };
        },
        components: {            
        },
        watch: {  
            EntityType: {
                immediate: true,
                handler() {
                    this.isLoading = true;
                    this.GetElenco();
                }
            }
        },
        methods: {
            Close() {
                this.SwiDel = false;
                this.isNew = false;
                this.resetModal();
                this.$bvModal.hide("modal-edit")
            },
            GetElenco() {
                let self = this;
                
                var Link = 'api/_gen/'+self.EntityType;
                axios.get(Link)
                    .then(response => {
                        self.Elements = response.data.result;


                        if (self.Elements.length > 0) {
                            self.fields = [];
                            self.cols = Object.keys(self.Elements[0]);

                            Object.keys(self.Elements[0]).forEach(key => {
                                //console.log("key: ", key);
                                let tCol = {};
                                tCol.key = key;
                                tCol.label = key;
                                tCol.thClass = 'table_header';
                                tCol.sortable = true;

                                self.fields.push(tCol);
                            });

                            let tColEdit = {};
                            tColEdit.key = '_id';
                            tColEdit.label = '';
                            tColEdit.thStyle = "width: 5%";
                            tColEdit.sortable = false;
                            self.fields.push(tColEdit);
                        }
                        this.isLoading = false;
                    }).catch(e => {
                        self.errors.push(e)
                        this.isLoading = false;
                    });
            },            
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.State = valid
                return valid
            },
            resetModal() {
                this.RecordSel = {};
                this.State = null;                
            },           
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                let self=this;
                // Exit when the form isn't valid
                if (!self.checkFormValidity()) {
                return
                }               
                this.SaveRec();
            },
            SaveRec() {
                let self = this;
                var Link = '';                
                if (self.isNew) {     
                    Link = 'api/_gen/' + self.EntityType;
                    axios.post(Link, self.RecordSel)
                        .then(resp => {
                            if (resp.data.result.length > 0) {
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.Close();                                    
                                })
                            }
                            else {
                                alert("Elemento non salvato");
                            }
                            self.GetElenco();

                        })
                        .catch(e => {
                            if (e.response) {                                
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                    
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {                                    
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });

                }
                else {
                    Link = 'api/_gen/' + self.EntityType;
                    axios.put(Link , self.RecordSel)
                        .then(resp => {
                            if (resp.data.result) {                                
                                self.GetElenco();
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")
                                })
                            }
                            else {
                                alert("Elemento non salvato");
                            }
                        })
                        .catch(e => {
                            if (e.response) {                                
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {                                    
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });
                }
            },

            OpenModal(parRecord) {
                this.SwiDel = false;
                this.RecordSel = parRecord;             
                this.$bvModal.show('modal-edit');
            },
            NuovoRecord() {
                this.RecordSel = {};
                this.isNew = true;
                this.SwiDel = false;
            },
            Delete() {
                if (this.isNew) {
                    this.Close();
                    return;
                }
                if (!this.SwiDel) {
                    this.SwiDel = true;
                    return;
                }
                else {
                    let self = this;
                    var Link = 'api/_gen/' + self.EntityType + '/' + self.RecordSel[self.KeyProp];
                    axios.delete(Link)
                        .then(resp => {
                            if (!resp.data.result) {
                                self.errors.push({ message: resp.data.errors.message });
                            }
                            this.Close();
                            this.GetElenco();
                        })
                        .catch(e => {
                            if (e.response) {
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {
                                    self.errors.push(e)
                                }
                            }
                            else {
                                self.errors.push(e)
                            }
                        }
                        );
                }                
            }
        },
        mounted() {            
            if(!this.$store.state.LoggedIn){
                this.$router.push('/');
            }
            this.GetElenco();
        },
        updated() {                      
        }
    }

</script>