<template>
    <div>
        <div v-if="SwiSalvato">
            <h3 class="text-primary">Contratto salvato</h3>
        </div>
        <template v-else>
            <b-spinner v-if="isLoading" variant="primary" class="col-4 offset-4 text-center ml-5" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')">in costruzione</b-spinner>
            <div v-else>
                <div class="row">
                    
                    <b-input-group v-if="IdContratto==0" class="col-12 col-md-8 ml-4 mb-4"  >
                        <b-input-group-prepend>
                            <b-button variant="outline-primary">Sel.Intestatario</b-button>
                        </b-input-group-prepend>
    
                        <model-list-select 
                            :list="ElencoCli"
                            v-model="Contratto.rifint"
                            class="col-12 col-sm-7 col-md-9"
                            option-value="cod"
                            option-text="des"
                            placeholder="digita almeno 3 caratteri per ricercare"
                            @searchchange="UpdateSelectListClienti"
                            
                        ></model-list-select>
                    </b-input-group>
                </div>
                <div class="row">
                    <b-input-group class="col-12 col-md-8 ml-4 mr-4"  >
                        <b-input-group-prepend>
                            <b-button variant="outline-primary">Tipo Contratto</b-button>
                        </b-input-group-prepend>

                        <!-- <b-form-select  v-model="Contratto.riftipocontratto"
                                        :options="ElencoTipiOb" 
                                        value-field="id"
                                        text-field="coddes"
                                        disabled-field="notEnabled"/>  -->
                        <model-list-select 
                            :list="ElencoTipiOb"
                            v-model="Contratto.riftipocontratto"
                            class="col-12 col-md-9"
                            option-value="id"
                            option-text="coddes" 
                            @searchchange="UpdateSelectListTipoOb"
                            
                        ></model-list-select>
                    </b-input-group>
                    <!-- <div v-if="IdContratto!=0" class="col-1 offset-1"> <b-button variant="outline-success" @click="ApriContrattiInt"> {{StrDettagli?StrDettagli:'Altri Int.'}} </b-button></div> -->
                    <div :class="IdContratto!=0?'col-1':'col-1 offset-2' "> <b-button variant="outline-secondary" @click="SalvaContratto"> Salva </b-button></div>
                    <div v-if="IdContratto!=0"  class="col-1"> <b-button variant="outline-danger" @click="EliminaContratto">Elimina </b-button></div>
                </div>
                <b-tabs  class="mr-4 ml-4 mt-3" content-class="mt-3" >
                    <b-tab title="Impostazioni Calcoli" class=""> 
                        <div class="row">   
                            <div class="col-5 mt-3">
                                <table class="table b-table table-bordered table-borderless table-light table-sm text-center">
                                    <thead>
                                        <tr>    
                                            <th>&nbsp;</th>
                                            <th>Target</th>
                                            <th :class="Contratto.swicalprediff?'':'text-secondary'"> Premio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-right">Fatturato :</td>
                                            <td><b-form-checkbox :id="'checkbox-trgswifatt_'+IdContratto" v-model="Contratto.trgswifatt" name="checkbox-trgswifatt"/></td>
                                            <td><b-form-checkbox :id="'checkbox-swifatt_'+IdContratto" v-model="Contratto.swifatt" name="checkbox-swifatt"  v-if="Contratto.swicalprediff"/></td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Consegnato :</td>
                                            <td><b-form-checkbox :id="'checkbox-trgswicons_'+IdContratto" v-model="Contratto.trgswicons" name="checkbox-trgswicons"/></td>
                                            <td><b-form-checkbox :id="'checkbox-swicons_'+IdContratto" v-model="Contratto.swicons" name="checkbox-swicons"  v-if="Contratto.swicalprediff"/></td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Ordinato :</td>
                                            <td><b-form-checkbox :id="'checkbox-trgswiord_'+IdContratto" v-model="Contratto.trgswiord" name="checkbox-trgswiord"/></td>
                                            <td><b-form-checkbox :id="'checkbox-swiord_'+IdContratto" v-model="Contratto.swiord" name="checkbox-swiord"  v-if="Contratto.swicalprediff"/></td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Fatturato Lordo :</td>
                                            <td><b-form-checkbox :id="'checkbox-trgswifattlordo_'+IdContratto" v-model="Contratto.trgswifattlordo" name="checkbox-trgswifattlordo"/></td>
                                            <td><b-form-checkbox :id="'checkbox-swifattlordo_'+IdContratto" v-model="Contratto.swifattlordo" name="checkbox-swifattlordo"  v-if="Contratto.swicalprediff"/></td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Fatturato Premi :</td>
                                            <td><b-form-checkbox :id="'checkbox-trgswifattpremi_'+IdContratto" v-model="Contratto.trgswifattpremi" name="checkbox-trgswifattpremi"/></td>
                                            <td><b-form-checkbox :id="'checkbox-swifattpremi_'+IdContratto" v-model="Contratto.swifattpremi" name="checkbox-swifattpremi"  v-if="Contratto.swicalprediff"/></td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">Fatt. Periodo Diff :</td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <b-form-checkbox :id="'checkbox-swicalperiododiff_'+IdContratto" v-model="Contratto.swicalperiododiff" name="checkbox-swicalperiododiff" v-if="Contratto.swicalprediff"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">&nbsp;</td>
                                            <td colspan="2">
                                                <b-form-checkbox :id="'checkbox-swicalprediff_'+IdContratto" v-model="Contratto.swicalprediff" name="checkbox-swicalprediff">
                                                    Calcola Premi su Valori Differenti
                                                </b-form-checkbox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6 offset-1 mt-3">
                                <b-form-group  stacked>
                                    <!--<b-input-group  class="mt-1">
                                        <b-input-group-prepend>
                                            <b-button variant="outline-primary" disabled="">  Inizio Validità Contratto </b-button>
                                        </b-input-group-prepend>
                                        <b-form-input v-model="Contratto.datinitxt" type="date"></b-form-input> 
                                    </b-input-group>
                                    <b-input-group  class="mt-1">
                                        <b-input-group-prepend>
                                            <b-button variant="outline-primary" disabled="">  Fine Validità Contratto </b-button>
                                        </b-input-group-prepend>
                                        <b-form-input v-model="Contratto.datfintxt" type="date"></b-form-input> 
                                    </b-input-group> -->

                                    <div class="d-flex  align-items-center"  >
                                    <!-- <div class="row  align-items-center"> -->
                                        <div class="col-2 px-0 "> 
                                            Inizio Validità  
                                        </div>
                                        <div class="col-4 pl-0 pr-3 mr-3">         
                                            <b-form-input v-model="Contratto.datinitxt" type="date"></b-form-input> 
                                        </div>
                                        <div class="col-2 pl-0 pr-1 "> 
                                            Fine Validità 
                                        </div>
                                        <div class="col-4 pl-0 pr-3 ">         
                                            <b-form-input v-model="Contratto.datfintxt" type="date"></b-form-input> 
                                        </div>
                                    <!-- </div> -->
                                    </div> 


                                    <b-form-checkbox class="mt-3" :id="'checkbox-qtaval_'+IdContratto" v-model="Contratto.qtaval" name="checkbox-qtaval">
                                        Qta / Val
                                    </b-form-checkbox>
                                    <b-form-checkbox class="mt-1" :id="'checkbox-swichiu_'+IdContratto" v-model="Contratto.swichiu" name="checkbox-swichiu">
                                        Chiuso
                                    </b-form-checkbox>
                                    <b-form-checkbox class="mt-1" :id="'checkbox-swipercval_'+IdContratto" v-if="TipoObiettivo && TipoObiettivo.swipercval" checked="true"
                                                                                             disabled name="checkbox-swipercval">
                                        Valore Fisso
                                    </b-form-checkbox>

                                    <b-row align-h="end">
                                        <b-col >
                                            <b-button variant="primary" disabled="">  Sconti </b-button>
                                        </b-col>
                                    </b-row>
                                    <!-- <b-input-group  class="mt-1" v-if="this.$store.state.TipoInt==0">
                                        <b-input-group-prepend>
                                            <b-button variant="outline-primary" disabled=""> Agenti </b-button>
                                        </b-input-group-prepend>
                                        <b-form-select  v-model="Contratto.codage"
                                                        :options="$store.state.Agenti" 
                                                        value-field="codage"
                                                        text-field="desage"
                                                        disabled-field="notEnabled"/> 
                                    </b-input-group> -->
                                    <div class="col-6 px-0">
                                        <b-input-group class="mt-1"  v-if="this.$store.state.TipoInt==0">
                                            <b-input-group-prepend>
                                                <b-button variant="outline-primary" disabled=""> Agenti </b-button>
                                            </b-input-group-prepend>
                                            <model-list-select 
                                                :list="ElencoAgenti"
                                                v-model="Contratto.codage" 
                                                option-value="codage"
                                                option-text="desage" 
                                                @searchchange="UpdateSelectListAgenti"
                                                
                                            ></model-list-select>
                                        </b-input-group>
                                    </div>

                                    <div class="d-flex mt-3 align-items-center" v-if="Contratto.swicalperiododiff" >
                                    <!-- <div class="row  align-items-center"> -->
                                        <div class="col-2 px-0 "> 
                                            Inizio Per.Diff  
                                        </div>
                                        <div class="col-4 pl-0 pr-3 mr-3">         
                                            <b-form-input v-model="Contratto.datinipremitxt" type="date"></b-form-input> 
                                        </div>
                                        <div class="col-2 pl-0 pr-1 "> 
                                            Fine  
                                        </div>
                                        <div class="col-4 pl-0 ">         
                                            <b-form-input v-model="Contratto.datfinpremitxt" type="date"></b-form-input> 
                                        </div>
                                    <!-- </div> -->
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Target" class=""> 
                        <div class="row">
                            <div class="col-4">
                                <b-form-group  stacked>
                                    <b-row align-v="center">
                                        <b-col >
                                            &nbsp;
                                        </b-col>
                                        <b-col class="text-center font-weight-bold">
                                            Valore
                                        </b-col>
                                        <b-col class="text-center font-weight-bold">
                                           {{(TipoObiettivo && TipoObiettivo.swipercval)?'Valore Premio':'Percentuale'}} 
                                        </b-col>
                                    </b-row>
                                    <b-row align-v="center">
                                        <b-col  > Target 1 </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-target1" v-model="Contratto.target1" max="1000000" min="0"/>
                                        </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-perctarget1" v-model="Contratto.perscotarget1" max="100" min="0" step="0.1"/>
                                        </b-col>
                                    </b-row>
                                    <b-row  align-v="center">
                                        <b-col  > Target 2 </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-target2" v-model="Contratto.target2" max="1000000" min="0"/>
                                        </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-perctarget2" v-model="Contratto.perscotarget2" max="100" min="0" step="0.1"/>
                                        </b-col>
                                    </b-row>
                                    <b-row  align-v="center">
                                        <b-col  > Target 3 </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-target3" v-model="Contratto.target3" max="1000000" min="0"/>
                                        </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-perctarget3" v-model="Contratto.perscotarget3" max="100" min="0" step="0.1"/>
                                        </b-col>
                                    </b-row>
                                    <b-row  align-v="center">
                                        <b-col  > Target Ridotto </b-col>
                                        <b-col  > &nbsp;</b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-perctargetrid" v-model="Contratto.perctargetrid" max="100" min="0" step="0.1"/>
                                        </b-col>
                                    </b-row>
                                    <b-row  align-v="center">
                                        <b-col  > Tolleranza </b-col>
                                        <b-col  > &nbsp;</b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-toll" v-model="Contratto.toll" max="100" min="0" step="0.1"/>
                                        </b-col>
                                    </b-row>
                                    <b-row  align-v="center">
                                        <b-col  > Toll. Ridotta </b-col>
                                        <b-col  > &nbsp;</b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-toll2" v-model="Contratto.toll2" max="100" min="0"/>
                                        </b-col>
                                    </b-row>
                                    <b-row  align-v="center">
                                        <b-col  > Regalo Finale </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-regfin" v-model="Contratto.regfin" max="1000000" min="0"/>
                                        </b-col>
                                        <b-col  > &nbsp;</b-col>
                                    </b-row>
                                </b-form-group>
                            </div>
                            <div class="col-3 offset-1">
                                <b-form-group  stacked>
                                    <b-row align-v="center" >
                                        <b-col class="text-center font-weight-bold">  Info </b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-1 border rounded">
                                        <b-col >Fatt. Premi </b-col>
                                        <b-col >{{Contratto.valfattpremi}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-1 border rounded" v-if="Contratto.swicalperiododiff">
                                        <b-col >Fatt. Premi Periodo</b-col>
                                        <b-col >{{Contratto.valfattperiodo}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-1 border rounded">
                                        <b-col >Fatt. Lordo </b-col>
                                        <b-col >{{Contratto.valfattlordo}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-1 border rounded">
                                        <b-col >Fatturato </b-col>
                                        <b-col >{{Contratto.valfatt}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-1 border rounded">
                                        <b-col >Consegnato </b-col>
                                        <b-col >{{Contratto.valcons}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-2">
                                        <b-col >Ordinato </b-col>
                                        <b-col >{{Contratto.valord}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-2">
                                        <b-col >Quantità </b-col>
                                        <b-col >{{Contratto.qtamov}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-2">
                                        <b-col >Quantità Vend. </b-col>
                                        <b-col >{{Contratto.qtaven}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-2">
                                        <b-col >Val. Premio </b-col>
                                        <b-col >{{Contratto.valpremio}}</b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-2">
                                        <b-col >Val. Premio Extra </b-col>
                                        <b-col >{{Contratto.valpremioex}}</b-col>
                                    </b-row>
                                </b-form-group>
                            </div>
                            <div class="col-3 offset-1">
                                <b-form-group  stacked>
                                    <b-row align-v="center">
                                        <b-col class="text-center font-weight-bold">
                                            Forzature
                                        </b-col>
                                    </b-row>
                                    <b-row >
                                        <b-col >
                                            <b-form-checkbox v-model="Contratto.swimodman"> Forza Premio Manuale </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row align-v="center" v-show="Contratto.swimodman">
                                        <b-col  > Valore Premio </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-valpremioman" v-model="Contratto.valpremioman" max="1000000" min="0"/>
                                        </b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-3">
                                        <b-col class="text-primary" > Accantonamento </b-col>
                                        <b-col  >&nbsp; </b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-1">
                                        <b-col cols="7" > Tolleranza Forecast </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-tollfor" v-model="Contratto.tollfor" max="100" min="0" step="0.1"/>
                                        </b-col>
                                    </b-row>
                                    <b-row align-v="center" class="mt-1">
                                        <b-col cols="7" > Perc.Target Forecast </b-col>
                                        <b-col  >
                                            <b-form-input type="number"  id="nb-perfortarget" v-model="Contratto.perfortarget" max="100" min="0" step="0.1"/>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Note" class=""> 
                        <b-form-textarea  id="txta-note" v-model="Contratto.note" rows="8" max-rows="20" />
                    </b-tab>
                    <b-tab v-if="IdContratto>0" lazy title="Log Email">
                        <logmail :IdContratto="IdContratto" />
                    </b-tab>
                </b-tabs>
            </div>
        </template>

        <b-modal :id="'mdl_Contratti_Int_'+IdContratto"
                :ref="'modal_Contratti_Int_'+IdContratto"
                 title="Intestatari collegati"
                 size="xl"
                 >
                <ContrattiInt :IdContratto="IdContratto" v-on:changeCntDet="OnChangeIntestatari" />
        </b-modal>

        <b-modal :id="'mdl_Contratti_ConfDel_'+IdContratto"
                :ref="'modal_Contratti_ConfDel_'+IdContratto"
                 title="Cancella contratto"
                  @ok="handleOk"
                 >
                <h4>
                    Sei sicuro di voler cancellare il contratto?
                </h4>
        </b-modal>
        
    </div>
</template>

<script>
    import axios from 'axios';
    import { ModelListSelect  } from 'vue-search-select';
    import ContrattiInt from'./ContrattiInt.vue';
    import logmail from './logmail.vue';
export default {
    props:{
        IdContratto: {
                type: Number,
                required: true
            },
        TxtCmd:{
            type: String,
            required:false
        },
        RifMail:{
            type: Number,
            required:false
        },
    },
    components:{ModelListSelect,ContrattiInt,logmail},
    data() {
        return {
            errors: [],
            isLoading: true,
            ShowModal: false,
            Contratto: Object,
            ComboElements: {},                
            row: Object,
            SelectedEntity: Object,
            ElencoCli:[],
            SwiSalvato:false,
            StrDettagli: this.TxtCmd,
            TxtRicercaTipoOb:'',
            TxtRicercaAgenti:'',
            awaitingSearch:false
        };
    },
    watch: { 
        'Contratto.datinitxt':function(newval) {
            this.Contratto.datini=newval;
        },
        'Contratto.datfintxt':function(newval) {
            this.Contratto.datfin=newval;
        },
        'Contratto.datinipremitxt':function(newval) {
            this.Contratto.datinipremi=newval;
        },
        'Contratto.datfinpremitxt':function(newval) {
            this.Contratto.datfinpremi=newval;
        },
        'Contratto.riftipocontratto':function(newval){
            if(newval!=0){
                this.Contratto.rifserie = this.$store.state.TipiOb.find(t=> t.id==newval).rifserie;
                if(this.IdContratto==0){
                    this.AggiornaInfoDaTipoOb();
                }
            }
        },
        'Contratto.rifint':function(newval) {
            if(this.ElencoCli && this.ElencoCli.length>0){
                this.Contratto.codage= this.ElencoCli.find(c=> c.cod==newval).codage;
            }
        },
        'Contratto.swicalperiododiff':function(newval){
            if(newval){
                this.Contratto.swifatt= false;
                this.Contratto.swicons= false;
                this.Contratto.swiord= false;
                this.Contratto.swifattlordo= false;
                this.Contratto.swifattpremi= false;
            }
        },
        'Contratto.swifatt':function(newval){
            if(newval){
                this.Contratto.swifattlordo= false;
                this.Contratto.swifattpremi= false;
                this.Contratto.swicalperiododiff=false;
            }
        },
        'Contratto.swifattlordo':function(newval){
            if(newval){
                this.Contratto.swifatt= false;
                this.Contratto.swifattpremi= false;
                this.Contratto.swicalperiododiff=false;
            }
        },
        'Contratto.swifattpremi':function(newval){
            if(newval){
                this.Contratto.swifattlordo= false;
                this.Contratto.swifatt= false;
                this.Contratto.swicalperiododiff=false;
            }
        }

    },
    computed:{
        ElencoTipiOb:function(){
            if(this.IdContratto==0){
                //console.log('this.$store.state.Series.filter(s=> s.abilitata).filter(s1=> s1.id== t.rifserie)',this.$store.state.Series.filter(s=> s.abilitata))
                //console.log('this.TxtRicercaTipoOb-->',this.TxtRicercaTipoOb)
                return this.$store.state.TipiOb.filter( t=> 
                    (t.tipint== this.$store.state.TipoInt)
                    &&
                    (this.$store.state.Series.filter(s=> s.abilitata).filter(s1=> s1.id== t.rifserie).length>0)
                    &&
                    (this.$store.state.Classi.filter(c=> c.abilitata).filter(c1=> c1.codclasse== t.classe).length>0)
                    &&
                    (this.TxtRicercaTipoOb=='' || t.coddes.toUpperCase().includes(this.TxtRicercaTipoOb.toUpperCase())  )
                );
            }
            else{
                return this.$store.state.TipiOb;
            }
        },
        ElencoAgenti:function(){
             return this.$store.state.Agenti.filter( t=> 
                    (this.TxtRicercaAgenti=='' 
                      || 
                     t.codage.toUpperCase().includes(this.TxtRicercaAgenti.toUpperCase())
                      || 
                     t.desage.toUpperCase().includes(this.TxtRicercaAgenti.toUpperCase())
                ));
        },
        TipoObiettivo:function(){
            return this.$store.state.TipiOb.find(t=> t.id== this.Contratto.riftipocontratto)
        },
        // Comp_swipercval(){
        //     return this.TipoObiettivo.swipercval>0;
        // }
    } ,
    methods:{
        AggiornaInfoDaTipoOb(){
            let self = this;
            if(self.Contratto.id ==0){ 

                axios.get( 'api/v1/TipiObiettivi/'+ self.Contratto.riftipocontratto)
                     .then(response=>{ 
                        
                        self.Contratto.qtaval=response.data.result.swiqtaval;
                        self.Contratto.swicalprediff=response.data.result.swicalprediff;
                        self.Contratto.swicons=response.data.result.swicons;
                        self.Contratto.swifatt=response.data.result.swifatt;
                        self.Contratto.swifattlordo=response.data.result.swifattlordo;
                        self.Contratto.swifattpremi=response.data.result.swifattpremi;
                        
                        self.Contratto.swiord=response.data.result.swiord;
                        self.Contratto.trgswicons=response.data.result.trgswicons;
                        self.Contratto.trgswifatt=response.data.result.trgswifatt;
 

                        self.Contratto.trgswifattlordo=response.data.result.trgswifattlordo;
                        self.Contratto.trgswifattpremi=response.data.result.trgswifattpremi;
                        self.Contratto.trgswiord=response.data.result.trgswiord;
                        self.Contratto.perctargetrid=response.data.result.perctargetrid;
                        self.Contratto.perscotargeT_1=response.data.result.perctarget1;
                        self.Contratto.perscotargeT_2=response.data.result.perctarget2;
                        self.Contratto.perscotargeT_3=response.data.result.perctarget3;
                        self.Contratto.scontolistino=response.data.result.scontolistino;
                        self.Contratto.toll2=response.data.result.toll2;
                        self.Contratto.toll=response.data.result.toll;
                        self.Contratto.datinitxt=response.data.result.datinitxt;
                        self.Contratto.datfintxt=response.data.result.datfintxt;

                        self.Contratto.perscotarget1=response.data.result.perctarget1;
                        self.Contratto.perscotarget2=response.data.result.perctarget2;
                        self.Contratto.perscotarget3=response.data.result.perctarget3;
                        
                        self.Contratto.datinipremitxt=response.data.result.datinipremitxt;
                        self.Contratto.datfinpremitxt=response.data.result.datfinpremitxt;
                        self.Contratto.swicalperiododiff=response.data.result.swicalperdiff;
                        
                    //"codage": "0",                    
                    }).catch(e => {
                        this.errors.push(e)
                    });

            }
        },
        UpdateSelectListTipoOb(searchText){
            this.TxtRicercaTipoOb=searchText;
        },
        UpdateSelectListAgenti(searchText){
            this.TxtRicercaAgenti=searchText;
        },
        UpdateSelectListClienti(searchText) {
                let self = this;                
                //console.log("UpdateSelectListClienti(searchText) ",searchText);
                //serve per bloccare il caricamento quando selezione l'elemento dell'elenco
                if (self.Contratto.rifint && searchText=="") { return; } 

                

                // maggiore uguale 3 caratteri
                if(searchText.length>=3){
                    if (!self.awaitingSearch) {
                        setTimeout(() => {
                            
                                axios.get('api/v1/Intestatari/GetFromType?Tipo='+ self.$store.state.TipoInt +'&str='+searchText)
                                    .then(response => {                    
                                        self.ElencoCli = response.data.result;                    
                                        
                                    }).catch(e => {
                                        this.errors.push(e)
                                    });
                            
                            self.awaitingSearch = false;
                        }, 700); // 0,5 sec delay

                    }

                    self.awaitingSearch = true;
                    
                }
            },
        SalvaContratto(){
            let self=this;

            var Link = 'api/_gen/Contratto/'  ;
            self.isLoading=true;

            if(self.IdContratto==0){
                axios.post(Link, self.Contratto)//, state.Headers)
                        .then(response => {
                            console.log(response);
                            self.SwiSalvato=true;
                            self.isLoading=false;
                            //self.IdContratto=response.data.result;
                        }).catch(e => {
                            self.$parent.errors.push(e)
                            self.SwiSalvato=false;
                            self.isLoading=false;
                        }); 
            }
            else
            {
                axios.put(Link, self.Contratto)//, state.Headers)
                        .then(response => {
                            console.log(response);
                            self.SwiSalvato=false;
                            self.isLoading=false;
                        }).catch(e => {
                            self.$parent.errors.push(e);
                            self.SwiSalvato=false;
                            self.isLoading=false;
                        }); 
            }
        },
        EliminaContratto(){
             
            this.$bvModal.show('mdl_Contratti_ConfDel_'+this.IdContratto);
             
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            let self=this; 

            var Link = 'api/_gen/Contratto/'+self.Contratto.id  ;
            self.isLoading=true;
            axios.delete(Link, self.Contratto)//, state.Headers)
                .then(response => {
                    console.log(response);
                    self.SwiSalvato=false;
                    self.isLoading=false;
                    self.$emit("delete",self.Contratto.id);
                    this.$nextTick(() => {
                        this.$bvModal.hide('mdl_Contratti_Int_'+this.IdContratto)
                        })
                }).catch(e => {
                    self.$parent.errors.push(e);
                    self.SwiSalvato=false;
                    self.isLoading=false;
                }); 
        },
        ApriContrattiInt(){
            this.$bvModal.show('mdl_Contratti_Int_'+this.IdContratto);
        },
        OnChangeIntestatari(str){
            this.StrDettagli = str;
        }
    },
    beforeMount(){ 
        let self=this;
console.log('beforeMount editcontratto,',self.IdContratto);
        if(self.IdContratto!=0){
            var Link = 'api/_gen/Contratto/'+ this.IdContratto ;
            axios.get(Link)//, state.Headers)
                        .then(response => {
                            self.Contratto = response.data.result;
                            self.isLoading=false;
                        }).catch(e => {
                            self.$parent.errors.push(e)
                        }); 
        }
        else{
            //nuovo contratto
            self.isLoading=false;
            self.Contratto= {
                    "qtaval": false,
                    "swiblocon": false,
                    "swibloord": false,
                    "swicalprediff": false,
                    "swichiu": false,
                    "swicons": false,
                    "swifatt": false,
                    "swifattlordo": false,
                    "swifattpremi": false,
                    "swimodman": false,
                    "swiord": false,
                    "trgswicons": false,
                    "trgswifatt": false,
                    "trgswifattlordo": false,
                    "trgswifattpremi": false,
                    "trgswiord": false,
                    "perctargetrid": 0,
                    "perscotargeT_1": 0,
                    "perscotargeT_2": 0,
                    "perscotargeT_3": 0,
                    "scontolistino": 0,
                    "targeT_1": 0,
                    "targeT_2": 0,
                    "targeT_3": 0,
                    "tolL2": 0,
                    "valacc": 0,
                    "valfattcalcpremi": 0.00,
                    "valpremio": 0,
                    "valpremioex": 0,
                    "valpremioman": 0,
                    "perfortarget": 0,
                    "qtamov": 0,
                    "tollfor": 0,
                    "valcons": 0,
                    "valfatt": 0.00,
                    "valfattpremi": 0.00,
                    "valord": 0,
                    "valpremiofor": 0,
                    "toll": 0,
                    "id": 0,
                    "rifserie": 0,
                    "riftipocontratto": 0,
                    "tiptrg": 0,
                    "infodate": "",
                    "rifint": "",
                    "datinitxt": "",
                    "datfintxt": "",
                    "codage": "0",
                    "perscotarget1": 0,
                    "perscotarget2": 0,
                    "perscotarget3": 0,
                    "target1": 0,
                    "target2": 0,
                    "target3": 0,
                    "datinipremitxt": "",
                    "datfinpremitxt": "",
                    "valfattperiodo": 0.00,
                    "swicalperiododiff":false};
	
        }
    }
}
</script>