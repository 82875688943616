<template>
    <div class="row">
        <b-input-group class="col-3 col-md-3">
            <b-input-group-prepend>
                <b-button variant="outline-primary">Tipo</b-button>
            </b-input-group-prepend>

            <b-form-select
                v-model="TipoObDet.campofiltro"
                :options="this.$store.state.ParFiltri"
                class=""
                value-field="id"
                text-field="desfiltro"
                ></b-form-select>
        </b-input-group>
        <b-input-group class="col-3 col-md-3">
            <b-input-group-prepend>
                <b-button variant="outline-primary">Operazione</b-button>
            </b-input-group-prepend>

                <b-form-select
                    v-model="TipoObDet.tipocond"
                    :options="this.$store.state.TipiOperazione"
                    class=""
                    value-field="id"
                    text-field="out_tipo"
                    disabled-field="notEnabled"
                    ></b-form-select>
        </b-input-group>
        <b-input-group class="col-5">
            <b-input-group-prepend>
                <b-button variant="outline-primary">Valori</b-button>
            </b-input-group-prepend>

            <b-form-input v-model="TipoObDet.val1" type="text"></b-form-input> 
        </b-input-group>
        <b-input-group class="col-1"> 
                <b-button variant="outline-danger" v-on:click="EliminaRecord">X</b-button>
        </b-input-group>
    </div>
</template>
<script>
export default {
    props: {
        IdTipoOb: {
            type: Number,
            required: true
        } ,
        TipoObDet: {
            type: Object,
            required: true
        } 
    },  
    data() {
        return {
            errors: []
        };
    },
    watch: {
    },
    methods:{
        EliminaRecord(){
            console.log( 'EliminaRecord()'+   this.TipoObDet.id)
            console.log( 'EliminaRecord()'+   this.TipoObDet.rowKey)

            this.$emit("delete", this.TipoObDet.id==0? this.TipoObDet.rowKey : this.TipoObDet.id);
        }
    },
    mounted(){
        //  //leggo l'anagrafica INTERA dal server
        // var Link = 'api/v1/TipiObiettiviDet/'+this.IdTipoObDet;
        // axios.get(Link,)//, state.Headers)
        //     .then(response => {
        //             this.TipoObDet = response.data.result;
        //     }).catch(e => {
        //         this.errors.push(e)
        //     });
    }
}
</script>