<template id="Filtri">
    <div class="container-fluid px-5 mb-2 mt-2"  v-if="this.$store.state.IsStarted">
        <div class="row mb-1" v-if=(!SoloClasseSerie)>
            <b-input-group class="col-6 offset-1"  style="height: 25px;">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Ricerca Intestatari</b-button>
                </b-input-group-prepend>

                <b-form-input type="text" debounce="1600" v-model="txtRicercaCli" v-on:keyup.enter="ExecRicerca"></b-form-input>

                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="txtRicercaCli=''">x</b-button> 
                </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-3"  style="height: 38px;"> 
                <b-input-group-prepend class="">
                    <b-button variant="outline-primary" disabled>Agenti</b-button>
                </b-input-group-prepend>
                <!-- <b-form-select
                        v-model="selAgente"
                        :options="Agenti"
                        class=""
                        value-field="codage"
                        text-field="desage"
                        disabled-field="notEnabled"
                        ></b-form-select> -->
                
                <model-list-select 
                        :list="ElencoAgenti"
                        v-model="selAgente"
                        class="col-7 rounded-0"
                        option-value="codage"
                        option-text="desage" 
                        @searchchange="UpdateSelectListAgenti"
                        
                    ></model-list-select>
                
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="selAgente=0;UpdateSelectListAgenti('')">x</b-button> 
                </b-input-group-append>
            </b-input-group>
            <b-form-checkbox v-model="SwiRicAuto" class="col-2 pt-1">Ricerca automatica</b-form-checkbox>
        </div>
        <div class="row mt-2">
            <b-input-group class="col-6 col-md-3 offset-md-1" style="height: 38px;">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Serie</b-button>
                </b-input-group-prepend>

                <b-form-select v-model="selSerie"
                               :options="series"
                               class=""
                               value-field="id"
                               text-field="nomeserie"
                               disabled-field="notEnabled"></b-form-select>
            </b-input-group>
            <b-input-group class="col-8 col-md-3" style="height: 38px;">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Classi</b-button>
                </b-input-group-prepend>

                <b-form-select v-model="selClasse"
                               :options="classi"
                               class=""
                               value-field="codclasse"
                               text-field="desclasse"
                               disabled-field="notEnabled"></b-form-select>
            </b-input-group>
            <b-input-group class="col-8 col-md-3" v-if=(!SoloClasseSerie) style="height: 38px;">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Tipi Contratti</b-button>
                </b-input-group-prepend>

                <b-form-select v-model="selTipoOb"
                               :options="tipiOb"
                               class=""
                               value-field="id"
                               text-field="descon"
                               disabled-field="notEnabled"></b-form-select>
            </b-input-group>
            
            
            <b-form-checkbox v-if="MostraIncludiInviati" v-model="SwiIncludiInviati" class="col-1 pt-1">{{TestoIncludiInviati||'Includi inviati'}}</b-form-checkbox>
            <b-button v-if="!SwiRicAuto" variant="outline-primary" @click="ExecRicerca"> Cerca </b-button>
            <!-- <b-button v-if=(!SoloClasseSerie) variant="outline-danger" class="ml-2" @click="StampaLettere"> Lettere </b-button> -->
        </div>
        <div class="row mt-2" v-if="MostraStatoLettere">
            <b-input-group class="col-6 col-md-3 offset-md-1" style="height: 38px;">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Stato</b-button>
                </b-input-group-prepend>

                <b-form-select v-model="selStato"
                               :options="elStati"
                               class=""
                               value-field="id"
                               text-field="desstato"
                               disabled-field="notEnabled"></b-form-select>
            </b-input-group>
        </div>
        <!-- <div class="row mt-2"> 
            <b-button variant="danger" @click="GetLetters" > zip </b-button>
        </div> -->
        <b-modal id="mdl_StampaLettere"
                 ref="modal_StampaLettere"
                 title="Stampa Lettere"
                 size="xl"
                 > 
        </b-modal>
    </div>
</template>

<script >
 import axios from 'axios'; 
    import { ModelListSelect  } from 'vue-search-select';
    export default {
        props: {
            SoloClasseSerie: Boolean ,
            MostraIncludiInviati: Boolean,
            MostraStatoLettere:Boolean,
            TestoIncludiInviati: String
        }, 
        components:{ModelListSelect},
        data() {
            return {
                errors: [],  

                series: [],
                selSerie:888888, //c'e` un problema di lentezza sul filtro serie abilitate
                classi: [],
                selClasse:888888,
                tipiOb: [],
                selTipoOb:999999,
                Agenti: [],
                selAgente:999999,
                elStati:[ 
                    {id:0,desstato:'<Tutti>'},
                    {id:10,desstato:'Da Inviare'},
                    {id:20,desstato:'Invio in corso'},
                    {id:30,desstato:'Inviate'},
                    {id:-100,desstato:'Errori'}
                ],
                selStato:10,
                txtRicercaCli:'',
                TxtRicercaAgenti:'',

                SwiRicAuto:this.SoloClasseSerie,
                LoggedIn:this.$store.state.IsStarted,
                SwiIncludiInviati: (!this.MostraIncludiInviati)  //se non c'è non lo metto
            };
        },
        watch: {
            LoggedIn: {
                 immediate: true,
                deep: true,
                handler(newVal) {
                    if (newVal) {

                        console.log('loggedin in filtri: ' + newVal);
                        this.LoadFilterData();

                    }
                    
                }
            },
            selClasse: function (){
                this.AggiornaElencoTipiOb();
                if(this.SwiRicAuto){
                    //this.$emit('change', this.selClasse, this.selTipoOb, this.selSerie, this.txtRicercaCli,this.selAgente);
                    this.ExecRicerca()
                }
            },
            selTipoOb: function (){
                if(this.SwiRicAuto){
                    //this.$emit('change', this.selClasse, this.selTipoOb, this.selSerie, this.txtRicercaCli,this.selAgente);
                    this.ExecRicerca()
                }
            },
            selSerie: function (){
                this.AggiornaElencoTipiOb();
                if(this.SwiRicAuto){
                    //this.$emit('change', this.selClasse, this.selTipoOb, this.selSerie, this.txtRicercaCli,this.selAgente);
                    this.ExecRicerca()
                }
            },
            selAgente: function (){
                if(this.SwiRicAuto){
                    //this.$emit('change', this.selClasse, this.selTipoOb, this.selSerie, this.txtRicercaCli,this.selAgente);
                    this.ExecRicerca()
                }
            },
            txtRicercaCli:function(){
                if(this.SwiRicAuto){
                    //this.$emit('change', this.selClasse, this.selTipoOb, this.selSerie, this.txtRicercaCli,this.selAgente);
                    this.ExecRicerca()
                }
            },
            '$store.state.TipoInt': function(){
                this.selSerie=888888;                
                this.selClasse=888888;
                this.selTipoOb=999999;
                this.selAgente=999999,
                this.txtRicercaCli='';

                this.AggiornaElencoClassi();   
                this.AggiornaElencoTipiOb(); 
                this.ExecRicerca();
            }
        },
        computed:{
            ElencoAgenti:function(){
                if(!this.$store.state.Agenti){
                    return [];
                }
                return this.$store.state.Agenti.filter( t=> 
                    (this.TxtRicercaAgenti=='' 
                      || 
                     t.codage.toUpperCase().includes(this.TxtRicercaAgenti.toUpperCase())
                      || 
                     t.desage.toUpperCase().includes(this.TxtRicercaAgenti.toUpperCase())
                ));
            }
        },
        methods: {
            UpdateSelectListAgenti(searchText){
                this.TxtRicercaAgenti=searchText;
            },
            AggiornaElencoClassi(){

                this.classi=[];

                var tClasse = {
                    codclasse:999999,
                    desclasse:'<Tutte>',
                    abilitata: true
                }
                this.classi.push(tClasse);

                tClasse = {
                    codclasse:888888,
                    desclasse:'<Abilitate>',
                    abilitata: true
                }
                this.classi.push(tClasse);
                if(this.$store.state.Classi.length>0)
                {
                    this.$store.state.Classi.forEach(el => {
                        //controllo se la classe è abilitata a questo tipo intestatario
                        switch( this.$store.state.TipoInt) {
                        case 0:
                            // clienti
                            if(el.switipcli) {this.classi.push(el);}
                            break;
                        case 1:
                            // fornitori
                            if(el.switipfor) {this.classi.push(el);}
                            break;
                        case 2:
                            // fornitori
                            if(el.switipage) {this.classi.push(el);}
                            break;
                        case 3:
                            // fornitori
                            if(el.switipam) {this.classi.push(el);}
                            break;
                        }
                        
                    });
                }
            },
            AggiornaElencoTipiOb(){
                let self=this;
                if(!self.SoloClasseSerie){
                    self.tipiOb=[];
    //console.log('AggiornaElencoTipiOb inizio');
                    var tEl = {
                        id:999999,
                        descon:'<Tutti>'
                    }
                    self.tipiOb.push(tEl); 
                    
                    var telencoClassi=[];
                    if(self.selClasse=='999999'){
                        this.$store.state.Classi.forEach(c=>{telencoClassi.push(c.codclasse)});
                    }
                    else if(self.selClasse=='888888'){
                        this.$store.state.Classi.filter(el =>  el.abilitata ).forEach(c=>{telencoClassi.push(c.codclasse)});
                    }
                    else if(self.selClasse!='888888'){
                        telencoClassi.push(self.selClasse);
                    }
                    var telencoSerie=[];
                    if(self.selSerie=='999999'){
                        this.$store.state.Series.forEach(s=>{telencoSerie.push(s.id)});
                    }
                    else if(self.selSerie=='888888'){
                        this.$store.state.Series.filter(el =>  el.abilitata ).forEach(s=>{telencoSerie.push(s.id)});
                    }
                    else if(self.selSerie!='888888'){
                        telencoSerie.push(self.selSerie);
                    }
    //console.log('AggiornaElencoTipiOb array serie e classi',telencoSerie, telencoClassi);
                    if(self.$store.state.TipiOb.length>0)
                    {
    //console.log('AggiornaElencoTipiOb array serie e classi',telencoSerie, telencoClassi);
                        self.$store.state.TipiOb.forEach(el => {
                            if(el.tipint==self.$store.state.TipoInt
                                &&
                                (telencoClassi.length==0 || telencoClassi.includes(el.classe)) //controllo le classi abilitate se includono quella del tipo ob
                                && 
                                (telencoSerie.length==0 || telencoSerie.includes(el.rifserie))
                                ){
                                self.tipiOb.push(el);
                            }
                        });
                    }
                }
            },
            LoadFilterData(){

                var tEl = {
                    id:999999,
                    nomeserie:'<Tutte>',
                    abilitata: true
                }
                this.series.push(tEl);

                tEl = {
                    id:888888,
                    nomeserie:'<Abilitate>',
                    abilitata: true
                }

                this.series.push(tEl);
                if(this.$store.state.Series.length>0)
                {
                    this.$store.state.Series.forEach(el => {
                        this.series.push(el);
                    });
                }
                
                this.AggiornaElencoClassi();
                
               this.AggiornaElencoTipiOb();


                tEl = {
                    codage:999999,
                    desage:'<Tutti>'
                }
                this.Agenti.push(tEl); 
                
                if(this.$store.state.Agenti.length>0)
                {
                    this.$store.state.Agenti.forEach(el => {
                        this.Agenti.push(el);
                    });
                }
            },
            StampaLettere(){
                this.$bvModal.show('mdl_StampaLettere');
            },
            GetLetters(){ 
                var Link = '/file/v1/Lettere/GetLetters?Tipo='+ self.TipoInt ;
                 
                if(this.txtRicercaCli){
                    Link += '&TxtCliente='+this.txtRicercaCli; 
                }
                if(this.selTipoOb){
                    Link += '&IdTipoObiettivo='+this.selTipoOb;
                }
                if(this.selClasse){
                    Link += '&Classe='+this.selClasse;
                }
                if(this.selSerie){
                    Link += '&Serie='+this.selSerie ;
                }
                if(this.selAgente){
                    Link += '&CodAgente='+this.selAgente ;
                }
                axios.get(Link)//, state.Headers)
                .then(
                    resp => {
                    //alert(resp.data);
                        console.log(resp.data);
                    if(!resp.data)
                    {
                        self.errors.push({message:'Error while retrieving data. Maybe No Records Found'})
                    }
                    else
                    {
                        if(resp.data.type=='application/json')
                        {
                            self.errors.push({message:'No Records Found'})
                        }
                        else
                        {
                            let blob = new Blob([resp.data], { type: 'application/zip' })
                            let link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.download = 'Report.zip'
                            link.click()
                        }
                    }
                })
            },
            ExecRicerca(){
                this.$store.commit('SetFiltri', {
                    selClasse:this.selClasse, 
                    selTipoOb:this.selTipoOb, 
                    selSerie:this.selSerie, 
                    txtRicercaCli:this.txtRicercaCli, 
                    selAgente:this.selAgente, 
                    SwiIncludiInviati:this.SwiIncludiInviati,
                    selStato:this.selStato
                })
                this.$emit('change', this.selClasse, this.selTipoOb, this.selSerie, this.txtRicercaCli,this.selAgente,this.SwiIncludiInviati,this.selStato);
            }
        },
        beforeMount(){

        },
        mounted(){
            let self=this;
            self.swiIncludiInviati= ! self.MostraIncludiInviati;

            if(self.$store.state.Filtri){
                //console.log('mounted filtri');
                // disabilito la ricerca automatica altrimenti partono troppi eventi
                var tricAuto=self.SwiRicAuto;
                self.SwiRicAuto=false;

                //console.log('aggiorno automatico spengo');
                if(self.$store.state.Filtri.selClasse) self.selClasse= self.$store.state.Filtri.selClasse;
                if(self.$store.state.Filtri.selTipoOb) self.selTipoOb=self.$store.state.Filtri.selTipoOb;
                if(self.$store.state.Filtri.selSerie) self.selSerie=self.$store.state.Filtri.selSerie;
                if(self.$store.state.Filtri.txtRicercaCli) self.txtRicercaCli=self.$store.state.Filtri.txtRicercaCli;
                if(self.$store.state.Filtri.selAgente) self.selAgente=self.$store.state.Filtri.selAgente;

                //console.log('esegue ricerca');
                //self.ExecRicerca();

                self.SwiRicAuto=tricAuto;
            }

            // console.log('mounted filtri: ');
            // self.LoadFilterData();
        }
    }

</script>