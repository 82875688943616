<template>
    <div id="app">

        <Menu :LoggedIn="this.$store.state.LoggedIn"></Menu>
        
        <div v-if="isLoadingGen && !this.$store.state.IsStarted" id="div-spinner" class="text-center py-4 mt-3">
            <b-spinner variant="primary" style="width: 4rem; height: 4rem;"></b-spinner>
        </div> 
        <router-view v-else/>
    </div>
</template>
<script>
    import Menu from './Menu.vue';
    import axios from 'axios';
    export default {
         data() {
            return {
                isLoadingGen: false //serve per il loading generale
            };
        }, components: { Menu },

        /******************************************************************************************************************
         * 
         * mounted da rivedere con calma perchè funziona,ma provoca dei daley nel ricaricare i dati iniziali e rende tutto instabile
         * 
         ******************************************************************************************************************/
         mounted() {
            let self=this;

            //console.log('app mounted');
            var tCookie= self.$cookies.get('AYCPToken');
            
            //console.log('appmounted, cookie:',tCookie);
             if (tCookie) {                     
                self.isLoadingGen = true;
                //provo a chiamare una API per vedere se il cookie è ancora valido
                axios.get('api/v1/Account/login')
                    .then(response => {
                        //console.log('app ',response.data.result)
                        self.$store.commit('SetToken', tCookie);
                        self.$store.commit('SetUsername', response.data.result.name);
                        self.$store.commit('GetInitialData');
                        self.$store.commit('SetLoggedIn', true);

                       this.$store.commit('SetswiAMM',response.data.result.swiAmm);
                        this.$store.commit('SetswiRV',response.data.result.swiRv);
                        this.$store.commit('SetswiAreaM',response.data.result.swiAream); 
                        this.$store.commit('SetswiAGE',response.data.result.swiAge);

                        /*console.log("Login: response.data.result.SwiAmm " + response.data.result.swiAmm)
                        console.log("Login: response.data.result.SwiRv " + response.data.result.swiRv)
                        console.log("Login: response.data.result.SwiAream " + response.data.result.swiAream)
                        console.log("Login: response.data.result.swiAge " + response.data.result.swiAge)
                        */

                        setTimeout(() => {             
                            self.isLoadingGen = false;
                        }, 1000);
                        self.errors = self.$store.state.errors;
                    }).catch(e => {
                        console.log(e);
                        self.isLoadingGen = false;
                    });
                
            }
        },
    }
</script>

<style>

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* text-align: center; */
        color: #2c3e50;
        /* margin-top: 60px; */
    }
</style>


