<template>
    <div class="container-fluid">
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        
        <Filtri :LoggedIn="this.$store.state.LoggedIn" v-on:change="onChangeFiltri" :SoloClasseSerie="false"></Filtri>  

        <div class="row px-5">
            
        </div>
        <div class="row px-5 mt-3 mb-2">
            <b-col sm=1  offset-md="1">
                <b-button title="Aggiungi nuovo contratto" pill variant="outline-success" class="ml-2" align="right" @click="ClickNuovo()">
                    <font-awesome-icon size="sm" icon="plus" />
                </b-button>
            </b-col>
            <b-col sm="2" class="my-1">
                <b-form-group
                label="Per pagina"
                label-for="per-page-select"
                label-cols-sm="6"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="6" md="2" class="my-0 ">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
                ></b-pagination>
            </b-col>
            <b-col offset="1" sm="4" md="2" align-self="center">
                <label for="" class="col-form-label col-form-label-sm "> Righe Estratte {{(!Contratti.length)?0: Contratti.length}}</label>
            </b-col>
            
        </div>
        <!--Spinner-->
        <!-- <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div> -->
        <div >
             
            <b-table 
                    hover 
                    small
                    head-variant="dark" 
                    sort-icon-left 
                    :items="Contratti" :fields="fields" 
                    primary-key="id" 
                    :busy="isLoading"
                    :current-page="currentPage"
                    :per-page="perPage">
                <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template v-slot:cell(actions)="row">
                    <b-button size="sm" @click="row.toggleDetails" variant="outline-secondary">
                        <font-awesome-icon v-if="row.detailsShowing" size="lg" icon="angle-up" />
                        <font-awesome-icon v-else size="lg" icon="angle-down"   />
                    </b-button>

                     <b-button variant="outline-primary" class="mx-3 " size="sm" @click="ShowDettInt(row.item.id)">
                        <font-awesome-icon size="lg" icon="bars" />{{ row.item.txtCmd.replace('Dettagli','')}} 
                    </b-button>
                    
                        <font-awesome-icon v-if="row.item.mailstatus ==1" size="lg" icon="envelope-open" class="ml-2" style="color:lightgrey" v-b-popover.hover.topleft="'in attesa generazione report'"  />

                        <b-button v-if="row.item.mailstatus == 10" variant="link"   :href="'file/v1/GetLetter?rifmail='+ row.item.rifmail"  v-b-popover.hover.topleft="'in attesa di conferma invio'" >
                            <font-awesome-icon size="lg" icon="envelope-open-text"  class="text-warning"   />
                        </b-button>
                        <b-button v-if="row.item.mailstatus == 20" variant="link"   :href="'file/v1/GetLetter?rifmail='+ row.item.rifmail"  v-b-popover.hover.topleft="'invio in corso'" >
                           <font-awesome-icon  size="lg" icon="envelope-open-text"  class="text-secondary" />
                        </b-button>
                        <b-button v-if="row.item.mailstatus > 20" variant="link"   :href="'file/v1/GetLetter?rifmail='+ row.item.rifmail"  v-b-popover.hover.topleft="'Inviata'" >
                            <font-awesome-icon v-if="row.item.mailstatus > 20" size="lg" icon="envelope-open-text"  class="text-success" />
                        </b-button>
                        
                        <font-awesome-icon v-if="row.item.mailstatus < 0" size="lg" icon="envelope-open-text"  style="color:red" :id="'icon-err-'+row.item.id">
                            <b-tooltip :target="'icon-err-'+row.item.id" placement="topleft" triggers="hover">
                                <b>in attesa di invio</b>
                            </b-tooltip>
                        </font-awesome-icon>
                    
                </template>
                <template v-slot:row-details="row">
                    <b-card>
                        <EditContratto :IdContratto="row.item.id" :TxtCmd="row.item.txtCmd" :RifMail="row.item.rifmail" v-on:delete="onDeleteContratto"/>
                    </b-card>
                </template>
                <!-- 
                    <template #cell(codtipob)="row">
                    {{$store.state.TipiOb.find(t=>  t.id==  row.item.riftipocontratto).codcon}}
                </template> 
                -->
                <template #cell(destipob)="row">
                    {{$store.state.TipiOb.find(t=>  t.id==  row.item.riftipocontratto).codcon+' ' +$store.state.TipiOb.find(t=>  t.id==  row.item.riftipocontratto).descon}}
                </template>

                <template #cell(tiptrg)="row">
                    <font-awesome-icon v-if="row.item.tiptrg == undefined" size="lg" icon="circle" @click="ViewList()" style="color:gray" />
                    <font-awesome-icon v-if="row.item.tiptrg > 0" size="lg" icon="check-circle" @click="ViewList()" style="color:forestgreen" />
                    <font-awesome-icon v-if="row.item.tiptrg == 0" size="lg" icon="exclamation-circle" @click="ViewList()" style="color:orange" />
                    <font-awesome-icon v-if="row.item.tiptrg < 0" size="lg" icon="stop" @click="ViewList()" style="color:red" />
                </template>

            </b-table>
        </div>

        <div v-if="Contratti && Contratti.length>10" class="row px-5">
            <b-col sm="7" md="6" offset-md="4" class="my-2">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
                ></b-pagination>
            </b-col>
        </div>

        <b-modal  
            id="mdl_NewContratto"
            ref="modal_NewContratto"
            title="Nuovo Contratto"
            size="xl"
            hide-footer
            centered>
            <edit-contratto :IdContratto="0" />
        </b-modal>

        <b-modal id="mdl_Contratti_Int"
            ref="modal_Contratti_Int"
            title="Intestatari collegati"
            size="xl"
            >
            <ContrattiInt :IdContratto="CntSel" v-on:changeCntDet="OnChangeIntestatari" />
        </b-modal>
    </div>
</template>
<script>
    import Filtri from './components/Filtri.vue';
    import EditContratto from './components/EditContratto.vue';

    import ContrattiInt from './components/ContrattiInt.vue'
    import axios from 'axios';

    export default {
        

        components: { 
            Filtri,EditContratto,ContrattiInt
        },

        data() {
            return {
                errors: [],
                isLoading: false,
                Contratti:[],
                CntSel:0,
                RifEmailSel:0,
                perPage:25,
                currentPage:1,
                totalRows:1,
                pageOptions: [25, 100, 500, { value: 0, text: "Tutti" }],
                fields: [
                    // {
                    //     key: 'codtipob',
                    //     sortable: true, 
                    //     label:'Codice'
                    // },
                    {
                        key: 'destipob',
                        sortable: true,
                        label:'Tipo Ob'
                    }, 
                    {
                        key: 'rifint',
                        sortable: true,
                        label:'Cod.Int'
                    },
                    {
                        key: 'desint',
                        sortable: true,
                        label:'Intestatario'
                    },
                    {
                        key: 'tiptrg',
                        sortable: true,
                        label:'P'
                    },
                    {
                        key: 'target1',
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        label:'Trg 1'
                    },
                    {
                        key: 'perscotarget1',
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        label:'% 1'
                    },
                    {
                        key: 'target2',
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        label:'Trg 2'
                    },
                    {
                        key: 'perscotarget2',
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        label:'% 2'
                    },// {
                    //     key: 'target3',
                    //     sortable: true,
                    //     label:'Trg 3'
                    // },
                    { key: 'actions', label: '' },
                    // { key: 'mail', label: '' }
                    ],
            };
        },
        watch:{
            '$store.state.TipoInt': function(){
                this.Contratti=[];
            }
        },
        methods:{
            onDeleteContratto(idContratto){
                this.Contratti= this.Contratti.filter(c=> c.id!= idContratto);
            },
            OnChangeIntestatari(str){
                let self= this;

                var tContratto = self.Contratti.find(c=> c.id==self.CntSel);

                tContratto.txtCmd = str;
            },
            ShowLogMail(idmail){
                this.RifEmailSel=idmail;
                this.$bvModal.show('mdl_LogMail');
            },
            ShowDettInt(id){
                this.CntSel=id;
                this.$bvModal.show('mdl_Contratti_Int');
            },
            getReport(idmail){
                 var Link = 'file/v1/GetLetter?rifmail='+ idmail ;
                 
                axios.get(Link)//, state.Headers)
                .then(
                    resp => {
                    //alert(resp.data);
                        console.log(resp.data);
                    if(!resp.data)
                    {
                        self.errors.push({message:'Error while retrieving data. Maybe No Records Found'})
                    }
                    else
                    {
                        if(resp.data.type=='application/json')
                        {
                            self.errors.push({message:'No Records Found'})
                        }
                        else
                        {
                            // let blob = new Blob([resp.data], { type: 'application/octet-stream' })
                            // let link = document.createElement('a')
                            // link.href = window.URL.createObjectURL(blob)
                            // link.download = 'Report.pdf'
                            // link.click()
                        }
                    }
                })
            },
            ClickNuovo(){
                this.$bvModal.show('mdl_NewContratto');
            },
            onChangeFiltri(selClasse, selTipoOb, selSerie, txtRicercaCli, selAgente){
                let self=this;
                self.isLoading=true;
                var Link = 'api/v1/ContrattiGriglia/GetFromType?Tipo='+ self.$store.state.TipoInt +'&IncludiInviati=true';
                if(txtRicercaCli){
                    Link += '&TxtCliente='+txtRicercaCli; 
                }
                if(selTipoOb){
                    Link += '&IdTipoObiettivo='+selTipoOb;
                }
                if(selClasse){
                    Link += '&Classe='+selClasse;
                }
                if(selSerie){
                    Link += '&Serie='+selSerie ;
                }
                if(selAgente){
                    Link += '&CodAgente='+selAgente ;
                }
                axios.get(Link)//, state.Headers)
                    .then(response => {
                        self.Contratti = response.data.result;
                        self.totalRows = self.Contratti.length;
                        self.currentPage = 1;
                        self.isLoading=false;
                    }).catch(e => {
                        self.errors.push(e)
                    }); 
                // self.$store.commit('SetFiltri', {
                //     selClasse, selTipoOb, selSerie, txtRicercaCli, selAgente
                // })
            }
        }
    }
</script>