<template> 
    <div> 
    
        <template >
            <b-spinner v-if="isLoading" variant="primary" class="col-4 offset-4 text-center ml-5" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')">in costruzione</b-spinner>
            <div v-else>
                
                <div class="row">
                    
                    
                    <b-input-group v-if="RifCli==0" class="col-12 col-md-8 ml-4 mb-4"  >
                        <b-input-group-prepend>
                            <b-button variant="outline-primary">Sel.Intestatario</b-button>
                        </b-input-group-prepend>
    
                        <model-list-select  
                            :list="ElencoCli"
                            v-model="NewCli"
                            class="col-12 col-sm-7 col-md-9"
                            option-value="cod"
                            option-text="des"
                            placeholder="digita almeno 3 caratteri per ricercare"
                            @searchchange="UpdateSelectListClienti"
                            
                            
                        ></model-list-select>

                   </b-input-group>
                </div>

                <div v-if="SwiModif || ( isNew && SwiCliscelto )" id="Modif" style="width:90%; margin-left: auto; margin-right: auto;">
                    <div class="row">

                        <b-table hover 
                                small
                                bordered
                                head-variant="light" 
                                :items="ProposteDettagli" :fields="fields"
                                primary-key="id"  >

                            <template slot="descon" slot-scope="data">                                
                                {{ data.item.descon }}
                            </template>
                                                                    
                            <template v-slot:cell(targeT_1)="row">
                                <b-form-input v-model="row.item.targeT_1" class="text-right" type="number"  id="nb-target1" max="1000000" min="0"/>
                            </template>

                            <template v-slot:cell(perscotargeT_1)="row">
                                <b-form-input v-model="row.item.perscotargeT_1" class="text-right" type="number"  id="nb-perctarget1" max="100" min="0" step="0.1"/>
                            </template>
                                                                    
                            <template v-slot:cell(targeT_2)="row">
                                <b-form-input v-model="row.item.targeT_2" class="text-right" type="number"  id="nb-target2" max="1000000" min="0"/>
                            </template>

                            <template v-slot:cell(perscotargeT_2)="row">
                                <b-form-input v-model="row.item.perscotargeT_2" class="text-right" type="number"  id="nb-perctarget2" max="100" min="0" step="0.1"/>
                            </template>

                            <template v-if="isNew" v-slot:cell(fatturato)="row">
                               <!-- 
                                <b-form-input v-model="row.item.fatturato" class="text-right" type="number"  id="nb-target1" max="1000000" min="0"/>
                                -->
                                {{ row.item.fatturato }}
                            </template>

                           <template v-slot:cell(note)="row">
                                <b-form-input v-model="row.item.note" class="text" type="text"  id="nb-note" />
                            </template>
                    
                        </b-table>

                    </div>
                
                    <div class="form-row" >                         

                        <b-button variant="outline-success"    @click="doSave()" :disabled="isLoading" >
                            <font-awesome-icon icon="save"   />
                            Salva
                        </b-button>
                    
                        <b-button class="ml-4" variant="outline-warning"    @click="doCancel()"  >                                   
                            <font-awesome-icon icon="undo"   />
                            Annulla
                        </b-button>
                        
                        <b-input-group class="col-3 md-4" style="height: 38px;">
                            <b-input-group-prepend>
                                    <b-button class="ml-4" variant="outline-primary" disabled>Nuovo Stato</b-button>
                            </b-input-group-prepend>
                                <b-form-select v-model="selStato" id="selstatoVis"
                                        :options="ElencoStati"                                            
                                        
                                        value-field="keysc"
                                        text-field="dessc"
                                        disabled-field="notEnabled">
                                </b-form-select>
                        </b-input-group>
                        
                        <b-input-group v-if="!SwiAGE " class="col-3" style="height: 38px;">
                            <b-input-group-prepend>
                                    <b-button class="ml-4" variant="outline-primary" disabled>Agente</b-button>
                            </b-input-group-prepend>
                                <b-form-select v-model="selAgente" id="selAgenteVis"
                                        :options="ElAgenti"                                            
                                        
                                        value-field="codage"
                                        text-field="desage"
                                        disabled-field="notEnabled">
                                </b-form-select>
                        </b-input-group>
                    </div>
                </div>


                <div v-else-if="!SwiModif && !isNew " id="header" style="width:90%; margin-left: auto; margin-right: auto; ">
                    <div class="row"> 
 
                            <b-table style="width:30%"
                                    hover 
                                    small
                                    bordered
                                    head-variant="light" 
                                    sort-icon-left 
                                    :items="ClientiLegati" :fields="fieldsLegati" 
                                    primary-key="cod" 
                                    :busy="isLoading" >  
                                <template v-slot:table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                                <template #cell(ToDelete)="data">                                    
                                    <button class="btn btn-sm btn-danger" @click="CancellaLegato(data.item.cod)">                                        
                                        
                                        <font-awesome-icon size="sm" icon="times"  />
                                    </button>
                                </template>
                                

                            </b-table>

                                <b-col v-if= "!SwiAGE && swiwri" cols="5">
                                    <b-button variant="outline-primary"    @click="ClickAddCustomer()"  >
                                        <font-awesome-icon icon="edit"   />
                                        Aggiungi Cliente
                                    </b-button>
                                
                                </b-col>
                    </div>

                    <div class="row">
                        <b-table 
                                hover 
                                small
                                bordered
                                head-variant="light" 
                                sort-icon-left 
                                :items="ProposteDettagli" :fields="fields" 
                                primary-key="id" 
                                :busy="isLoading" >  
                            <template v-slot:table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                        </b-table>

                        <div v-if="selStato != 'I'  && swiwri" class="col-12 ">
                            <b-col cols="5">
                                <b-button variant="outline-primary"    @click="doModif()"  >
                                    <font-awesome-icon icon="edit"   />
                                    Modifica
                                </b-button>
                            
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>

        </template>  

        <b-modal  
            id="mdl_NewCutomer"
            ref="modal_NewCutomer"
            title="Nuovo Cliente Legato"
            size="xl"
            hide-footer
            centered>

            <div class="row">
                <b-input-group v-if="SwiNuovoLegato" class="col-12 col-md-8 ml-4 mb-4"  >
                            <b-input-group-prepend>
                                <b-button variant="outline-primary">Sel.Nuovo Legato</b-button>
                            </b-input-group-prepend>

                            <model-list-select  
                                :list="ElencoCliLegabili"
                                v-model="NewCliLegato"
                                class="col-12 col-sm-7 col-md-9"
                                option-value="cod"
                                option-text="des"
                                placeholder="digita almeno 3 caratteri per ricercare"
                                @searchchange="SelectClienteLegato"
                                
                                
                            ></model-list-select>

                </b-input-group>  

                <b-button variant="outline-success"  class="ml-4 mb-4"  @click="doAggiungiLegato()"  >
                    <font-awesome-icon icon="plus"   />
                    Aggiungi
                </b-button>  
                <!--SwiCliLegatoScelto -->

            </div>        

            
<!--
            <EditProposte v-if="!isLoading" :RifCli="'0'" :Rifcam="IdCampagna" :ElencoStati="elStatiModifica" :ElAgenti="ElencoAgenti" :ClientiProposte="ClientiProposte" :SwiAGE="SwiAGE" :Errori="errors" :Successi="successes" v-on:refresh="onRefresh"/>
 -->       
        </b-modal> 
    </div>
</template>

<script>
    import axios from 'axios';
    import { ModelListSelect  } from 'vue-search-select';
   
export default {
    props:{
        RifCli: {
                type: String,
                required: true
            },
        Rifcam:{
            type: Number,
            required:false
        },

        ElencoStati:{
            type: Array
        },
        ElAgenti:{
            type: Array
        },
        ClientiProposte:{
            type: Array
        },
        SwiAGE:{
            type: Boolean
        },        
        swiwri:{
            type: Boolean
        },
        Errori:{
            type: Array
        },
        Successi:{
            type: Array
        }
    },
    components:{ModelListSelect},
    data() {
        return {
            //errors: [],
            isLoading: true,
            ShowModal: false, 
            ProposteDettagli: [],
            ComboElements: {},    
            ShowEdit:false,     
            SwiModif:false,
            isNew: false,
            selStato: '',            
            swiStatoChanged: false,
            selAgente: 999999,
            ObiettiviCampagna: [],
            SwiCliscelto: false,
            NewCli: 0,
            ClientiLegati: [],
            SwiNuovoLegato: false,
            ElencoCliLegabili:[],
            NewCliLegato: 0,
            SwiCliLegatoScelto: false,
            

           
            SelectedItem:{},       
            row: Object,
            SelectedEntity: Object,
            ElencoCli:[],
            SwiSalvato:false,
            StrDettagli: this.TxtCmd,
            TxtRicercaTipoOb:'',
            TxtRicercaAgenti:'',
            awaitingSearch:false,
            fieldsLegati:[
                        {   key: "cod",  
                            label:'Clienti Legati',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-left',
                            thStyle : "width: 25%"
                       },
                            {   
                            key: "des",  
                            label:' ',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-left',
                            thStyle : "width: 80%"
                       },
                       { 
                            key: "ToDelete",
                            label:' ',
                            value: ' '
                       }     
                    ],


            fields: [
                    { 
                        key: "descon",  
                        label:'Tipo Target',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-left',
                        thStyle : "width: 25%",
                        variant: 'warning'
                    }, 
                    {
                        key: 'targeT_1',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-right',
                        label:'Target 1',
                        formatter: (value) => value.toLocaleString('it-IT', { minimumFractionDigits: 0}),
                        thStyle : "width: 10%",
                        variant: 'warning'
                    },
                    {
                        key: 'perscotargeT_1',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-right',
                        label:'% 1',
                        formatter: (value) => value.toLocaleString('it-IT', { minimumFractionDigits: 1 }),
                        thStyle : "width: 8%",
                        variant: 'warning'
                        
                    },
                    {
                        key: 'targeT_2',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-right',
                        label:'Target 2',
                        formatter: (value) => value.toLocaleString('it-IT', { minimumFractionDigits: 0 }),
                        thStyle : "width: 10%",
                        variant: 'warning'
                    },
                    {
                        key: 'perscotargeT_2',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-right',
                        label:'% 2',
                        formatter: (value) => value.toLocaleString('it-IT', { minimumFractionDigits: 1 }),
                        thStyle : "width: 8%",
                        variant: 'warning'
                    },
                    {
                        key: 'fatturato',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-right',
                        label:'Fatturato AP',
                        formatter: (value) => value.toLocaleString('it-IT', { minimumFractionDigits: 0 }),
                        thStyle : "width: 12%",
                        variant: 'warning'
                    },
                    {
                        key: 'note',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-left',
                        label:'Note',
                        thStyle : "width: 30%",
                        variant: 'warning'
                    }
                    ]
        };
    },
    watch: { 
    },
    computed:{   
    } ,
    methods:{           
        onCloseModif(){ 
            this.SwiModif=false;            
        },    
        doModif()  {           
           this.SwiModif=!this.SwiModif;
        },
        doCancel(){
            //let self=this;    
            
            if(this.isNew){
                this.isNew = false;
                this.$nextTick(() => {
                    this.$bvModal.hide('mdl_NewProposta')
                    })
            }

            //Perchè la read se sto annullando ?
            //this.ReadDettagli(self.RifCli,self.Rifcam)
            this.onCloseModif();
        },
        doSave(){
            let self=this;

            self.isLoading = true;

            if (self.isNew) {
                Array.from(self.ProposteDettagli).forEach(item => {  
                        item.codcli = this.NewCli
                        item.flaG_STATO = this.selStato
                        item.codage = this.selAgente  
                        item.idcampagna = this.Rifcam
                    }
                )  
                this.swiStatoChanged = true;
                
            }
            else {
                this.swiStatoChanged =false;

                Array.from(self.ProposteDettagli).forEach(item => {
                        if(item.flaG_STATO != this.selStato){
                            this.swiStatoChanged = true;
                            item.flaG_STATO = this.selStato
                        }

                            if(item.codage != this.selAgente){
                            this.swiStatoChanged = true;
                            item.codage = this.selAgente
                        }                              

                    }
                )      
            }

                axios.post('api/v1/Contratti/',Array.from(self.ProposteDettagli))
                    .then(resp => {
                        self.isLoading = false;
                        if(resp.data.result)
                        {                                     
                            //console.log("salvato record"); 
                            self.Successi.splice(0);
                            self.Successi.push({id:1, message: "Dati Salvati"});
                            this.onCloseModif();
                            if (self.isNew) 
                            {
                                self.isNew = false;
                                
                                this.$nextTick(() => {
                                    this.$bvModal.hide('mdl_NewProposta')
                                    })

                                self.$emit("refresh",this.Rifcam);

                            }
                            if(this.swiStatoChanged){
                                self.$emit("refresh",this.Rifcam);
                            }
                                
                        }  
                        else
                        {   
                            self.Errori.splice(0);
                            self.Errori.push({id:1, message: "Dati non salvati"});
                        }

                    })
                    .catch(e => {
                        if (e.response) {
                            self.Errori.splice(0);
                            self.Errori.push({id:1, message: "Dati non salvati: "+e.response.data.detail});

                            if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                //console.log("Errore in catch 1:" + e.response.data.detail);
                                self.Errori.splice(0);
                                self.Errori.push({ message:  e.response.data.detail});
                            }
                            else {
                                //console.log("Errore in catch 2:" + e.message);
                                self.Errori.splice(0);
                                self.Errori.push(e)
                            }
                        }
                        else {
                            //console.log("Errore in catch 3:" + e.message);
                            self.Errori.splice(0);
                            self.Errori.push({id:1, message: "Dati non salvati: "+e.response.data.detail});

                        }
                        self.isLoading = false;
                    });                         

        }, 
        doAggiungiLegato(){
            let self=this; 

            console.log('self.NewCliLegato = ' + self.NewCliLegato )

            var Link = 'api/v1/Intestatari/AggiungiLegato';

               var tParametri ={     
                    idCampagna: self.Rifcam  ,
                    codclipad: self.RifCli,
                    codclidet: self.NewCliLegato
                } 

                axios.post(Link,tParametri)
                    .then(resp => {
                    if(resp.data.result)
                    {                                     
                        //console.log("salvato record"); 
                        self.Successi.splice(0);
                        self.Successi.push({id:1, message: "Dati Salvati"});
                        self.NewCliLegato = 0
  
                            
                        this.$nextTick(() => {
                            this.$bvModal.hide('mdl_NewCutomer')
                            })


                            this.ReadLegati(self.RifCli,self.Rifcam)
/*
                            self.$emit("refresh",this.Rifcam);

                        }
                        if(this.swiStatoChanged){
                            self.$emit("refresh",this.Rifcam);
                        }
                        */
                            
                    }  
                    else
                    {   
                        self.Errori.splice(0);
                        self.Errori.push({id:1, message: "Dati non salvati"});
                    }

                })
                .catch(e => {
                    if (e.response) {
                        self.Errori.splice(0);
                        self.Errori.push({id:1, message: "Dati non salvati: "+e.response.data.detail});

                        if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                            //console.log("Errore in catch 1:" + e.response.data.detail);
                            self.Errori.splice(0);
                            self.Errori.push({ message:  e.response.data.detail});
                        }
                        else {
                            //console.log("Errore in catch 2:" + e.message);
                            self.Errori.splice(0);
                            self.Errori.push(e)
                        }
                    }
                    else {
                        //console.log("Errore in catch 3:" + e.message);
                        self.Errori.splice(0);
                        self.Errori.push({id:1, message: "Dati non salvati: "+e.response.data.detail});

                    }
                    self.isLoading = false;
                });

            

        },


        UpdateSelectListClienti(searchText) {
                let self = this;  
                //serve per bloccare il caricamento quando selezione l'elemento dell'elenco
                if (self.NewCli && searchText=="") { 
                        // imposta la visibilità della griglia per l'input
                        self.SwiCliscelto = true;
                        return; 
                    } 

                

                // maggiore uguale 3 caratteri
                if(searchText.length>=3){
                    if (!self.awaitingSearch) {
                        setTimeout(() => {
                                axios.get('api/v1/Intestatari/GetFromType?Tipo='+ self.$store.state.TipoInt +'&str='+searchText)
                                    .then(response => {                            

                                         // mostra solo i clienti che NON hanno già una proposta
                                        self.ElencoCli = response.data.result.filter(item => !self.ClientiProposte.includes(item.cod));

                                        
                                    }).catch(e => {
                                        self.Errori.splice(0);
                                        self.Errori.push(e)
                                    });
                            
                            self.awaitingSearch = false;
                        }, 700); // 0,5 sec delay

                    }

                    self.awaitingSearch = true;
                    
                }
            },
            SelectClienteLegato(searchText) {
                let self = this;  
                //serve per bloccare il caricamento quando selezione l'elemento dell'elenco
                if (self.NewCli && searchText=="") { 
                        // imposta la visibilità della griglia per l'input
                        self.SwiCliLegatoScelto = true;
                        return; 
                    }                 

                // maggiore uguale 3 caratteri
                if(searchText.length>=3){
                    if (!self.awaitingSearch) {
                        setTimeout(() => {
                                axios.get('api/v1/Intestatari/GetFromType?Tipo='+ self.$store.state.TipoInt +'&str='+searchText)
                                    .then(response => {                            

                                         // mostra solo i clienti che NON sono già legati                                  
                                        self.ElencoCliLegabili = response.data.result.filter(function (el)
                                        {
                                            return !self.ClientiLegati.find(function(element) {
                                                    return element.cod == el.cod;
                                            });                                           
                                        });
                                                                                
                                    }).catch(e => {
                                        self.Errori.splice(0);
                                        self.Errori.push(e)
                                    });
                            
                            self.awaitingSearch = false;
                        }, 700); // 0,5 sec delay

                    }

                    self.awaitingSearch = true;
                    
                }
            },
        ReadDettagli(parRifcli,parRifcam){
            let self=this;
            var Link = 'api/v1/Contratti/TargetCli' ; 
            var tParametri ={
                    CODCLI: parRifcli,    
                    rifcam: parRifcam                    
                }
            
            axios.post(Link,tParametri)
                        .then(response => {
                            self.ProposteDettagli = response.data.result;
                            self.selStato = (Array.from(self.ProposteDettagli))[0].flaG_STATO;
                            self.selAgente = (Array.from(self.ProposteDettagli))[0].codage;                            
                            self.isLoading=false;
                        }).catch(e => {
                            self.Errori.splice(0);
                            self.Errori.push(e)
                        }); 
        },
        ReadObiettivi(parRifcam){
            let self=this;
            var Link = 'api/v1/TipiObiettivi/Obiettivi/'+ parRifcam; 
           
            axios.get(Link)
                        .then(response => {
                            self.ObiettiviCampagna = response.data.result;
                            Array.from(self.ObiettiviCampagna).forEach(item => {
                                    
                            // prepara array per tutti i Tipi Target necessari
                            self.ProposteDettagli.push( {"descon":item.descon,
                                                    "targeT_1" : 0,
                                                    "perscotargeT_1" : 0,
                                                    "targeT_2" : 0,
                                                    "perscotargeT_2" : 0,
                                                    "fatturato" : 0,
                                                    "note" : "",
                                                    
                                                    "target_3" : 0,
                                                    "perscotarget_3" : 0,  
                                                    
                                                    "codcli":"0",
                                                    "flaG_STATO":"N",
                                                    "selAgente":0,
                                                    "idcampagna": parRifcam,
                                                    "riftipocontratto": item.id,
                                                    "target_prop": 0
                                                    } );
                            });

                            self.isLoading=false;
                        }).catch(e => {
                            self.Errori.splice(0);
                            self.Errori.push(e)
                        }); 

        },
        ReadLegati(parRifcli,parRifcam){
            let self=this;
            var Link =  'api/v1/Intestatari/GetLegati?rifcam='+ parRifcam +'&codcli='+parRifcli
                        
            axios.get(Link)
                        .then(response => {
                            self.ClientiLegati = response.data.result;                          
                            self.isLoading=false;
                        }).catch(e => {
                            self.Errori.splice(0);
                            self.Errori.push(e)
                        }); 
        },           
        ClickAddCustomer(){
                let self=this;
                self.SwiNuovoLegato = true;
                console.log('self.SwiNuovoLegato = ' + self.SwiNuovoLegato)
                self.$bvModal.show('mdl_NewCutomer');
            },
        CancellaLegato(IdLegato){
            
             if (confirm('Vuoi davvero cancellare il cliente legato ?'))
			{
				console.log('allora cancella= ');
                this.DoCancellaLegato(IdLegato)
			}    
        }, 
        DoCancellaLegato(IdLegato){
            let self=this; 

            console.log('sIdLegato = ' + IdLegato)

            var Link = 'api/v1/Intestatari/CancellaLegato';

               var tParametri ={     
                    idCampagna: self.Rifcam  ,
                    codclipad: self.RifCli,
                    codclidet: IdLegato
                } 

                axios.post(Link,tParametri)
                    .then(resp => {
                    if(resp.data.result)
                    {                                     
                        //console.log("Legato Cancellato"); 
                        self.Successi.splice(0);
                        self.Successi.push({id:1, message: "Legato Cancellato"});
                            
                        this.ReadLegati(self.RifCli,self.Rifcam)                          
                    }  
                    else
                    {   
                        self.Errori.splice(0);
                        self.Errori.push({id:1, message: "Attenzione Legato NON Cancellato"});
                    }

                })
                .catch(e => {
                    if (e.response) {
                        self.Errori.splice(0);
                        self.Errori.push({id:1, message: "Legato NON Cancellato: "+e.response.data.detail});

                        if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                            //console.log("Errore in catch 1:" + e.response.data.detail);
                            self.Errori.splice(0);
                            self.Errori.push({ message:  e.response.data.detail});
                        }
                        else {
                            //console.log("Errore in catch 2:" + e.message);
                            self.Errori.splice(0);
                            self.Errori.push(e)
                        }
                    }
                    else {
                        //console.log("Errore in catch 3:" + e.message);
                        self.Errori.splice(0);
                        self.Errori.push({id:1, message: "Legato NON Cancellato > : "+e.response.data.detail});

                    }
                    self.isLoading = false;
                });     
        },


        
  
    },
    beforeMount(){ 
        let self=this;
        
        if(self.RifCli!=0){
            this.ReadDettagli(self.RifCli,self.Rifcam)
        }
        else{
            //nuova proposta
            self.isLoading=false;

            this.ReadObiettivi(self.Rifcam);    

            self.selStato = "N";    
            self.selAgente = self.ElAgenti[0].codage;   

            self.isNew = true;
	
        }

        this.ReadLegati(self.RifCli,self.Rifcam)
    }
}
</script>