<template>
    <div class="row mt-2"> 
        <h5 class="col-12">Opzioni</h5>
        <p class="col-10 offset-2">work in progress</p>
    </div>
</template>
<script>
export default {
    
}
</script>