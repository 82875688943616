import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies'
import BootstrapVue  from 'bootstrap-vue';
// Componenti del progetto
import Home from './Home.vue';
import AnaSerie from './AnaSerie.vue';
import AnaClassi from './AnaClassi.vue';
import AnaTipiOb from './AnaTipiOb.vue';
import AnaTipoObEdit from './AnaTipoObEdit.vue';
import AnaCampagneWeb from './AnaCampagneWeb.vue';


import DuplicaContratti from './DuplicaContratti.vue';
import DuplicaSerie from './DuplicaSerie.vue';
import MultiModifica from './MultiModifica.vue';
import Opzioni from './Opzioni.vue';
import StampaLettere from './StampaLettere.vue';
import InviaLettere from './InviaLettere.vue';
import ProposteWEB from './ProposteWEB.vue'
import UtentiElenco from './UtentiElenco.vue'
import GenericoElenco from './GenericoElenco.vue'
import ElencoFileImport from './ElencoFileImport.vue'
import ElencoProcessi from './ElencoProcessi.vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'vue-search-select/dist/VueSearchSelect.css'


import './assets/bag-discount.png'

import axios from 'axios';
import moment from 'moment';

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(moment);
Vue.use(BootstrapVue);
// Imposta i routes
Vue.use(VueRouter);
const router = new VueRouter();

// Cookies
Vue.use(VueCookies)

// Vuex
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        errors: [],        
        Series: {}, 
        Classi: {},
        TipiOb:{},
        Token: '',
        Headers : {},        
        LoggedIn: false,        
        baseUrl: '',
        ParFiltri: {},
        TipiOperazione:{},
        ObiettiviLiquidazioni: {},
        RegoleLiquidazioni:{},
        Agenti:{},
        Username:'',
        TipoInt:0,
        Filtri:{},        
        swiAMM: false,
        swiRV: false,
        swiAreaM: false,  
        swiAGE: false,    
        AreaManager:{},
        VisibleMenu:[]
        //AgentiPerUser:{},
    },
    mutations: {
        IsStarted: state => state.Started = false,        
        SetToken(state, parToken) {            
            state.Token = parToken;    

            const config = {
                headers: { Authorization: 'Analytic ' + parToken }
            };
            state.Headers = config;                        
        },
        SetswiAMM(state, swiAMM) {
            state.swiAMM = swiAMM;    
        },
        SetswiRV(state, swiRV) {
            state.swiRV = swiRV;    
        },
        SetswiAreaM(state, swiAreaM) {
            state.swiAreaM = swiAreaM;    
            //console.log('state.swiAreaM '+ state.swiAreaM )
        },
        SetswiAGE(state, swiAGE) {
            state.swiAGE = swiAGE;    
            //console.log('state.swiAGE '+ state.swiAGE )
        },
        SetUsername(state, Username) {
            state.Username = Username;    
        },
        SetTipoInt(state, TipoInt) {            
            var Link = 'api/v1/TipiObiettivi?Tipo='+TipoInt ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.TipiOb = response.data.result;
                    state.TipoInt = TipoInt;                      
                }).catch(e => {
                    state.errors.push(e);
                    state.TipoInt = TipoInt;   
                }); 
        },
        SetSeries(state) {
            console.log('SetSeries'); 
            // Scarico le serie
            var Link = 'api/v1/Series' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.Series = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });
            
        },
        SetClassi(state) {
            console.log('SetClassi'); 
            // Scarico le serie
            var Link = 'api/v1/Classi' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.Classi = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });
            
        },
        SetTipiOb(state) {
            //console.log('SetTipiOb'); 
            // Scarico le serie
            var Link = 'api/v1/TipiObiettivi?Tipo='+state.TipoInt ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.TipiOb = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });
            
        },
        SetBaseUrl(state, parBaseUrl) {
            state.baseUrl = parBaseUrl;
        },                
        SetLoggedIn(state, parLoggedIn) {
            state.LoggedIn = parLoggedIn;
        },
        GetInitialData(state){
            

            var Link = 'api/_gen/Visible_Menu' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.VisibleMenu = response.data.result;
                    var tMenu=state.VisibleMenu.filter(m=> m.visible);
                    if(tMenu.length>=1){
                        state.TipoInt =tMenu[0].rifint;
                    }
                    axios.all([
                        // Elenco Tipo Obiettivi
                        axios.get('api/v1/TipiObiettivi?Tipo='+state.TipoInt ),
                        // Classi
                        axios.get('api/v1/Classi'),
                        // Series
                        axios.get('api/v1/Series'),
                        // par filtri
                        axios.get('api/_gen/Par_Filtri'),
                        // tipo operazione
                        axios.get('api/_gen/Tipo_Operazione' ),
                        // regole liquidazione
                        axios.get('api/_gen/Tipo_Regole_Liquidazione'),
                        //ob liquidazione
                        axios.get('api/_gen/Combo_Obiettivi_Liquidazione'),
                        //Agenti
                        axios.get('api/v1/Agenti'),
                        //Area Manager
                        axios.get('api/v1/AreaManager'),
                    ])
                        .then(axios.spread((...responses) => {
                            // tipi ob
                            state.TipiOb = responses[0].data.result;
                            // Classi
                            state.Classi = responses[1].data.result;
                            // series
                            state.Series = responses[2].data.result;
                            // filtri condizioni
                            state.ParFiltri = responses[3].data.result;
                            // TipiOperazione
                            state.TipiOperazione = responses[4].data.result;
                            // Regole Liquidazioni
                            state.RegoleLiquidazioni = responses[5].data.result;
                            // Obiettivi Liquidazioni
                            state.ObiettiviLiquidazioni = responses[6].data.result;
                            // Obiettivi Liquidazioni
                            state.Agenti = responses[7].data.result;
                            //AreaManager
                            state.AreaManager = responses[8].data.result;
                            state.IsStarted = true;
                        })).catch(e => {
                            state.errors.push(e)
                        });
        
                }).catch(e => {
                    state.errors.push(e)
                });

           
/*
            Link = 'api/v1/Classi' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.Classi = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });

            Link = 'api/v1/Series' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.Series = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });
       
            Link = 'api/_gen/Par_Filtri' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.ParFiltri = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });
            Link = 'api/_gen/Tipo_Operazione' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.TipiOperazione = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });

            Link = 'api/_gen/Tipo_Regole_Liquidazione' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.RegoleLiquidazioni = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });
            Link = 'api/_gen/Combo_Obiettivi_Liquidazione' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.ObiettiviLiquidazioni = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });    
                
            Link = 'api/v1/Agenti' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.Agenti = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });

              
            Link = 'api/v1/AreaManager' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.AreaManager = response.data.result;

                    //console.log('AreaManager' + response.data.result.length);

                }).catch(e => {
                    state.errors.push(e)
                });
*/
            //#RT modificare con API che legge agenti validi per user loggato
           /* Link = 'api/_gen/Agente' ;
            axios.get(Link)//, state.Headers)
                .then(response => {
                    state.AgentiPerUser = response.data.result;
                }).catch(e => {
                    state.errors.push(e)
                });
                */

            //#RT aggiugere API che legge elenco stati contratto e gestire poi in FiltriProposte


        },
        SetFiltri(state, parFiltri){
            state.Filtri=parFiltri;
        }
        //decrement: state => state.count--
    }
})


new Vue({
    router,
    store,
    beforemount(){
    },
    mounted() {
        router.addRoutes(
            [  
                { path: '/AnaSerie', component: AnaSerie, name:"AnaSerie"},
                { path: '/AnaClassi', component: AnaClassi},
                { path: '/AnaTipiOb', component: AnaTipiOb },                
                { path: '/AnaTipoObEdit', component: AnaTipoObEdit, props: (route) => ({ IdTipoOb: route.query.riftipoob }) },
                { path: '/AnaCampagneWeb', component: AnaCampagneWeb },
                { path: '*', component: Home }, // catch all redirect
                { path: '/DuplicaContratti', component: DuplicaContratti, name:"DuplicaContratti"},
                { path: '/MultiModifica', component: MultiModifica},
                { path: '/Opzioni', component: Opzioni },
                { path: '/ImportaFile', component: ElencoFileImport },                
                { path: '/Processi', component: ElencoProcessi },                
                { path: '/DuplicaSerie', component: DuplicaSerie},
                { path: '/StampaLettere', component: StampaLettere},
                { path: '/InviaLettere', component: InviaLettere},
                { path: '/ProposteWEB', component: ProposteWEB },
                { path: '/Utenti', component: UtentiElenco },
                { path: '/Fornitori', component: GenericoElenco, props: ({ EntityType: 'Fornitore', Title: 'Fornitori', KeyProp: 'codfor' }) },
                { path: '/ProfitCenter', component: GenericoElenco, props: ({ EntityType: 'ProfitCenter', Title: 'ProfitCenter', KeyProp: 'codpc' }) }



            ]
        );
        //this.$router.listen((newLocation) => { console.log(newLocation); })


        // this.$store.commit('SetSeries');
        // this.$store.commit('SetClassi');
        // this.$store.commit('SetTipiOb');
        
    },
  render: h => h(App),
}).$mount('#app')
