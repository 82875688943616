<template>
    <div class="container-fluid">
     <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Error : {{error.message}}
                </li>
            </ul>
        </b-alert>

        <!-- Successi -->
        <b-alert v-if="successes && successes.length" variant="success" show dismissible>
            <ul>
                <li v-for="success of successes" :key="success.id">
                    Success : {{success.message}}
                </li>
            </ul>
        </b-alert>

         <b-row>
            <div class="col-md-2">
                <h3 id="h3_title" class="mt-2 ml-4">Elenco Proposte </h3>
            </div>
            <div class="col-md-3">

               <b-input-group class="mt-2 ml-1"  style="height: 38px;"> 
                    <b-input-group-prepend>
                        <b-button variant="outline-primary" disabled>Campagna</b-button>
                    </b-input-group-prepend>

                    <b-form-select v-model="IdCampagna"
                            :options="CampagneAttuali"
                            selected="IdCampagna"
                            
                            value-field="id"
                            text-field="descam"
                            disabled-field="notEnabled"
                            @input="ExecRicerca">
                    </b-form-select>
                </b-input-group>
            </div>
            <div class="col-md-3">
               <b-input-group class=" mt-2 "> 
                    <b-form-input v-model="RicercaCli" class="text" type="text"  style="height: 38px;" id="nb-ricercacli" placeholder="Nome cliente da filtrare"/>
                   <b-input-group-append>
                        <b-button variant="outline-secondary" style="height: 38px;" @click="RicercaCli='' ">x</b-button> 
                    </b-input-group-append>                    
                </b-input-group>
            </div>
            <div class="col">   

                <b-button v-if="!SwiRicAuto" v-show="!ShowFiltri" class=" mt-2" variant="outline-primary" style="height: 38px;" @click="ExecRicerca"> Cerca </b-button>
                 
           <!--
                <button  v-on:click="ShowFiltri=!ShowFiltri" class="btn btn-link btn-sm  mt-2 ml-3 " style="height: 38px;">
                    <font-awesome-icon size="sm" icon="filter"  />
                    <span class="col-1"> Filtri</span>
                </button>
                -->
            </div>
         </b-row>
             
 
        
        <!--b-row v-show="ShowFiltri" class="container-fluid ml-5 mb-2 mt-2">            -->
        <!--<b-row class="container-fluid ml-5 mb-2 mt-2">       -->
        <b-row>    
            <div class="col-md-2">
            </div>
            <div class="ml-1 col-md-2">
                <b-input-group  style="height: 38px;">
                    <b-input-group-prepend>
                        <b-button variant="outline-primary" disabled>Stato</b-button>
                    </b-input-group-prepend>

                    <b-form-select v-model="selStato" id="selstatoVis"
                            :options="elStati"                            
                            
                            value-field="keysc"
                            text-field="dessc"
                            disabled-field="notEnabled">
                        
                    </b-form-select>
                </b-input-group>   
            </div>
            <div class="col-md-4">

                <b-input-group v-if="SwiAmm || SwiRV"   style="height: 38px;"> 
                    <b-input-group-prepend class="">
                        <b-button variant="outline-primary" disabled>Area Manager</b-button>
                    </b-input-group-prepend>
                                
                    <model-list-select 
                            :list="ElencoAreaManager"
                            v-model="selAreaManager"
                            class="col rounded-0"
                            option-value="codAream"
                            option-text="desAream"                             
                            
                    ></model-list-select>
                    
                    
                    <b-input-group-append>
                        <b-button variant="outline-secondary" @click="selAreaManager=''; ResetSelectListAreaM()">x</b-button> 
                    </b-input-group-append>
                        
                </b-input-group>           
            </div>
            <div class="col-md-3"> 
                <b-input-group v-if="SwiAmm || SwiRV || swiAreaM"  style="height: 38px;"> 
                    <b-input-group-prepend class="">
                        <b-button variant="outline-primary" disabled>Agente</b-button>
                    </b-input-group-prepend>
                                
                    <model-list-select 
                            :list="ElencoAgenti"
                            v-model="selAgente"
                            class="col rounded-0"
                            option-value="codage"
                            option-text="desage" 
                            
                            
                    ></model-list-select>
                    
                    <b-input-group-append>
                        <b-button variant="outline-secondary" @click="selAgente=''; ResetListAgenti()">x</b-button> 
                    </b-input-group-append>
                </b-input-group>
            </div>
<!--
            <b-input-group v-if="SwiAmm || SwiRV || swiAreaM" class="col-3 offset-1"  style="height: 38px;"> 
                <b-input-group-prepend class="">
                    <b-button variant="outline-primary" disabled>Agente</b-button>
                </b-input-group-prepend>
                            
                <model-list-select 
                        :list="ElencoAgenti"
                        v-model="selAgente"
                        class="col-7 rounded-0"
                        option-value="codage"
                        option-text="desage" 
                        
                        
                ></model-list-select>
                
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="selAgente=''; ResetListAgenti()">x</b-button> 
                </b-input-group-append>
            </b-input-group>


            <b-input-group class="col-2 col-md-2 ml-3" style="height: 38px;">
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Stato</b-button>
                </b-input-group-prepend>

                <b-form-select v-model="selStato" id="selstatoVis"
                        :options="elStati"                            
                        
                        value-field="keysc"
                        text-field="dessc"
                        disabled-field="notEnabled">
                    
                </b-form-select>
            </b-input-group>
            

            <b-input-group v-if="SwiAmm || SwiRV" class="col-3"  style="height: 38px;"> 
                <b-input-group-prepend class="">
                    <b-button variant="outline-primary" disabled>Area Manager</b-button>
                </b-input-group-prepend>
                            
                <model-list-select 
                        :list="ElencoAreaManager"
                        v-model="selAreaManager"
                        class="col-7 rounded-0"
                        option-value="codAream"
                        option-text="desAream"                             
                        
                ></model-list-select>
                
                
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="selAreaManager=''; ResetSelectListAreaM()">x</b-button> 
                </b-input-group-append>
                    
            </b-input-group>
                             -->

                <!--
                <b-button v-if="!SwiRicAuto" variant="outline-primary" @click="ExecRicerca"> Cerca </b-button>
                -->
                
            
        </b-row>
        
        

        <div class="row px-5">
            
        </div>
        <div class="row px-5 mt-3 mb-2">
            <b-col sm=1  offset-md="1">
                <b-button title="Aggiungi nuova proposta" pill variant="outline-success" class="ml-2" align="right" @click="ClickNuovo()">
                    <font-awesome-icon size="sm" icon="plus" />
                </b-button>
            </b-col>
            <b-col sm="2" class="my-1">
                <b-form-group
                label="Per pagina"
                label-for="per-page-select"
                label-cols-sm="6"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="6" md="2" class="my-0 ">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
                ></b-pagination>
            </b-col>
            <b-col offset="1" sm="4" md="2" align-self="center">
                <label for="" class="col-form-label col-form-label-sm "> Righe Estratte {{(!Proposte.length)?0: Proposte.length}}</label>
            </b-col>
            
        </div>

        <div>
            <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-if="SwiAmm || SwiRV || swiAreaM " block v-b-toggle.accordion-1 variant="info">Amministrazione</b-button>
            </b-card-header>
            <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
                <b-card-body>

                    <div class="form-row" >
              
                        <div class="form-row" > 
                         
                            <b-button variant="outline-secondary" class="ml-4"   @click="doSelectAll()"   >                                
                                {{(isSelectedAll)?'Deseleziona': 'Seleziona'}} Tutti
                            </b-button>
                        
                        <!--
                            <b-button variant="outline-secondary" class="ml-4"   @click="doExpandAll()"  >                                
                                {{(isExpandedAll)?'Chiudi': 'Espandi'}} Tutti
                            </b-button>
                        -->
                    
                            <label class="ml-4 mt-2">
                                Assegna Stato :
                            </label>
                                <b-button v-if="SwiAmm || swiAreaM " variant="outline-success" class="ml-4"   @click="doStato('A')"  >                                
                                    Approvato
                                </b-button>                            
                                <b-button v-if="SwiAmm || SwiRV || swiAreaM " class="ml-4" variant="outline-danger"    @click="doStato('B')"  >  
                                    Bloccato
                                </b-button>          
                                <b-button v-if="SwiAmm || SwiRV " variant="outline-info" class="ml-4"   @click="doStato('C')"  >                                
                                    Confermato
                                </b-button>                            
                                <b-button v-if="SwiAmm || SwiRV || swiAreaM " class="ml-4" variant="outline-primary"    @click="doStato('N')"  >  
                                    Nuovo
                                </b-button>           
                                <b-button v-if="SwiAmm || SwiRV || swiAreaM " class="ml-4" variant="outline-warning"    @click="doStato('R')"  >  
                                    Da Rivedere
                                </b-button>    
                        </div>

                    </div>

                </b-card-body>
            </b-collapse>
            </b-card>
        </div>

  
        <div >
             
            <b-table ref="TabProposte"
                    hover 
                    small
                    head-variant="dark" 
                    sort-icon-left 
                    :items="Proposte" :fields="fields" 
                    primary-key="id" 
                    :busy="isLoading"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @row-clicked=" onRowClicked">  <!-- serve per aprire e chiudere i dettagli su click riga -->
                <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                         
                <template #cell(preview)="data">
                    <b-form-checkbox
                        v-model="data.item.preview"
                        @change="onPreviewClick($event, data.index, data.item)"
                    ></b-form-checkbox>
                </template>

                <template #cell(editrow)="data">
                    <b-button size="sm" @click="data.toggleDetails" variant="outline-secondary">
                        <font-awesome-icon v-if="data.detailsShowing" size="lg" icon="angle-up" />
                        <font-awesome-icon v-else size="lg" icon="angle-down"   />
                    </b-button>
                </template>

                <!-- A custom formatted column -->
                <template #cell(dessc)="data">
                     
                    <b v-if="data.item.dessc == 'Approvato'" class="text-success">{{ data.item.dessc }}</b>
                    <b v-else-if="data.item.dessc =='Bloccato'" class="text-danger">{{ data.item.dessc }}</b>
                    <b v-else-if="data.item.dessc =='Confermato'" class="text-info">{{ data.item.dessc }}</b>
                    <b v-else-if="data.item.dessc =='Nuovo'" class="text-primary">{{ data.item.dessc }}</b>
                    <b v-else-if="data.item.dessc =='Da rivedere'" class="text-warning">{{ data.item.dessc }}</b>
                    <b v-else-if="data.item.dessc =='Importato'" class="text-secondary">{{ data.item.dessc }}</b>
                    <b v-else class="text-secondary">{{ data.item.dessc }}</b>      
 
                </template>

                <template slot="row-details" slot-scope="row">
                    <b-card>
                    <EditProposte :RifCli="row.item.codcli" :Rifcam="IdCampagna"  :ElencoStati="elStatiModifica" :ElAgenti="ElencoAgenti" :SwiAGE="SwiAGE" :swiwri="swiwri" :Errori="errors" :Successi="successes" v-on:refresh="onRefresh"/>
                    </b-card>
                </template>

            </b-table>
        </div>


        <b-modal  
            id="mdl_NewProposta"
            ref="modal_NewContratto"
            title="Nuova Proposta"
            size="xl"
            hide-footer
            centered>
            <EditProposte v-if="!isLoading" :RifCli="'0'" :Rifcam="IdCampagna" :ElencoStati="elStatiModifica" :ElAgenti="ElencoAgenti" :ClientiProposte="ClientiProposte" :SwiAGE="SwiAGE" :swiwri="swiwri" :Errori="errors" :Successi="successes" v-on:refresh="onRefresh"/>
        </b-modal>


    </div>
</template>
<script>
    import { ModelListSelect  } from 'vue-search-select';   
    import EditProposte from './components/EditProposte.vue';  
    import axios from 'axios';

    export default {
        
        components: { 
            EditProposte,ModelListSelect
        },

        data() {
            return {
                errors: [],
                successes: [],
                isLoading: false,                
                Proposte:[],                
                perPage:25,
                currentPage:1,
                totalRows:1,
                ShowFiltri:false,
                pageOptions: [25, 100, 500, { value: 0, text: "Tutti" }],               
                selAgente:'',
                selAreaManager:'',
                selStato:'X',
                RicercaCli: '',
                SwiRicAuto:false,

                SatusChange: [],


                fields: [
                     { 
                        key: "preview",  label:'S'
                    },                  
                    {
                        key: 'descli',
                        sortable: true,
                        label:'Cliente'
                    },
                    {
                        key: 'codcli',
                        sortable: true,
                        label:'Cod.Cli'
                    },
                    {
                        key: 'dessc',
                        sortable: true,
                        label:'Stato',
                    },
                    {
                        key: 'desage',
                        sortable: true,
                        label:'Agente'
                    }, 
                    { key: 'actions', label: '' },

                    { 
                        key: "editrow",  label:''
                    },             
                    // { key: 'mail', label: '' }
                    ],
                    elStati:[], 
                    elStatiModifica:[],
                    CampagneAttuali:[],          
                    SwiCampagna : false,
                    IdCampagna : 0,
                    swiwri: false,
                    ClientiProposte:[],   
                    isSelectedAll: false,
                    SelectedInt:[],
                    isExpandedAll: false,
                    ChangedStatus:[],

            };
        },
        watch:{
            /*
            '$store.state.TipoInt': function(){
                this.Contratti=[];
            },*/
            selAgente: function (){
                if(this.SwiRicAuto){
                     this.ExecRicerca()
                }
            },
            selAreaManager: function(){
                 var Link = 'api/v1/Agenti/PerAreaManager/' + this.selAreaManager;
                axios.get(Link)//, state.Headers)
                    .then(response => {
                        this.$store.state.Agenti = response.data.result;

                        this.selAgente = '';

                    }).catch(e => {
                        self.errors.splice(0);
                        self.errors.push(e)
                    });
            },
            IdCampagna:function(){
                this.swiwri = this.CampagneAttuali.find(x => (x.id == this.IdCampagna)).swiwri;
                //console.log(this.swiwri );                  
            },
            /*
            txtRicercaCli:function(){
                if(this.SwiRicAuto){
                    this.ExecRicerca()
                }
            }*/
        },
        computed:{
            ElencoAgenti:function(){                
                return this.$store.state.Agenti;     
            },
            ElencoAreaManager:function(){                
                return this.$store.state.AreaManager;     
            },
            SwiAmm(){
                //console.log("SwiAmm ", + this.$store.state.swiAMM);
                return this.$store.state.swiAMM;
            },
            SwiRV(){
                //console.log("SwiRV ", + this.$store.state.swiRV);
                return this.$store.state.swiRV;
            },
            swiAreaM(){
                console.log("swiAreaM = ", + this.$store.state.swiAreaM);
                return this.$store.state.swiAreaM;
            },
            SwiAGE(){
                console.log("SwiAGE = ", + this.$store.state.swiAGE);
                return this.$store.state.swiAGE;
            }
          
        }, 
        methods:{
            onRowClicked(item){
                this.successes.splice(0);
                item._showDetails = !item._showDetails;
            },
            /*
            onDeleteContratto(idContratto){
                this.Contratti= this.Contratti.filter(c=> c.id!= idContratto);
            },*/
            onRefresh(idcampagna){
                this.onChangeFiltriProposte(idcampagna);
            },
            /*
            OnChangeIntestatari(str){
                let self= this;

                var tContratto = self.Contratti.find(c=> c.id==self.CntSel);

                tContratto.txtCmd = str;
            },*/
            /*
            ShowLogMail(idmail){
                this.RifEmailSel=idmail;
                this.$bvModal.show('mdl_LogMail');
            },
            */
            /*
            ShowDettInt(id){
                this.CntSel=id;
                this.$bvModal.show('mdl_Contratti_Int');
            },
            */
           /*
            getReport(idmail){
                 var Link = 'file/v1/GetLetter?rifmail='+ idmail ;
                 
                axios.get(Link)//, state.Headers)
                .then(
                    resp => {
                    
                    if(!resp.data)
                    {
                        self.errors.push({message:'Error while retrieving data. Maybe No Records Found'})
                    }
                    else
                    {
                        if(resp.data.type=='application/json')
                        {
                            self.errors.push({message:'No Records Found'})
                        }
                        else
                        {
                            // let blob = new Blob([resp.data], { type: 'application/octet-stream' })
                            // let link = document.createElement('a')
                            // link.href = window.URL.createObjectURL(blob)
                            // link.download = 'Report.pdf'
                            // link.click()
                        }
                    }
                })
            },*/
            ClickNuovo(){
                this.$bvModal.show('mdl_NewProposta');
            },
           
            onChangeFiltriProposte(idcampagna) {

                if (idcampagna == 0) {
                    return;
                }

                let self=this;
                self.isLoading=true;
                var Link = 'api/v1/Contratti/ElencoWeb';

                self.successes.splice(0);

                var tParametri ={                    
                    startrow: 0,
                    MAXROWS: 10000,
                    rifcam: idcampagna  ,
                    codAge: self.selAgente,
                    codAream: self.selAreaManager,
                    DesCli: self.RicercaCli,
                    FlagStato: self.selStato
                } 

                axios.post(Link,tParametri)
                        .then(response=>{
                            self.Proposte = response.data.result;
                            self.totalRows = self.Proposte.length;
                            self.currentPage = 1;
                            self.isLoading=false;

                            Array.from(self.Proposte).forEach(item => {                               
                                self.ClientiProposte.push(item.codcli);
                            });
                          
                        }).catch(e=>{
                            self.errors.splice(0);
                            self.errors.push(e)
                            this.isLoading=false;
                            
                        });
            },
            ExecRicerca(){
                this.$store.commit('SetFiltri', {
                    selAgente:this.selAgente, 
                    selStato:this.selStato
                })
 
                this.onChangeFiltriProposte(this.IdCampagna);
            },            
            ResetListAgenti(){
                //console.log("ResetListAgenti");
                this.onChangeFiltriProposte(this.IdCampagna)
            },
            ResetSelectListAreaM(){
                //console.log("ResetSelectListAreaM");
                this.onChangeFiltriProposte(this.IdCampagna)
            },

            onPreviewClick(value, index, item) {
            // value == value of checkbox (ie. true or false, or whatever is stored in v-model)
            // index == visual index of row (i.e. the index of the row wrt the displayed rows)
            // item == the clicked row item data
            console.log("row-clicked [" + value + "] , [" + index + "] , [" + item);
            },
            GetMasterData() {
                let self = this;
                self.isLoading = true;
                axios.all([                  
                    axios.get('api/v1/ContrattiStato'),
                    axios.get('api/v1/ContrattiStato/PerTipoUtente'),
                    axios.get('api/v1/CampagneWeb/Attuali'),
                ])
                    .then(axios.spread((...responses) => {      
                        if (responses[2].data.result.length == 0) {
                            self.errors.push({id:1, message: "Non ci sono Campagne disponibili"});
                            self.isLoading = false;
                            return;
                        }
                        self.elStati = responses[0].data.result;
                        self.elStatiModifica = responses[1].data.result;                        
                        self.CampagneAttuali = responses[2].data.result;
                        self.IdCampagna = self.CampagneAttuali[0].id;
                        self.isLoading = false;
                        self.ExecRicerca();
                })).catch(e => {
                    self.errors.push(e)
                });

            },

            //ReadContrattiStato() {
            //    let self=this;
            //    self.isLoading=true;
            //    var Link = 'api/v1/ContrattiStato';
               
            //    axios.get(Link)
            //        .then(response => {
            //            self.elStati = response.data.result;                            
            //        }).catch(e => {
            //            self.errors.splice(0);
            //            self.errors.push(e)
            //        });                
            //},
            //ReadContrattiStatoUtente() {
            //    let self=this;
            //    self.isLoading=true;                
            //    var Link = 'api/v1/ContrattiStato/PerTipoUtente';
               
            //    axios.get(Link)
            //        .then(response => {                        
            //            self.elStatiModifica = response.data.result;                                                    
            //        }).catch(e => {
            //            self.errors.splice(0);
            //            self.errors.push(e)
            //        });                
            //},
            //ReadCampagne() {
            //    let self=this;
            //    self.isLoading=true;
            //    var Link = 'api/v1/CampagneWeb/Attuali';
               
            //    axios.get(Link)
            //        .then(response => {
            //            self.CampagneAttuali = response.data.result;
            //            //if(self.CampagneAttuali.length == 1){
            //                self.IdCampagna = self.CampagneAttuali[0].id
            //            //}

            //        }).catch(e => {
            //            self.errors.splice(0);
            //            self.errors.push(e)
            //        });                                 
            //},

            doSelectAll(){
            
                let self=this;  
                
                self.isSelectedAll= !self.isSelectedAll;                

                self.isLoading=true;
                self.$nextTick(() => {
                    self.Proposte.forEach(x => { 
                        x.preview = self.isSelectedAll
                    });
                    self.isLoading=false;
                });         
            },

            /*   ATTENZIONE PER IL MOMENTO NON IMPLEMENTATA
           doExpandAll(){            
                let self=this;  
                
                self.isExpandedAll= !self.isExpandedAll;  
                console.log('self.isExpandedAll =' + self.isExpandedAll);  
                self.isLoading=true;

                var interval = 600;
                self.Proposte.forEach(function (el, index) {
                    setTimeout(function () {

                        console.log(el.descli);
                        self.$nextTick(() => {
                            el._showDetails = self.isExpandedAll
                        });

                    }, index * interval);
                });
                self.isLoading=false;    
            },*/
            doStato(NewStato){
                let self=this;  
                self.isLoading=true;

                // memorizza i codici dei clienti per poi cambiare lo stato
                self.ChangedStatus = (self.Proposte.filter(function(proposta){                    
                    if (proposta.preview) return proposta.codcli;
                })).map(function(filtrati){                   
                    if (filtrati.preview) return filtrati.codcli;
                });  

                var Link = 'api/v1/Contratti/AggiornaStato';

                var tParametri ={                    
                    IdCampagna: this.IdCampagna,
                    FlagStato: NewStato,
                    CODCLI : self.ChangedStatus
                } 

                axios.post(Link,tParametri)
                        .then(resp=>{
                            if(resp.data.result)
                            {  
                                self.successes.splice(0);
                                self.successes.push({id:1, message: "Stato Aggiornato"});  
                            }  
                            else
                            {             
                                self.errors.splice(0);
                                self.errors.push("Classe non salvata")   
                            }

                        })
                        .catch(e => {
                            if (e.response) {
                                self.errors.splice(0);
                                self.errors.push(e.message) 

                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                    //console.log("Errore in catch 1:" + e.response.data.detail);
                                    self.errors.splice(0);
                                    self.errors.push({ message:  e.response.data.detail});
                                }
                                else {
                                    //console.log("Errore in catch 2:" + e.message);
                                    self.errors.splice(0);
                                    self.errors.push(e)
                                }
                            }
                            else {
                                //console.log("Errore in catch 3:" + e.message);
                                self.errors.splice(0);
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });

                this.onChangeFiltriProposte(this.IdCampagna);

                self.isLoading=false;   

                self.ChangedStatus = null;
                self.isSelectedAll = true;
                this.doSelectAll(); 

            },
            /*
            Flaggato(Proposta){
                console.log("Flaggato ");
                if (Proposta.preview) return Proposta.codcli;
            }*/

        },
        mounted(){
          if(!this.$store.state.LoggedIn){
            console.log('not logged in, return to homepage')
            this.$router.push('/');
            }        
            
            //let self=this;            

            this.GetMasterData();

            //self.ReadContrattiStato();
            //self.ReadContrattiStatoUtente();
            //self.ReadCampagne();
            //self.ExecRicerca();
            
            
        }
    }
</script>