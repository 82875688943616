<template id="Login">
    <div>

        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <b-container class="bv-example-row mt-5">
               
            <b-row align-h="center" class="mb-4">
                <img    src="./assets/bag-discount.png" alt="bag-discount" />
            </b-row>
            
            <div v-if="isLoadingGen" id="div-spinner" class="text-center py-4 mt-3">
                <b-spinner variant="primary" style="width: 4rem; height: 4rem;"></b-spinner>
            </div> 
            <b-row v-else align-h="center">
               
                <b-col cols="6">
                    <!-- Card -->
                    <b-card title="Calcolo Premi"
                            body-class="text-center"
                            header-tag="nav"
                            style="max-width: 30rem;"
                            class="mb-2 ml-4">

                        <b-input-group class="mt-3">
                            <template v-slot:prepend>
                                <font-awesome-icon size="2x" icon="user" />

                            </template>
                            <b-form-input id="fgi_username"
                                          v-model="Username"
                                          placeholder="Username"
                                          type="text"
                                          class="ml-3">
                            </b-form-input>
                        </b-input-group>

                        <b-form-group>
                            <b-input-group class="mt-3">
                                <template v-slot:prepend>
                                    <font-awesome-icon size="2x" icon="lock" />

                                </template>
                                <b-form-input id="fgi_Password"
                                              v-model="Password"
                                              placeholder="Password"
                                              type="password"
                                              v-on:keyup.enter="Login"
                                            class="ml-3">
                                </b-form-input>
                            </b-input-group>
                            
                        </b-form-group>
                        <b-card-text class="text-danger">{{LoginErrorResponse}}</b-card-text>                        
                        <div class="text-center py-4 mt-3">
                            <b-button variant="success" @click="Login" :disabled="isLoading">LOGIN</b-button>
                        </div>
                        <div v-if="isLoading" id="div-spinner" class="text-center py-4 mt-3">
                            <b-spinner variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
                        </div> 
                    </b-card>
                    <!-- Card -->
                </b-col>
            </b-row>
        </b-container>
        
    </div>        

</template>


<script>        
    import axios from 'axios';

    export default {        
        data() {
            return {
                errors: [],  
                Username: '',
                Password: '',
                LoginErrorResponse: '',
                isLoading:false,
                isLoadingGen: false //serve per il loading generale
            };
        },
        components: {            
        },
        watch: {            
          
        },
        methods: {
            Login() {
                let self = this;
                self.isLoading = true;
                
                this.$store.commit('SetLoggedIn', false);      
                self.errors = [];
                var Link = 'api/v1/Account/login';
                let data = {
                    username: this.Username,
                    password: this.Password
                }
                axios.post(Link, data)
                    .then(response => {       
                        self.errors = [];
                        self.LoginErrorResponse = '';
                        //this.$store.commit('SetToken', response.data.result.token);
                        this.$store.commit('SetUsername', response.data.result.name);
                        this.$store.commit('GetInitialData');

                        this.$store.commit('SetswiAMM',response.data.result.swiAmm);
                        this.$store.commit('SetswiRV',response.data.result.swiRv);
                        this.$store.commit('SetswiAreaM',response.data.result.swiAream); 
                        this.$store.commit('SetswiAGE',response.data.result.swiAge);
                        
                        setTimeout(() => {             
                            this.$store.commit('SetLoggedIn', true);
                            self.isLoading = false;
                        }, 2000);
                        self.errors = this.$store.state.errors;
                        
                    })
                    .catch(e => {  
                        if (e.response) {
                            if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                self.LoginErrorResponse = e.response.data.title;
                            }
                            else {
                                this.errors.push(e)
                            }
                            this.$store.commit('SetLoggedIn', false);
                            self.isLoading = false;
                        }
                    })

            }
    
     
        },
        // mounted() {
        //     let self=this;

        //     console.log('comp login mounted');
        //     var tCookie= self.$cookies.get('AYCPToken');
            
        //     console.log('mounted, cookie:',tCookie);
        //     if(tCookie){    
        //         self.isLoadingGen = true;
        //         //provo a chiamare una API per vedere se il cookie è ancora valido
        //         axios.get('api/v1/Account/login')
        //             .then(response => {
        //                 console.log(response.length)
        //                 self.$store.commit('SetToken', tCookie);
        //                 self.$store.commit('SetUsername', response.data.result.name);
        //                 self.$store.commit('GetInitialData');
        //                 setTimeout(() => {             
        //                     self.$store.commit('SetLoggedIn', true);
        //                     self.isLoadingGen = false;
        //                 }, 2000);
        //                 self.errors = self.$store.state.errors;
        //             }).catch(e => {
        //                 console.log(e);
        //                 self.isLoadingGen = false;
        //             });
                
        //     }
        // },
        updated() {            
        }

        
    }


</script>