<template id="AnaClassi">
<div>
            <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <h2 class="text-center mt-3 mb-2">
            Elenco Classi
            <b-button title="Aggiungi Classe" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="NuovaClasse()" v-b-modal.modal-edit><font-awesome-icon size="sm" icon="plus" /></b-button>
        </h2>
       
        <!--Spinner-->
        <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div>
        <div v-else>
            <div class="container">

                <!-- <div class="col-md-12  offset-md-1">
                    <label class="col-form-label font-weight-bold " for="inpute_search">Cerca</label>
                    <input id="inpute_search" class="col-md-6 search-query st-default-search-input aa-input" aria-label="Search" type="text" v-model="search" placeholder="Search..." />
                </div> -->
                <!-- <div class="row">
                    <b-button variant="outline-primary" class="col-8 offset-2" v-on:click="NuovaClasse()" v-b-modal.modal-edit >Nuova</b-button>
                </div> -->
                <div class="row">
                    <b-table :items="this.$store.state.Classi" :fields="fields" no-footer-sorting sort-icon-left responsive class="table col-sm-8 offset-sm-2">
                            <!-- A virtual composite column -->
                            <template v-slot:cell(abilitata)="data">
                                <b-form-checkbox :id="'checkbox'+data.index"
                                                v-model="data.item.abilitata"
                                                :name="'checkbox-'+data.item.codclasse"
                                                value="true"
                                                unchecked-value="false"
                                                @change="UpdateRec(data.item)">
                                </b-form-checkbox>
                            </template>
                            <template v-slot:cell(btn)="data"> 
                                <font-awesome-icon size="lg" icon="edit"  v-on:click="OpenModal(data.item)" v-b-modal.modal-edit style="color:darkorange" />
                                <font-awesome-icon v-on:click="Delete(data.item)" class="icon" size="lg" icon="times"  /> 
                            </template>
                        </b-table>
                        <!--
                    <table v-if="!isLoading" class="table col-sm-8 offset-sm-2">
                        <thead>
                            <tr>
                                <th>Codice</th>
                                <th>Descrizione</th>
                                <th>Abilitata</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                         <template v-for="classe in this.$store.state.Classi">

                            <tr :key="classe.codclasse">
                                <td>{{classe.codclasse}}</td>
                                <td>{{classe.desclasse}}</td>
                                <td> 
                                    <b-form-checkbox
                                            :id="'checkbox-'+classe.codclasse"
                                            v-model="classe.abilitata"
                                            :name="'checkbox-'+classe.codclasse"
                                            value="true"
                                            unchecked-value="false" 
                                            disabled
                                            >    </b-form-checkbox> 
                                </td>
                                <td> <a href="/#" v-on:click="OpenModal(classe)" v-b-modal.modal-edit><font-awesome-icon size="lg" icon="edit" style="color:dodgerblue" /></a></td>
                                <td> <a href="/#" v-on:click="Delete(classe)" ><font-awesome-icon size="lg" icon="times" style="color:red" /></a></td>
                            </tr>
                        </template> 

                    </table>-->
                </div>
            </div>
            <b-modal id="modal-edit" title="Classe" ref="modal"  
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Codice"  :state="State" label-for="Codice-input" invalid-feedback="Codice is required" >
                        <b-form-input id="Codice-input" v-model="currentClasse.codclasse" :state="State" maxlength="10" required :disabled="!isNew"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Nome"  :state="State" label-for="name-input" invalid-feedback="Nome is required" >
                        <b-form-input id="name-input" v-model="currentClasse.desclasse" :state="State" required></b-form-input>
                    </b-form-group>
                    <b-form-group  label="" label-for="swiabi_chkbox"  >
                        <b-form-checkbox  id="swiabi_chkbox" v-model="currentClasse.abilitata">Abilitata</b-form-checkbox>
                    </b-form-group> 
                    <b-form-group  label="Vibilità:"  >

                            <b-form-checkbox  id="switipcli_chkbox" v-model="currentClasse.switipcli" inline>Clienti</b-form-checkbox>
                            <b-form-checkbox  id="switipage_chkbox" v-model="currentClasse.switipage" inline>Agenti</b-form-checkbox>
                            <b-form-checkbox  id="switipfor_chkbox" v-model="currentClasse.switipfor" inline>Fornitori</b-form-checkbox>
                            <b-form-checkbox  id="switipam_chkbox" v-model="currentClasse.switipam" inline>Area Manager</b-form-checkbox>

                    </b-form-group>
                    
                    <b-form-group label="Report Default"   label-for="report-input"  >
                        <b-form-input id="report-input" v-model="currentClasse.reportdefault"></b-form-input>
                    </b-form-group>
                </form>
            </b-modal>
        </div>
</div>
</template>

<style>

    .search-query {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        padding: 0 15px 0 30px;
        border: 1px solid #e3e3e3;
        color: #273849;
        outline: none;
        border-radius: 15px;
        margin-right: 10px;
        transition: border-color 0.2s ease;
        /*background: #fff url("search.png") 8px 5px no-repeat; background-size: auto;*/
        background-size: 20px;
        vertical-align: middle !important;
    }

</style>

<script>    
    //require("@/assets/search.png")
    import axios from 'axios';
    //import EditAna from './EditAna.vue';

    export default {

        data() {
            return {
                errors: [],  
                isLoading: true,
                SelectedEntity: Object,
                classdef: Object,
                KeyPropDef: Object,
               // Classi: [],
                search: '',
                awaitingSearch: false,
                // SelectedRowId: {
                //     type: String,
                //     default: ''
                // },
                SwiEdit: false,
                currentClasse:{},
                showModal:false,
                State:null,
                isNew: false,
                fields: [                    
                    {
                        key: 'codclasse',
                        label: 'Codice',
                        sortable: true
                    },
                    {
                        key: 'desclasse',
                        label: 'Descrizione',
                        sortable: true                       
                    },
                    {
                        key: 'abilitata',
                        sortable: true
                    },
                    {
                        key: 'btn',
                        sortable: false
                    }],
            };
        },
        components: {
         //   EditAna
        },
        watch: {            
             
            // search: function () {
            //     //debounce...
            //     if (!this.awaitingSearch) {
            //         setTimeout(() => {                        
            //             if (!this.isLoading) {
            //                 this.GetData();
            //             }
            //             this.awaitingSearch = false;
            //         }, 700); // 0,5 sec delay
                
            //     }
            
                
            //     this.awaitingSearch = true;
            // },
            // $route(to, from) {
            //     console.log('Route changed from ' + from.path + ' to ' + to.path);
            // }


        },

        methods: {
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.State = valid
                //if(!valid){console.log("form non valida :" +valid)}
                return valid
            },
            resetModal() {
                this.currentClasse = {}
                this.State = null
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                let self=this;
                // Exit when the form isn't valid
                if (!self.checkFormValidity()) {
                return
                }
                self.SaveRec();
            },
            SaveRec() {
                let self=this; 
                if(self.isNew)
                { 
                    var tnuova={
                                    codclasse: self.currentClasse.codclasse,
                                    desclasse: self.currentClasse.desclasse,
                                    abilitata: self.currentClasse.abilitata,
                                    switipcli:self.currentClasse.switipcli,
                                    switipage:self.currentClasse.switipage,
                                    switipfor:self.currentClasse.switipfor,
                                    switipam:self.currentClasse.switipa,
                                    reportdefault:self.currentClasse.reportdefault
                               };
                    //console.log("post Classi: " + self.currentClasse.nomeClassi);
                    axios.post('api/v1/Classi/',self.currentClasse)
                        .then(resp => {
                           if(resp.data.result)
                           { 
                               
                    //console.log("post Classi dopo response: " + tnuova.nomeClassi);
                                tnuova.codclasse= resp.data.result;
                                this.$store.state.Classi.push(tnuova); 
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.isNew=false;
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")
                                })
                           }
                           else
                           {
                               alert("Classe non salvata");
                           }

                      })
                      .catch(e => {
                        if (e.response) {
                            //console.log("e.response");
                            if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                //console.log("Errore in catch 1:" + e.response.data.detail);
                                self.errors.push({ message:  e.response.data.detail});
                            }
                            else {
                                //console.log("Errore in catch 2:" + e.message);
                                self.errors.push(e)
                            }
                        }
                        else {
                            //console.log("Errore in catch 3:" + e.message);
                            self.errors.push(e)
                        }
                        self.isLoading = false;
                    });

               }
               else
               {
                   var tEdit={
                                    codclasse: self.currentClasse.codclasse,
                                    desclasse: self.currentClasse.desclasse,
                                    abilitata: self.currentClasse.abilitata,
                                    switipcli: self.currentClasse.switipcli,
                                    switipage: self.currentClasse.switipage,
                                    switipfor: self.currentClasse.switipfor,
                                    switipam: self.currentClasse.switipa,
                                    reportdefault:self.currentClasse.reportdefault
                               };
                    axios.put('api/v1/Classi/'+self.currentClasse.codclasse,self.currentClasse)
                        .then(resp => {
                           if(resp.data.result)
                           { 
                                var tClasse = this.$store.state.Classi.find(s => s.codclasse === tEdit.codclasse);
                
                                if(tClasse)
                                {
                                    tClasse.abilitata= tEdit.abilitata;
                                    tClasse.desclasse= tEdit.desclasse;
                                    tClasse.switipcli= tEdit.switipcli,
                                    tClasse.switipage= tEdit.switipage,
                                    tClasse.switipfor= tEdit.switipfor,
                                    tClasse.switipam= tEdit.switipa,
                                    tClasse.reportdefault=tEdit.reportdefault
                                }
                                 
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")
                                })
                           }
                           else
                           {
                               alert("Classi non salvata");
                           }
                      })
                      .catch(e => {
                        if (e.response) {
                            //console.log("e.response");
                            if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                //console.log("Errore in catch 1:" + e.response.data.detail);
                                self.errors.push({ message:  e.response.data.detail});
                            }
                            else {
                                //console.log("Errore in catch 2:" + e.message);
                                self.errors.push(e)
                            }
                        }
                        else {
                            //console.log("Errore in catch 3:" + e.message);
                            self.errors.push(e)
                        }
                        self.isLoading = false;
                    });
               }
            },
            UpdateRec(classe) {
                 this.currentClasse={
                    codclasse: classe.codclasse,
                    desclasse: classe.desclasse,
                    abilitata: classe.abilitata,
                    switipcli: classe.switipcli,
                    switipage: classe.switipage,
                    switipfor: classe.switipfor,
                    switipam: classe.switipa,
                    reportdefault: classe.reportdefault
                };       
                this.SaveRec();
            },
            OpenModal(classe){
                console.log('classe-->',classe)
                this.currentClasse={
                    codclasse: classe.codclasse,
                    desclasse: classe.desclasse,
                    abilitata: classe.abilitata,
                    switipcli: classe.switipcli,
                    switipage: classe.switipage,
                    switipfor: classe.switipfor,
                    switipam: classe.switipa,
                    reportdefault: classe.reportdefault
                };
                //this.showModal=true;
                //this.$bvModal.show('modal-edit');
            },
            NuovaClasse(){
                 this.currentClasse={
                    codclasse: '',
                    desclasse: '',
                    abilitata: false,
                    switipcli: false,
                    switipage: false,
                    switipfor: false,
                    switipam: false,
                    reportdefault:''
                };
                this.isNew=true;
            },
            Delete(classe){
                let self=this;

                if (confirm("Cancellare la Classe  "+classe.desclasse+"?"))
                {
                    axios.delete('api/v1/Classi/'+ classe.codclasse)
                        .then(resp => {
                            if(!resp.data.result)
                            { 
                                //console.log("Errore in response:" + resp.data.errors.message);
                                self.errors.push({ message: resp.data.errors.message});
                            }
                            else
                            {
                                self.$store.state.Classi = self.$store.state.Classi.filter(function (item) {
                                                    return item.codclasse !== classe.codclasse
                                                });
                            }
                        }).catch(e => {
                            if (e.response) {
                                 //console.log("e.response");
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                    //console.log("Errore in catch 1:" + e.response.data.detail);
                                    self.errors.push({ message:  e.response.data.detail});
                                }
                                else {
                                    //console.log("Errore in catch 2:" + e.message);
                                    self.errors.push(e)
                                }
                            }
                            else {
                                //console.log("Errore in catch 3:" + e.message);
                                self.errors.push(e)
                            }
                    });
                }
            }
        },
        mounted() {
            //console.log('mounted');
            if(!this.$store.state.LoggedIn){
                this.$router.push('/');
            }
            //this.Classi = this.$store.state.Classi;
            this.isLoading = false;
        },
        updated() {
            ////console.log(this.$route)
        }

        
    }


</script>