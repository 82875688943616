<template>
    <div class="row my-2">
        
        <b-form-checkbox class="col-1 offset-2 text-right m-2" v-model="value.selected"/>
         
        <b-form-input class="col-1" readonly v-model="value.oldCod" type="text" />
        <b-form-input class="col-3" readonly v-model="value.oldDes" type="text" />
            
        <font-awesome-icon icon="angle-double-right"  class="mx-3 text-warning mt-2" />
            
        <b-form-input class="col-1" :readonly="!value.selected" v-model="value.codcon" type="text" debounce="500" />
        <b-form-input class="col-3" :readonly="!value.selected" v-model="value.descon" type="text" debounce="500" />

        <font-awesome-icon  v-if="value.error" icon="exclamation"  class="col-1 text-danger mt-2" />
        <p v-if="value.textError" class="col-1">{{value.textError}}</p>
        <font-awesome-icon v-if="value.success" icon="check-circle"  class="col-1 text-success mt-2" />
        <div v-if="value.isLoading" id="div-spinner" class="col-1 text-center mt-2">
            <b-spinner variant="primary" small></b-spinner>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                    this.$emit('input',this.value);
            }
        },
        'value.selected':function(){
            this.$emit('input',this.value);
        }
     },
    methods:{
        onchange(){
            this.$emit('input',this.value);
        }
    }
}
</script>