<template>
    <div class ="container-fluid px-5 pb-5">
       
        <div class="row mt-2"> 
            <h4 class="col-6 col-md-3 offset-md-2"  >Duplica Serie</h4>
        </div>
        <div class="row mt-2"> 
            <b-input-group class="col-6 col-md-3 offset-md-2"  >
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Serie Sorgente</b-button>
                </b-input-group-prepend>

                <b-form-select
                        v-model="selSerieSorg"
                        :options="Series"
                        class=""
                        value-field="id"
                        text-field="nomeserie"
                        disabled-field="notEnabled"
                        ></b-form-select>
            </b-input-group> 
        </div>
        <div class="row mt-2"> 
            <b-input-group class="col-6 col-md-3 offset-md-2"  >
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Serie Destinaz.</b-button>
                </b-input-group-prepend>

            <!-- in data 16-6-21 cambio le options associate perchè l'utente ha voluto vedere tutte le serie anche in destinazione -->
                <b-form-select
                        v-model="selSerieDest"
                        :options="Series" 
                        class=""
                        value-field="id"
                        text-field="nomeserie"
                        disabled-field="notEnabled"
                        ></b-form-select>
            </b-input-group>
        </div>
        <div class="row mt-2">  
            <div class="col-8 col-md-4 offset-md-2">
                <b-form-checkbox class="col-12" id="swiaddanno_chkbox" v-model="swiAggiungiAnno">Aggiungi anno</b-form-checkbox>
            </div>  
        </div>
        <!-- <b-table hover small head-variant="dark" sort-icon-left :items="tipiOb" :fields="fields" primary-key="id">
        </b-table> -->
        <div class="container-fluid mt-4" v-if="selSerieSorg">
            <TipoObiettivoDuplica  v-for="tipo in tipiOb" :key="tipo.id" v-model="tipo.value" >
                <!-- <TTipoObiettivoDuplicaipoObiettivoDuplica v-model="tipo" :key="tipo.id"></TipoObiettivoDuplica> -->
            </TipoObiettivoDuplica>
        </div>
        <div class="row mt-5">  
             <div class="col-8 col-md-4 offset-4 offset-md-2">
                <b-button variant="success" v-on:click="Duplica" :disabled="!selSerieSorg || !selSerieDest" > Duplica! </b-button>
                &nbsp;
                <b-button variant="outline-warning" href="#/"> Annulla </b-button>
             </div>
        </div>

        <b-alert v-if="successMessage" variant="success" show dismissible>
            <ul>
                <li >
                    {{successMessage}}
                </li>
            </ul>
        </b-alert>
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
    </div>
</template>
<script>
import axios from 'axios';

import TipoObiettivoDuplica from  './components/TipoObiettivoDuplica.vue';
export default {
    components:{TipoObiettivoDuplica},
    data() {
        return {
            errors:[],
            successMessage:'',
            swiAggiungiAnno:true,
            Series:this.$store.state.Series,
            tipiOb:[] ,
            selSerieSorg:0,
            selSerieDest:0,
            Esito:[],            
            totalRows: 0,

            fields:[
                    {key:'selected',label:'S'},
                    {
                        key: 'oldCon',
                        sortable: true, 
                        label:'Codice Orig. Tipo Ob.'
                    },
                    {
                        key: 'oldDes',
                        sortable: true,
                        label:'Orig. Tipo Obiettivo'
                    },
                    {
                        key: 'codcon',
                        sortable: true, 
                        label:'Codice Tipo Ob.'
                    },
                    {
                        key: 'descon',
                        sortable: true,
                        label:'Tipo Obiettivo'
                    },
            ]
        }
    },
     watch: {
        selSerieSorg:function(newval){
            let self=this;
            self.tipiOb=[];
            self.$store.state.TipiOb.forEach(t=>{ 
                    if(t.rifserie== newval){
                        var newObj= {
                            selected:false,
                            oldCod:t.codcon,
                            oldDes:t.descon,
                            codcon:t.codcon+'_1',
                            descon:t.descon+'_1',
                            isLoading:false,
                            success:false,
                            error:false
                        }

                        self.tipiOb.push({
                                id: t.id,
                                value: newObj
                                });
                    }
                }); 
        },

     },
    computed:{
        SeriesNotSelected : function(){
            return this.$store.state.Series.filter(s=> s.id!= this.selSerieSorg);
        }
    },
    methods :{
        Duplica(){
            let self=this;
            self.errors=[];
            self.successMessage='';
            
            var tError=false;
 
            let axiosArray = []
                this.tipiOb.filter(t=>t.value.selected).forEach(
                    tip=>{

                        let TipoDuplicazione={
                            // id:tip.id,
                            IDTIPOBORI: tip.id,
                            CODNEWTIPOB: tip.value.codcon,
                            DESNEWTIPOB: tip.value.descon,
                            RIFSERIENEW: self.selSerieDest,
                            ADDANNO: self.swiAggiungiAnno
                        }

                        axiosArray.push(TipoDuplicazione)
                    }
                );

                var Link = 'api/v1/TipiObiettivi/Duplica'  ;
                //console.log(TipoDuplicazione);
                axios.post(Link, axiosArray)//, state.Headers)
                        .then(response => {

                                self.Esito = Object.keys(response.data.result).map((key) => [String(key), response.data.result[key]]);

                                self.totalRows = self.Esito.length;

                                self.Esito.forEach((item) => {
 
                                    if ( item[1] ==  -1){
                                        self.errors.push({message:"Record with Code: " + item[0] + " already exists"});
                                        tError = true;
                                    }
                                    else{  
                                        self.errors.push({message:"Record with id: " + item[0] + " not created."});
                                        tError = true;
                                    }
                                } )

                            self.FineDuplica(tError)
                        }).catch(e => {
                            console.log('eccezione:',e);
                            
                            tError = true;
                            self.errors.push({message:e.response.data.title});
                            self.FineDuplica(tError)
                            });
                  
            },

        FineDuplica(errore){
            let self=this;
             //se alla fine del ciclo è tutto ok, senza errori, cancello la lista:
            if(!errore){ 
                self.selSerieSorg=0,
                self.selSerieDest=0,
                self.successMessage='Duplicazione avvenuta correttamente';
            }
            // else{
            //     self.errors.push({message:'Si sono verificati degli errori, verificare le singole righe'});
            // }
            
        },
        GetSoloAbilitati(){
            let self=this;
            self.tipiOb=[];
            if(!self.soloAbilitate){
                 this.$store.state.TipiOb.forEach(el => {
                        self.tipiOb.push(el);
                    });
            }
            else{
                //console.log("GetSoloAbilitati true");
                var series = self.$store.state.Series.filter(s=> s.abilitata== self.soloAbilitate); 
                //console.log("GetSoloAbilitati serie "+ series.length);

                self.$store.state.TipiOb.forEach(t=>{ 
                    series.forEach (s=> {
                            if(s.id==t.rifserie){
                               self.tipiOb.push(t);
                           }
                    });
                }); 
               // console.log("GetSoloAbilitati tipi serie abilitati "+ self.tipiOb.length);
            }
        }
    },
    beforeMount(){      
        //this.GetSoloAbilitati();
    },
    mounted(){
        if(!this.$store.state.LoggedIn){
            this.$router.push('/');
        }
    }
}
</script>