<template>
    <div>
<!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        <h3 class="text-center my-2">Generazione Lettere</h3>
        <b-tabs content-class="mt-3" align="center" class="mt-3">
             <b-tab title="Selezione Intestatari" active>
                <Filtri :MostraIncludiInviati="true" v-on:change="onChangeFiltri"/>

                <div class="row px-5 mt-3 mb-2">
                    <b-button class="col-1" variant="link" @click="SelectAll">{{(isSelectedAll)?'Deseleziona': 'Seleziona'}} Tutti</b-button>
                    
                    <b-button class="col-1" variant="primary" @click="Invia">Genera Report</b-button>
                    <!-- <b-button class="col-1" variant="link" @click="InvertiSelezione">Inverti Selezione</b-button> -->
                    <b-col sm="2" class="my-1">
                        <b-form-group
                        label="Per pagina"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0" 
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="2" class="my-0 ">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        class="my-0"
                        ></b-pagination>
                    </b-col>
                    <b-col offset="1" sm="4" md="2" align-self="center">
                        <label for="" class="col-form-label col-form-label-sm "> Righe Estratte {{ElencoInt.length?ElencoInt.length:0}}</label>
                    </b-col>
                </div>
                <div class="px-5">
                    <b-table hover 
                                small
                                head-variant="dark" 
                                sort-icon-left 
                                :items="ElencoInt" 
                                :fields="fields"                         
                                :busy="isLoading"
                                :current-page="currentPage"
                                :per-page="perPage">
                            <template v-slot:table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                            <!-- <template v-slot:head(sel)="data">
                                <b-form-checkbox size="lg"  v-model="isSelectedAll" @change="SelectAll(data)" />
                            </template>  -->
                            <template v-slot:cell(sel)="data">
                                <b-form-group>
                                    <b-form-checkbox  v-model="data.item.sel" />
                                </b-form-group>
                            </template>
                            <template v-slot:cell(des)="data">
                                <div class="row">
                                    {{data.item.des}} 
                                    <div class="col-4 text-small text-secondary">{{ '   ('+ data.item.mail+') '}}</div>
                                </div>
                            </template>
                            <template v-slot:cell(info)="data">
                                <font-awesome-icon :id="'icon-d-'+data.item.cod" v-if="data.item.numinv > 0" size="lg" icon="check-circle" @click="ViewDettInviati()" style="color:orange" class="mt-2" >
                                </font-awesome-icon>
                                    <b-tooltip :target="'icon-d-'+data.item.cod" placement="left" triggers="hover">
                                        <b>Contratti Già inviati {{data.item.numinv}}</b>
                                    </b-tooltip>
                                <font-awesome-icon :id="'icon-e-'+data.item.cod" v-if="data.item.numerr > 0" size="lg" icon="stop" @click="ViewErrori()" style="color:red" class="mt-2">
                                    <b-tooltip :target="'icon-e-'+data.item.cod" placement="right" triggers="hover">
                                        <b>Errori Invio</b>
                                    </b-tooltip>
                                </font-awesome-icon>
                            </template>
                    </b-table>
                </div>
            </b-tab>
            <!-- <div class="container-fluid px-5 my-2 ">
                <b-button :title="(ShowInfoLettera?'Nascondi':'Mostra')+'Info Lettera'" pill variant="outline-success" @click="ShowInfoLettera=!ShowInfoLettera">
                    <font-awesome-icon v-if="!ShowInfoLettera" size="sm" icon="angle-down" />   
                    <template v-if="!ShowInfoLettera"> Info Lettera </template>
                    <font-awesome-icon v-else size="sm" icon="angle-up" />
                </b-button>
            </div> -->
            <b-tab title="Info Lettera">
                <div  class="container">
                    <div class="row">
                        <b-input-group class="col-6 col-md-3">
                            <b-input-group-prepend>
                                <b-button variant="outline-primary" disabled>Mese</b-button>
                            </b-input-group-prepend>

                            <b-form-select
                                    v-model="selMese"
                                    :options="Mesi"
                                    class=""
                                    value-field="cod"
                                    text-field="des"
                                    ></b-form-select>
                        </b-input-group>
                        <b-input-group class="col-8 col-md-2">
                            <b-input-group-prepend>
                                <b-button variant="outline-primary" disabled>Anno</b-button>
                            </b-input-group-prepend>

                            <b-form-input type="number" min="2020" v-model="selAnno"></b-form-input>
                        </b-input-group>

                        <b-input-group class="col-8 col-md-3 offset-md-4">
                            <b-input-group-prepend>
                                <b-button variant="outline-primary" disabled>Report</b-button>
                            </b-input-group-prepend>

                            <b-form-select
                                    v-model="selReportModel"
                                    :options="ReportModels"
                                    class=""
                                    value-field="des"
                                    text-field="des"
                                    ></b-form-select>
                        </b-input-group>

                    </div>
                    
                    <div class="row mt-3">
                        <b-input-group class="col-4 col-md-2">
                            
                                <b-button variant="outline-primary" disabled>Suddivi Files per:</b-button>
                        </b-input-group>
                        <div class="col-1 mt-2">
                            <b-form-checkbox switch class="mr-n2" v-model="swiDividiAgenti">
                                <span class=""> Agenti</span>
                            </b-form-checkbox> 
                        </div>
                        <div class="col-1 mt-2 ml-1">
                            <b-form-checkbox switch class="mr-n2" v-model="swiDividiClienti">
                                <span class=""> Clienti</span>
                            </b-form-checkbox> 
                        </div>
                    </div>

                    <div class="mt-3">
                        <b-form-checkbox switch class="col-12 mr-n2" v-model="swiNotaAnnullamento">
                            <span class=""> Inserisci nota di annullamento precedente</span>
                        </b-form-checkbox> 
                        <b-form-input type="text" v-model="txtNotaAnnullamento" :disabled="!swiNotaAnnullamento"></b-form-input>
                    </div>
                    <div class="mt-3">
                        <span class=""> Aggiungi Nota</span>
                        <b-form-input type="text" v-model="txtNota" ></b-form-input>
                    </div>
                    <div class="mt-3">
                        <span class=""> Testo per Corpo Lettera</span>
                        
                        <b-form-textarea  v-model="txtCorpoReport" rows="6"></b-form-textarea>
                    </div>
                </div>
            </b-tab>
            <!-- <div class="container-fluid px-5 my-2 ">
                <b-button :title="(ShowDatiMail?'Nascondi':'Mostra')+'Info Lettera'" pill variant="outline-success" @click="ShowDatiMail=!ShowDatiMail">
                    <font-awesome-icon v-if="!ShowDatiMail" size="sm" icon="angle-down" />   
                    <template v-if="!ShowDatiMail"> Dati Mail </template>
                    <font-awesome-icon v-else size="sm" icon="angle-up" />
                </b-button>
            </div> -->
            <b-tab title="Dati Mail">
                <div  class="container">
                    <!-- <div> <h4>Dati Mail</h4> </div> -->
                    <div class="mt-3">
                        <span class=""> Oggetto Email:</span>
                        <b-form-input type="text" v-model="txtOggettoMail" ></b-form-input>
                    </div>
                    <div class="mt-3">
                        <span class=""> Testo per Corpo Email:</span>
                        <b-form-textarea  v-model="txtCorpoMail" rows="6"></b-form-textarea>
                    </div>
                    
                </div>
            </b-tab>
           
        </b-tabs>
    </div>
</template>

<script>
import axios from 'axios';
import Filtri from './components/Filtri.vue';

export default {
    name:"StampaLettere",
    components:{
        Filtri
    },
    data() {
        return {
            errors: [],  

            Mesi: [
                {cod:1,des:'Gennaio'},
                {cod:2,des:'Febbraio'},
                {cod:3,des:'Marzo'},
                {cod:4,des:'Aprile'},
                {cod:5,des:'Maggio'},
                {cod:6,des:'Giugno'},
                {cod:7,des:'Luglio'},
                {cod:8,des:'Agosto'},
                {cod:9,des:'Settembre'},
                {cod:10,des:'Ottobre'},
                {cod:11,des:'Novembre'},
                {cod:12,des:'Dicembre'},
            ],
            selMese: 1,  
            selAnno: new Date().getFullYear(),
            ReportModels: [],
            selReportModel:'',
            swiDividiAgenti:false,
            swiDividiClienti:false,
            swiNotaAnnullamento:false,
            txtNotaAnnullamento:'ANNULLA E SOSTITUISCE LA PRECEDENTE',
            txtNota:'',
            txtCorpoReport:'',
            ShowInfoLettera:false,
            ShowDatiMail:false,
            txtOggettoMail:'',
            txtCorpoMail:'',

            perPage:25,
            currentPage:1,
            totalRows:1,
            pageOptions: [25, 100, 500, { value: 0, text: "Tutti" }],

            ElencoInt:[],
            fields: [
                { key: 'sel', sortable: true,  label:'S'},
                { key: 'cod', sortable: true,  label:'Codice Int.'},
                { key: 'des', sortable: true,  label:'Des.Intestatario'},
                { key: 'desage',sortable: true,  label:'Agente'},
                { key: 'numcnt', sortable: true,  label:'N° Contratti'},
                { key: 'info', sortable: true,  label:'Info'},
                    
            ],
            isLoading:false,
            isSelectedAll:false,
            SelectedInt:[]
        };
    },
    watch:{
            '$store.state.TipoInt': function(){
                this.ElencoInt=[];
            }
        },
    methods:{
        InvertiSelezione(){
            let self=this;  
            //self.isSelectedAll= !self.isSelectedAll;
            self.ElencoInt.forEach(e => { 
                e.sel = !e.sel
            }); 
        },
        SelectAll(){
             let self=this;  
            self.isSelectedAll= !self.isSelectedAll;
            self.isLoading=true;
            self.$nextTick(() => {
                self.ElencoInt.forEach(cli => { 
                    cli.sel = self.isSelectedAll
                });
                self.isLoading=false;
            }); 
           
            if(!self.isSelectedAll){
                self.SelectedInt=[];
            }
            else{
                //console.log('SelectAll  --> else',self.SelectedInt);
                self.SelectedInt=[];
                self.ElencoInt.forEach(cli=> self.SelectedInt.push(cli.cod));
            }
        },
        onChangeFiltri(selClasse, selTipoOb, selSerie, txtRicercaCli, selAgente,SwiIncludiInviati){
                let self=this;
                self.isLoading=true;
                var Link = 'api/v1/Lettere/GetListInt?Tipo='+ self.$store.state.TipoInt+'&IncludiInviati='+SwiIncludiInviati ;
                if(txtRicercaCli){
                    Link += '&TxtCliente='+txtRicercaCli; 
                }
                if(selTipoOb){
                    Link += '&IdTipoObiettivo='+selTipoOb;
                }
                if(selClasse){
                    Link += '&Classe='+selClasse;
                }
                if(selSerie){
                    Link += '&Serie='+selSerie ;
                }
                if(selAgente){
                    Link += '&CodAgente='+selAgente ;
                }
                axios.get(Link)//, state.Headers)
                    .then(response => {
                        self.ElencoInt = response.data.result;
                        self.ElencoInt.forEach(e=> {e.sel=false;});
                        self.totalRows = self.ElencoInt.length;
                        self.currentPage = 1;
                        self.isLoading=false;
                    }).catch(e => {
                        self.errors.push(e)
                    });  

                // self.$store.commit('SetFiltri', {
                //     selClasse, selTipoOb, selSerie, txtRicercaCli, selAgente,SwiIncludiInviati
                // })
                
                var tClasse = self.$store.state.Classi.find(c=>c.codclasse==selClasse);
                if(tClasse){
                    self.selReportModel= tClasse.reportdefault
                }

                self.isSelectedAll=false;
                    //console.log("imposto report default : "+self.selReportModel);
                
            },
            Invia(){
                let self=this;

                if(self.$store.state.Filtri.selSerie>=888888)
                {
                    alert("selezionare una serie!")
                    return;
                }
                
                if(self.$store.state.Filtri.selClasse>=888888)
                {
                    alert("selezionare una classe!")
                    return;
                }
                var tElencoInt=[];
                self.ElencoInt.filter(i=> i.sel).forEach(t=> { tElencoInt.push(t.cod)});
                var tParametri={
                    "elencoInt": tElencoInt,
                    "serie": self.$store.state.Filtri.selSerie,
                    "classe": self.$store.state.Filtri.selClasse,
                    "mailSubject": self.txtOggettoMail,
                    "mailBody": self.txtCorpoMail,
                    "mese": self.selMese,
                    "anno": self.selAnno,
                    "reportModel": self.selReportModel,
                    "swiDividiAgenti": self.swiDividiAgenti,
                    "swiDividiClienti": self.swiDividiClienti,
                    "notaAnnullamento": self.swiNotaAnnullamento? self.txtNotaAnnullamento:'',
                    "notaAggiunta": self.txtNota,
                    "corpoReport": self.txtCorpoReport,
                    "IncludiInviati": self.$store.state.Filtri.SwiIncludiInviati
                };

                console.log("parametri : ", tParametri);
                self.isLoading=true;
                axios.post('api/v1/Lettere/AddMails',tParametri)
                    .then(response=>{
                        if(response.data.isError)
                        {
                            self.errors.push({message:response.data.message});
                        }
                        else
                        {
                            alert("Generazione report Accodata!");
                            self.ElencoInt = [];
                            self.totalRows = self.ElencoInt.length;
                            self.currentPage = 1;
                            self.isLoading=false;
                        }
                    })
                    .catch(e => {
                        self.errors.push(e);
                        self.isLoading=false;
                    });
            }
    },
    mounted(){
        if(!this.$store.state.LoggedIn){
            console.log('not logged in, return to homepage')
            this.$router.push('/');
        }
        let self=this;
        self.selMese= new Date().getMonth()+1;
        axios.get('api/v1/Lettere/GetReportModels')
            .then(response=>{
                self.ReportModels= response.data.result;
            })
            .catch(e => {
                self.errors.push(e)
            })
        
    }
}
</script>