<template>
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        <b-navbar v-if="this.$store.state.LoggedIn" toggleable="lg" type="dark" variant="dark">

            <b-navbar-brand href="/#">Calcolo Premi</b-navbar-brand>


            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>



            <b-collapse id="nav-collapse" is-nav>

                <b-navbar-nav v-if="SwiAmm">
                    <b-nav-item> <router-link :to="{ path: '/'}" class="nav-link">Contratti</router-link></b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav>
                    <!-- <b-nav-item @click="SetTipoInt(0)"> Clienti
        </b-nav-item>
        <b-nav-item @click="SetTipoInt(2)"> Agenti
        </b-nav-item> -->
                    <b-nav-item-dropdown v-if="SwiAmm" left :text="this.DesTipoInt">

                        <!-- <b-dropdown-item v-if="this.$store.state.VisibleMenu.find(m=> m.rifint==0 && m.visible)" @click="SetTipoInt(0)">
                            <font-awesome-icon v-if="this.$store.state.TipoInt==0" icon="check" /> Clienti
                        </b-dropdown-item>

                        <b-dropdown-item v-if="this.$store.state.VisibleMenu.find(m=> m.rifint==2 && m.visible)" @click="SetTipoInt(2)">
                            <font-awesome-icon v-if="this.$store.state.TipoInt==2" icon="check" /> {{this.$store.state.VisibleMenu.find(m=> m.rifint==2 && m.visible).menudes}}
                        </b-dropdown-item>
                         
                        <b-dropdown-item v-if="this.$store.state.VisibleMenu.find(m=> m.rifint==1 && m.visible)" @click="SetTipoInt(1)">
                            <font-awesome-icon v-if="this.$store.state.TipoInt==1" icon="check" /> {{this.$store.state.VisibleMenu.find(m=> m.rifint==1 && m.visible).menudes}}
                        </b-dropdown-item> -->



                        <b-dropdown-item v-for="tVM of $store.state.VisibleMenu.filter(m=> m.visible)" @click="SetTipoInt(tVM.rifint)" :key="tVM.id">
                            <font-awesome-icon v-if="$store.state.TipoInt==tVM.rifint" icon="check" /> {{tVM.menudes}}
                        </b-dropdown-item>

                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown v-if="SwiAmm" left text="Anagrafiche">

                        <b-dropdown-item :to="{ path: 'AnaSerie'}">
                            Serie
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ path: 'AnaClassi'}">
                            Classi
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ path: 'AnaTipiOb'}">
                            Tipi Obiettivi
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ path: 'AnaCampagneWeb'}">
                            Campagne Web
                        </b-dropdown-item>
                        <b-dropdown-item v-if="$store.state.TipoInt==1" :to="{ path: 'Fornitori'}">
                            Fornitori
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ path: 'ProfitCenter'}">
                            Profit Center
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown v-if="SwiAmm" left text="Funzioni">
                        <b-dropdown-item :to="{ path: 'DuplicaSerie'}">
                            Duplica Serie
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ path: 'DuplicaContratti'}">
                            Duplica Contratti
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ path: 'StampaLettere'}">
                            Genera Lettere
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ path: 'InviaLettere'}">
                            Invia Lettere
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ path: 'MultiModifica'}">
                            Multi Modifica
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ path: 'Opzioni'}">
                            Opzioni
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ path: 'Processi'}">
                            Aggiornamenti
                        </b-dropdown-item>


                        <b-dropdown-item :to="{ path: 'ImportaFile'}">
                            Importa File
                        </b-dropdown-item>

                    </b-nav-item-dropdown>

                   <b-nav-item-dropdown left text="Proposte WEB">
                        <b-dropdown-item :to="{ path: 'ProposteWEB'}">
                            Proposte WEB                            
                        </b-dropdown-item>
                    </b-nav-item-dropdown>    
                                    
                    <!-- <b-nav-form>
                        <b-button variant="outline-warning" class="my-2 my-sm-0" @click="ClickNuovo">Nuovo</b-button>
                    </b-nav-form> -->
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown right>
                        <!-- Using 'button-content' slot -->
                        <template #button-content>
                            <font-awesome-icon id="user-icon" icon="user-circle" size="2x"></font-awesome-icon>
                            <b-tooltip target="user-icon" triggers="hover">
                                <b>{{$store.state.Username}}</b>
                            </b-tooltip>
                        </template>
                        <b-dropdown-item>Connesso come : {{$store.state.Username}}</b-dropdown-item>
                        <b-dropdown-item v-if="SwiAmm" :to="{ path: 'Utenti'}">
                            Utenti
                        </b-dropdown-item>
                        <b-dropdown-item @click="Logout" class="text-right">
                            Logout
                        </b-dropdown-item>
                    </b-nav-item-dropdown>

                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        


    </div>

    
</template>
<style>
    .dropdown-toggle {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
    }

    .navbar-brand {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #5e5e5e;
    }

    .b-nav-dropdown {
        padding: 0px 5px;
    }
</style>

<script>

    import axios from 'axios';


    export default {      
        props: {
            LoggedIn: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                errors: [],
                Company: {},
                //LMenuList: {},
                RMenuList: {},
                isLoading: false,
                BASE_URL: ''
            };
        },
        computed: {
            // a computed getter
            DesTipoInt: function () {
                var tMenu=this.$store.state.VisibleMenu.find(m=> m.rifint==this.$store.state.TipoInt && m.visible);
                if(tMenu){
                    return tMenu.menudes|| 'N/D';
                }
                else{
                    if (this.$store.state.TipoInt == 0) {
                        return 'Clienti';
                    }
                    if (this.$store.state.TipoInt == 2) {
                        return 'Agenti';
                    }
                    if (this.$store.state.TipoInt == 1) {
                        return 'Fornitori';
                    }
                    return 'N/D';
                }
            },
            SwiUpdate: function () {

                if (this.RifJob > 0) {
                    //E' un metodo impreciso, andrebbe raffinato
                    if ((this.HasReqFilters) & (this.Filters.length > 0)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            },            
            SwiAmm(){
                //console.log("SwiAmm ", + this.$store.state.swiAMM);
                return this.$store.state.swiAMM;
            },
        },
        // watch: {
        //     LoggedIn: {
        //         immediate: true,
        //         deep: true,
        //         handler(newVal) {
        //             if (newVal) {

        //                 console.log('loggedi in menu');
                       

        //             }
                    
        //         }
        //     }
        // },
        methods: {
            SetCompany(parCompanyId) {                
                this.$store.commit('SetCompany', parCompanyId);                
            },
            GetMenuLink(MenuItem) {
                //console.log(MenuItem.classname);
                var tPar = "";
                if (MenuItem.RouteValues) {
                    if (MenuItem.RouteValues.id) {
                        tPar = '/' + MenuItem.RouteValues.id;
                    }
                }
                tPar = MenuItem.classname;
                return 'ListAna/' + tPar;
                //return '/' + MenuItem.Controller + '/' + MenuItem.Action + tPar;
            },
            GetObjFromApi(ApiUrl) {                      
                axios.get(ApiUrl, this.$store.state.Headers)
                    .then(response => {                        
                        this.$store.commit('SetEntity', response.data.result);
                    }).catch(e => {
                        this.errors.push(e)
                    });
            },
            Logout(){
                var Link = 'api/v1/Account/logout';
                axios.get(Link)
                    .then(response => {   
                        console.log(response)
                        this.$store.commit('SetLoggedIn', false);    
                        this.$store.commit('SetUsername', '');    
                        this.$router.push('/');
                    }).catch(e => {
                        this.errors.push(e)
                        this.errors.push({message: e.response.data.title})
                        if(e.response.data.status == 401){
                        //console.log(e.response)
                            this.$store.commit('SetLoggedIn', false);    
                            this.$store.commit('SetUsername', '');
                            this.$router.push('/');
                        }
                    });
            },
            
            onClose() {
               this.$bvModal.hide('mdl_NewContratto'); 
            },
            SetTipoInt(val){
                this.$store.commit('SetTipoInt',val);
            },

            onSubmit() {                
            //     let self=this;

            // var Link = 'api/_gen/Contratto/'  ;
            // axios.post(Link, self.Contratto)//, state.Headers)             

            //     console.log('posting new record');
            //     axios.post(Link, self.Row, this.$store.state.Headers)
            //         .then(response => {
            //             this.$bvModal.hide('mdl_NewContratto'); 
            //         })
            //         .catch(e => {                            
            //             this.errors.push(e)                            
            //         })
 
            }
        },

        mounted() {
            // var tMenu=this.$store.state.VisibleMenu.filter(m=> m.visible);
            // console.log("menu mounted tmenu-->", tMenu);
            // if(tMenu && tMenu.length==1){
            //     this.SetTipoInt(tMenu[0].rifint);
            // }
        }
    }


</script>