<template>
    <div>
         <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        
        <h3 class="text-center my-2">Invio Lettere</h3>

        <Filtri v-on:change="onChangeFiltri" :MostraIncludiInviati="false" :MostraStatoLettere="true" />  <!-- :TestoIncludiInviati="'Mostra Tutti'" /> -->
        <div class="row px-5 mt-3 mb-2">
            <b-button class="col-1" variant="link" @click="SelectAll">{{(isSelectedAll)?'Deseleziona': 'Seleziona'}} Tutti</b-button>
            
            <b-button class="col-1 ml-3" variant="primary" @click="Invia">Accoda Mail</b-button>
            <b-button class="col-1 ml-3" variant="outline-danger" @click="Elimina">Annulla Mail</b-button>
            <!-- :href="GetZip" -->
            <!-- <b-button class="col-1" variant="link" @click="InvertiSelezione">Inverti Selezione</b-button> -->
            <b-col sm="2" class="my-1">
                <b-form-group
                label="Per pagina"
                label-for="per-page-select"
                label-cols-sm="6"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="6" md="2" class="my-0 ">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="4" md="2" align-self="center">
                <label for="" class="col-form-label col-form-label-sm "> Righe Estratte {{ElencoMails.length?ElencoMails.length:0}}</label>
            </b-col>
            
            <b-col sm="4" md="2" >
                <b-row v-if="!DownloadingFile">
                    <b-col sm="5" class="px-0">
                        <b-button  variant="success" :disabled="!HasSelezionati" @click="download" >Scarica ZIP</b-button>
                    </b-col>
                    <b-col sm="7" class="px-0 ml-n1" align-self="center">
                        <b-form-checkbox v-model="SoloUltimeLettere" class="align-self-center">Solo Ultime Lettere</b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Downloading File...</strong>
                    </div>
                </b-row>
            </b-col>
        </div>
    <div class="px-5">
        <b-table hover 
                    small
                    head-variant="dark" 
                    sort-icon-left 
                    :items="ElencoMails" 
                    :fields="fields"                         
                    :busy="isLoading"
                    :current-page="currentPage"
                    :per-page="perPage">
                <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template> 
                <template v-slot:cell(sel)="data">
                    <b-form-group>
                        <b-form-checkbox  v-model="data.item.sel" @change="CheckSelezionati"/>
                    </b-form-group>
                </template>
                <template v-slot:cell(des)="data">
                    <div class="row">
                        {{data.item.des}} 
                        <div class="col-4 text-small text-secondary">{{ '   ('+ data.item.mail+') '}}</div>
                    </div>
                </template>
                <template v-slot:cell(actions)="row"> 
                        <font-awesome-icon v-if="row.item.maiL_STATUS ==1" icon="envelope-open"    class="ml-2" style="color:lightgrey"  v-b-popover.hover.topleft="'in attesa generazione report'"  />

                        <b-button v-if="row.item.maiL_STATUS == 10" variant="link" class="pt-0 pb-0"  :href="'file/v1/GetLetter?rifmail='+ row.item.maiL_ID"  v-b-popover.hover.topleft="'in attesa di conferma invio'" >
                            <font-awesome-icon icon="envelope-open-text"  class="text-warning"   />
                        </b-button>
                        <b-button v-if="row.item.maiL_STATUS == 20" variant="link"  class="pt-0 pb-0"  :href="'file/v1/GetLetter?rifmail='+ row.item.maiL_ID"  v-b-popover.hover.topleft="'invio in corso'" >
                           <font-awesome-icon  icon="envelope-open-text"  class="text-secondary" />
                        </b-button>
                        <b-button v-if="row.item.maiL_STATUS > 20" variant="link" class="pt-0 pb-0"   :href="'file/v1/GetLetter?rifmail='+ row.item.maiL_ID"  v-b-popover.hover.topleft="'Inviata'" >
                            <font-awesome-icon v-if="row.item.maiL_STATUS > 20" icon="envelope-open-text"  class="text-success" />
                        </b-button>
                        
                        <font-awesome-icon v-if="row.item.maiL_STATUS < 0" icon="envelope-open-text"  style="color:red" :id="'icon-err-'+row.item.id">
                            <b-tooltip :target="'icon-err-'+row.item.id" placement="topleft" triggers="hover">
                                <b>in attesa di invio</b>
                            </b-tooltip>
                        </font-awesome-icon>
                    
                </template>
        </b-table>
    </div>

    </div>
</template>

<script>
import axios from 'axios';
import Filtri from './components/Filtri.vue';
export default {
    name:"InviaLettere",
    components:{
        Filtri
    },
    data(){
        return{
            errors:[],
            perPage:25,
            currentPage:1,
            totalRows:1,
            pageOptions: [25, 100, 500, { value: 0, text: "Tutti" }],

            ElencoMails:[],
            fields:[
                { key: 'sel', sortable: true,  label:'S'},
                { key:"desint", label: "Intestatario" } ,
                { key:"maiL_TO", label: "A" } ,
                { key:"maiL_CC",label:"CC"},
                { key:"maiL_SUBJECT",label:"Oggetto"},
                { key:"maiL_STATUS", label:"Stato"},
                { key:"maiL_DELIVERY_NOTE",label:"Note Invio"},
                { key:"datinstxt",label:"Data Ins."},
                { key:"datupdtxt",label:"Data Mod."},
                { key:"actions",label:''}
            ],
            isLoading:false,
            isSelectedAll:false,
            SoloUltimeLettere:true,
            HasSelezionati:false,
            DownloadingFile:false
        }
    },
    computed:{
        GetZip:function(){
             let self=this; 
            var Link = 'file/v1/GetZipLetters?Tipo='+ self.$store.state.TipoInt
            if(self.$store.state.SwiIncludiInviati){
                Link +='&IncludiInviati='+self.$store.state.SwiIncludiInviati ;
            }
            if(self.$store.state.Filtri.txtRicercaCli){
                Link += '&TxtCliente='+self.$store.state.Filtri.txtRicercaCli; 
            }
            if(self.$store.state.Filtri.selTipoOb){
                Link += '&IdTipoObiettivo='+self.$store.state.Filtri.selTipoOb;
            }
            if(self.$store.state.Filtri.selClasse){
                Link += '&Classe='+self.$store.state.Filtri.selClasse;
            }
            if(self.$store.state.Filtri.selSerie){
                Link += '&Serie='+self.$store.state.Filtri.selSerie ;
            }
            if(self.$store.state.Filtri.selAgente){
                Link += '&CodAgente='+self.$store.state.Filtri.selAgente ;
            }
            
            return Link;
        }
    },
    methods:{
        CheckSelezionati(){
            this.HasSelezionati=(this.Selezionati());
        },
        Selezionati(){
            if(this.ElencoMails){
                var tElencoMails=[];
                this.ElencoMails.filter(i=> i.sel).forEach(t=> { tElencoMails.push(t.maiL_ID)});
                return (tElencoMails.length>0);
            }
            else{
                return false;
            }
        },
        //GetZip
        download(){
            let self=this; 
            var tElencoMails=[];
            self.ElencoMails.filter(i=> i.sel).forEach(t=> { tElencoMails.push(t.maiL_ID)});
            if(tElencoMails.length>0){
                self.errors=[];
                var tParametri={
                    "rifmaillist": tElencoMails,
                    "stato": self.SoloUltimeLettere?0 :99  //mi serve per scaricare tutte le referenze selezionate, altrimenti solo le ultime lettere di default
                };
                self.DownloadingFile=true;
                axios.post('file/v1/GetZipLetters',tParametri, {responseType: 'blob'})
                    .then(response => { 
                        console.log(response.data) 
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Files.zip');
                        document.body.appendChild(link);
                        link.click();    

                        self.DownloadingFile=false;
                        // let blob = new Blob([response.data], { type: 'application/zip' });
                        // let link = document.createElement('a');
                        // link.href = window.URL.createObjectURL(blob);
                        // link.download = 'Files.zip';
                        // link.click();

                    }).catch(e => {
                        console.log(e);
                         self.errors.push(e);
                        // if (e.response) { 
                        //     console.log(e.response);
                        //     if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) { 
                        //         self.errors.push({ message:  e.response.data.detail});
                        // console.log('>>>>>>>>>>>>>>>>e.response.data.detail:<<<<<<<<<<<<<<<<<<<<');
                        //     }
                        //     else { 
                        //         self.errors.push(e)
                        //             console.log('>>>>>>>>>>>>>>>>e e basta<<<<<<<<<<<<<<<<<<<<');
                        //     }
                        // }
                        // else { 
                        // console.log('>>>>>>>>>>>>>>>>no response:<<<<<<<<<<<<<<<<<<<<');
                        //     if(e.message){
                        //         self.errors.push(e);
                        //     }
                        //     else{
                        //         self.errors.push({message:e});
                        //     }
                        // }
                        self.DownloadingFile=false;
                    }); 
            }
            // var Link = 'file/v1/GetZipLetters?Tipo='+ self.$store.state.TipoInt;
            // if(self.$store.state.Filtri.SwiIncludiInviati){
            //     Link +='&IncludiInviati='+self.$store.state.Filtri.SwiIncludiInviati ;
            // }
            // if(self.$store.state.Filtri.txtRicercaCli){
            //     Link += '&TxtCliente='+self.$store.state.Filtri.txtRicercaCli; 
            // }
            // if(self.$store.state.Filtri.selTipoOb){
            //     Link += '&IdTipoObiettivo='+self.$store.state.Filtri.selTipoOb;
            // }
            // if(self.$store.state.Filtri.selClasse){
            //     Link += '&Classe='+self.$store.state.Filtri.selClasse;
            // }
            // if(self.$store.state.Filtri.selSerie){
            //     Link += '&Serie='+self.$store.state.Filtri.selSerie ;
            // }
            // if(self.$store.state.Filtri.selAgente){
            //     Link += '&CodAgente='+self.$store.state.Filtri.selAgente ;
            // }
            // console.log('link ',Link)
            // //return Link;
            // axios.get(Link, {responseType: 'blob'})//, state.Headers)
            //         .then(response => { 
            //             console.log(response.data)
            //             // let blob = new Blob([response.data], { type: 'application/zip' });
            //             // let link = document.createElement('a');
            //             // link.href = window.URL.createObjectURL(blob);
            //             // link.download = 'Files.zip';
            //             // link.click();

            //             const url = window.URL.createObjectURL(new Blob([response.data]));
            //             const link = document.createElement('a');
            //             link.href = url;
            //             link.setAttribute('download', 'Files.zip');
            //             document.body.appendChild(link);
            //             link.click();
                         
            //         }).catch(e => {
            //             self.errors.push(e)
            //         }); 
        },
        SelectAll(){
            let self=this;  
            self.isLoading=true;
            self.$nextTick(() => {
                self.isSelectedAll= !self.isSelectedAll;
                self.ElencoMails.forEach(cli => { 
                    cli.sel = self.isSelectedAll
                }); 
                self.CheckSelezionati();
                self.isLoading=false;
            });
        },
         
        onChangeFiltri(selClasse, selTipoOb, selSerie, txtRicercaCli, selAgente,SwiIncludiInviati,selStato){
                let self=this;
                self.isLoading=true;
                var Link = 'api/v1/Lettere/GetMails?Tipo='+ self.$store.state.TipoInt+'&IncludiInviati='+SwiIncludiInviati ;
                if(txtRicercaCli){
                    Link += '&TxtCliente='+txtRicercaCli; 
                }
                if(selTipoOb){
                    Link += '&IdTipoObiettivo='+selTipoOb;
                }
                if(selClasse){
                    Link += '&Classe='+selClasse;
                }
                if(selSerie){
                    Link += '&Serie='+selSerie ;
                }
                if(selAgente){
                    Link += '&CodAgente='+selAgente ;
                }
                if(selStato){
                    Link += '&Stato='+selStato ;
                }

                axios.get(Link)//, state.Headers)
                    .then(response => {
                        self.ElencoMails = response.data.result;
                        self.ElencoMails.forEach(e=> {e.sel=false;});
                        self.totalRows = self.ElencoMails.length;
                        self.currentPage = 1;
                        self.CheckSelezionati();
                        self.isSelectedAll=false;
                        self.isLoading=false;
                    }).catch(e => {
                        self.errors.push(e)
                    }); 
                // console.log('SetFiltri', {
                //     selClasse, selTipoOb, selSerie, txtRicercaCli, selAgente,SwiIncludiInviati
                // })
                // self.$store.commit('SetFiltri', {
                //     selClasse, selTipoOb, selSerie, txtRicercaCli, selAgente,SwiIncludiInviati
                // })

                if(!self.selReportModel){
                    self.selReportModel= self.$store.state.Classi.find(c=>c.codclasse==selClasse).reportdefault
                }
        },
        Invia(){
            this.pMultiMod(20);
        },
        Elimina(){
            this.pMultiMod(-20);
        },
        pMultiMod(stato){
            let self=this;

            if(self.$store.state.Filtri.selSerie>=888888)
            {
                alert("selezionare una serie!")
                return;
            }
            
            if(self.$store.state.Filtri.selClasse>=888888)
            {
                alert("selezionare una classe!")
                return;
            }

            var tElencoMails=[];
            self.ElencoMails.filter(i=> i.sel).forEach(t=> { tElencoMails.push(t.maiL_ID)});
            var tParametri={
                "rifmaillist": tElencoMails,
                "stato": stato 
            };

            //console.log("parametri : ", tParametri);
            self.isLoading=true;
            axios.post('api/v1/Lettere/MultiMod',tParametri)
                .then(response=>{
                    if(response.data.isError)
                    {
                        self.errors.push({message:response.data.message});
                    }
                    else
                    {
                        if(stato<0){
                            alert("Invio Email Annullato!");
                        }
                        else{
                            alert("Email Accodate!");
                        }
                        self.ElencoMails = [];
                        self.totalRows = self.ElencoMails.length;
                        self.currentPage = 1;
                        self.isLoading=false;
                    }
                })
                .catch(e => {
                    self.errors.push(e);
                    self.isLoading=false;
                });
        }
    },
    mounted(){
        if(!this.$store.state.LoggedIn){
            console.log('not logged in, return to homepage')
            this.$router.push('/');
        }

    }
}
</script>