<template>
    <div>
        <b-alert v-if="successMessage" variant="success" show dismissible>
            <ul>
                <li >
                    {{successMessage}}
                </li>
            </ul>
        </b-alert>
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>

        <b-table :items="pEmailsList" :fields="fields">
            <template v-slot:cell(actions)="row"> 
                <font-awesome-icon v-if="row.item.maiL_STATUS ==1" icon="envelope-open"    class="ml-2" style="color:lightgrey"  v-b-popover.hover.topleft="'in attesa generazione report'"  />

                <b-button v-if="row.item.maiL_STATUS == 10" variant="link" class="p-0 border-0"  :href="'file/v1/GetLetter?rifmail='+ row.item.maiL_ID"  v-b-popover.hover.topleft="'in attesa di conferma invio'" >
                    <font-awesome-icon icon="envelope-open-text"  class="text-warning"   />
                </b-button>
                <b-button v-if="row.item.maiL_STATUS == 20" variant="link"  class="p-0 border-0"  :href="'file/v1/GetLetter?rifmail='+ row.item.maiL_ID"  v-b-popover.hover.topleft="'invio in corso'" >
                    <font-awesome-icon  icon="envelope-open-text"  class="text-secondary" />
                </b-button>
                <b-button v-if="row.item.maiL_STATUS > 20" variant="link" class="p-0 border-0"   :href="'file/v1/GetLetter?rifmail='+ row.item.maiL_ID"  v-b-popover.hover.topleft="'Inviata'" >
                    <font-awesome-icon v-if="row.item.maiL_STATUS > 20" icon="envelope-open-text"  class="text-success" />
                </b-button>
                
                <font-awesome-icon 
                                    v-if="row.item.maiL_STATUS < 0" icon="envelope-open-text"  style="color:red" :id="'icon-err-'+row.item.id" v-b-popover.hover.topleft="'Errore invio/Annullato'">
                </font-awesome-icon>
            
            </template>
        </b-table>

        <b-button v-if="pEmailsList.length>0" variant="primary" size="sm" @click="SendMail">
            Re-Invia Ultima Mail
        </b-button>
    </div>
</template>

<script>
    import axios from 'axios';

export default {

    name: 'logmail',
    props: {
        IdContratto: {
            type: Number,
            required: true
        } 
    },
    data() {
        return {
            successMessage:'',
            errors: [],    
            pEmailsList:[],
            fields:[
                { key:"maiL_TO", label: "A" } ,
                { key:"maiL_CC",label:"CC"},
                { key:"maiL_SUBJECT",label:"Oggetto"},
                { key:"maiL_STATUS", label:"Stato"},
                { key:"maiL_DELIVERY_NOTE",label:"Note Invio"},
                { key:"datinstxt",label:"Data Ins."},
                { key:"datupdtxt",label:"Data Mod."},
                { key:"actions",label:''}
            ]
		//"maiL_INVIOIMMEDIATO": false
        };
    },
    methods:{
        SendMail(){
            let self = this;

            var tMaxID = Math.max.apply(Math,self.pEmailsList.map(function(o){return o.maiL_ID;}));

            if(tMaxID){
                var tEmail = self.pEmailsList.find(m=> m.maiL_ID== tMaxID);

                tEmail.maiL_STATUS=20;
                tEmail.maiL_DELIVERY_NOTE="Sending..";
                axios.put('api/_gen/Mail/', tEmail)
                    .then(r=>{
                        if(r.data.isError){
                            self.errors.push({message: r.data.message})
                        }
                        else
                        {
                            self.successMessage= r.data.message;
                        }
                    })
                    .catch(e=>{ self.errors.push(e)})
            }
        }
    },
    mounted(){
        let self=this;
        if(self.IdContratto!=0){
            axios.get('api/v1/Lettere/GetMailsFromContract?rifcnt='+self.IdContratto)
                .then(r=>{
                    self.pEmailsList=r.data.result;
                })
                .catch(e=>{ self.errors.push(e)})
        }
    }
}
</script>