<template>
    <div class="row">
        <b-input-group class="col-2">
            <b-input-group-prepend>
                <b-button variant="outline-primary">Tipo</b-button>
            </b-input-group-prepend>
            <b-form-select  v-model="TipoObDetLiq.tiporegola"
                            :options="this.$store.state.RegoleLiquidazioni"
                            class=""
                            value-field="codtip"
                            text-field="destip"></b-form-select>
        </b-input-group>
        <b-input-group class="col-3">
            <b-input-group-prepend>
                <b-button variant="outline-primary">Percentuale</b-button>
            </b-input-group-prepend>
            <b-form-input v-model="TipoObDetLiq.perliq" type="text"></b-form-input> 
        </b-input-group>
        <b-input-group class="col-3">
            <b-input-group-prepend>
                <b-button variant="outline-primary">Scadenza</b-button>
            </b-input-group-prepend>
            <b-form-input v-model="TipoObDetLiq.datsca" type="date"></b-form-input> 
        </b-input-group>
        <b-input-group class="col-3">
            <b-input-group-prepend>
                <b-button variant="outline-primary">Tipo Obiettivo</b-button>
            </b-input-group-prepend>
                <b-form-select  v-model="TipoObDetLiq.rifobbmix"
                                :options="this.$store.state.ObiettiviLiquidazioni"
                                class=""
                                value-field="id"
                                text-field="descon"
                                disabled-field="notEnabled" ></b-form-select>
        </b-input-group>

        <b-input-group class="col-1"> 
                <b-button variant="outline-danger" v-on:click="EliminaRecord">X</b-button>
        </b-input-group>
    </div>
</template>
<script>
export default {
    props: {
        IdTipoOb: {
            type: Number,
            required: true
        } ,
        TipoObDetLiq: {
            type: Object,
            required: true
        } 
    },  
    data() {
        return {
            errors: []
        };
    },
    watch: {
    },
    methods:{
        EliminaRecord(){
            this.$emit("delete", this.TipoObDetLiq.keytipliq==0? this.TipoObDetLiq.rowKey : this.TipoObDetLiq.keytipliq);
        }
    },
    mounted(){
    }
}
</script>