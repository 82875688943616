<template>
    <div class ="container">
        <div class="row mt-2"> 
            <h5>Duplica Contratti</h5>
        </div>
        <div class="row mt-2"> 
            <b-input-group class="col-8 col-md-4"  >
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Tipo Sorgente</b-button>
                </b-input-group-prepend>

                <b-form-select
                        v-model="selTipoObSorg"
                        :options="tipiOb"
                        class=""
                        value-field="id"
                        text-field="descon"
                        disabled-field="notEnabled"
                        ></b-form-select>
            </b-input-group> 
        </div>
        <div class="row mt-2"> 
            <b-input-group class="col-8 col-md-4"  >
                <b-input-group-prepend>
                    <b-button variant="outline-primary" disabled>Tipo Destinazione</b-button>
                </b-input-group-prepend>

                <b-form-select
                        v-model="selTipoObDest"
                        :options="tipiOb"
                        class=""
                        value-field="id"
                        text-field="descon"
                        disabled-field="notEnabled"
                        ></b-form-select>
            </b-input-group>
        </div>
        <div class="row mt-2">  
            <div class="col-8 col-md-4">
                <b-form-checkbox class="col-12" id="swiabi_chkbox" v-model="soloAbilitate">Visualizza solo serie abilitate</b-form-checkbox>
            </div>  
        </div>
        <div class="row mt-2">  
             <div class="col-8 col-md-4 offset-4 offset-md-2">
                <b-button variant="success" v-on:click="Duplica"> Duplica! </b-button>
                &nbsp;
                <b-button variant="outline-warning" href="#/"> Annulla </b-button>
             </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            soloAbilitate:true,
            tipiOb:[] ,
            selTipoObSorg:0,
            selTipoObDest:0
        }
    },
     watch: {
            soloAbilitate: {
                immediate: true,
                deep: true,
                handler() {
                        this. GetSoloAbilitati();
                }
            }
     },
    methods :{
        Duplica(){
            if(!this.selTipoObSorg){
                alert("Tipo Sorgente mancante!");
                return
            }
            if(!this.selTipoObDest){
                alert("Tipo Destinazione mancante!");
                return
            }
            if(this.selTipoObSorg===this.selTipoObDest){
                alert("Tipo Sorgente e destinazione uguale!");
                return
            }
            axios.get('api/v1/TipiObiettivi/DuplicaContratti?orig='+this.selTipoObSorg+'&dest='+this.selTipoObDest)
                .then(r=>{
                    if(r.data.isError){
                        alert(r.data.title);
                    }
                    else{
                        alert(r.data.message);
                    }

                })
                .catch(e=>{
                     if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                        //console.log("Errore in catch 1:" + e.response.data.detail);
                        alert(e.response.data.detail);
                    }
                    else {
                        //console.log("Errore in catch 2:" + e.message);
                        alert(e)    
                    }
                });

        },
        GetSoloAbilitati(){
            let self=this;
            self.tipiOb=[];
            if(!self.soloAbilitate){
                 this.$store.state.TipiOb.forEach(el => {
                        self.tipiOb.push(el);
                    });
            }
            else{
                console.log("GetSoloAbilitati true");
                var series = self.$store.state.Series.filter(s=> s.abilitata== self.soloAbilitate); 
                console.log("GetSoloAbilitati serie "+ series.length);

                self.$store.state.TipiOb.forEach(t=>{ 
                    series.forEach (s=> {
                            if(s.id==t.rifserie){
                               self.tipiOb.push(t);
                           }
                    });
                }); 
                console.log("GetSoloAbilitati tipi serie abilitati "+ self.tipiOb.length);
            }
        }
    },
    beforeMount(){      
        this.GetSoloAbilitati();
    },
    mounted(){
        if(!this.$store.state.LoggedIn){
                this.$router.push('/');
            }
    }
}
</script>