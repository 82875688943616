<template>
    <div>
        <!-- Home, componente vuoto -->      
        <Login v-if="!this.$store.state.LoggedIn"></Login>
        <ElencoContratti v-else-if="SwiAmm" />
        <ProposteWEB v-else />
    </div>
</template>

<script>
    import Login from './Login.vue';
    import ElencoContratti from './ElencoContratti.vue';        
    import ProposteWEB from './ProposteWEB.vue';
    export default {
        components: {
            Login, ElencoContratti,
                ProposteWEB
        },        
        computed: {
            SwiAmm(){
                //console.log("SwiAmm ", + this.$store.state.swiAMM);
                return this.$store.state.swiAMM;
            }

        },
        methods: {
            onChangeFiltri(classe, tipoob, serie){
                console.log('classe '+classe );
                console.log('tipoob '+tipoob );
                console.log('serie '+serie );
            }
        }
    }
</script>
