<template>
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        <div class="row"> 
            <b-input-group class="col-6">
                 <b-input-group-prepend>
                    <b-button variant="outline-primary">Ricerca Intestatari</b-button>
                </b-input-group-prepend>

                <b-form-input type="text" debounce="1600" v-model="txtRicercaCli" v-on:keyup.enter="Cerca"></b-form-input>
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="txtRicercaCli=''">x</b-button> 
                </b-input-group-append>
            </b-input-group>
            <b-button variant="primary" small @click="Cerca" >Cerca</b-button>
        </div>
        <div class="row">
        <div class="col-6">
            <div class="row my-3">
                <div class="col-6"> Elenco clienti Ricerca</div>
                <b-button variant="outline-warning" class="offset-4" size="sm" @click="Aggiungi"> Aggiungi</b-button>
            </div>
            <b-table small head-variant="dark" sort-icon-left :items="ElencoCliRicerca" :fields="fields" primary-key="cod">
                <template v-slot:head(selected)="data">
                    <b-form-checkbox size="lg"  v-model="isSelectedRicercaAll" @change="SelectAllCli(data)" />
                </template> 
                <template v-slot:cell(selected)="data">
                    <b-form-group>
                         <b-form-checkbox  v-model="data.item.selected" @change="SelectCli(data.item)"/>
                    </b-form-group>
                </template>
                        
            </b-table>
        </div>
        <div class="col-6"> 
             <div class="row my-3">
                <div class="col-6"> Elenco clienti Associati</div>
                <b-button variant="outline-danger" class="offset-4" size="sm" @click="Rimuovi"> Rimuovi</b-button>
            </div>
            <b-table small head-variant="dark" sort-icon-left :items="ElencoCliAssociati" :fields="fields" >
                
                <template v-slot:head(selected)="data">
                    <b-form-checkbox size="lg"  v-model="isSelectedAssociatiAll" @change="SelectAllAssociati(data)" />
                </template> 
                <template v-slot:cell(selected)="data">
                    <b-form-group>
                         <b-form-checkbox  v-model="data.item.sel" @change="SelectIntAssociati(data.item)"/>
                    </b-form-group>
                </template>
                        
            </b-table>
        </div>
        </div>
    </div>
</template>

<script> 
    
    import axios from 'axios'; 

export default {
    props:{
        IdContratto: {
                type: Number,
                required: true
            }
    }, 
    data() {
        return {
            errors:[],
            isLoading: true,
            txtRicercaCli:'',
            ElencoContrattiInt:[],
            ElencoCliAssociati:[],
            // fieldsAssociati:[  
            //     { key: 'cod', sortable: true, label:'Codice' },
            //     { key: 'des', sortable: true, label:'Rag.Soc' }
            // ],
            ElencoCliRicerca:[],
            fields:[ 
                { key:'selected', label:''},
                { key: 'cod', sortable: true, label:'Codice' },
                { key: 'des', sortable: true, label:'Rag.Soc' }
            ],
            isSelectedRicercaAll:false,
            SelectedCliRicerca:[],
            
            isSelectedAssociatiAll:false,
            SelectedCliAssociati:[],
        };
    },
    watch: { 
        'Contratto.datinitxt':function(newval) {
            this.Contratto.datini=newval;
        },
    },
    methods:{
        Cerca(){
            let self=this;
            self.isSelectedRicercaAll=false;
            self.ElencoCliRicerca=[];
//  api/v1/Intestatari/GetFromType?str=SETTEMBRINI%20
            var Link = 'api/v1/Intestatari/GetFromType?tipo='+self.$store.state.TipoInt +'&str='+ this.txtRicercaCli ;
            axios.get(Link)//, state.Headers)
                        .then(response => {
                            
                            var tElenco = response.data.result;

                            tElenco.forEach(el => {
                                //escludo quelli già presenti nell'altro elenco
                                if(self.ElencoCliAssociati.filter(e=> e.cod== el.cod).length==0)
                                {
                                    var tObj = { selected:false, cod:el.cod, des: el.des}
                                    self.ElencoCliRicerca .push(tObj);      
                                }
                            });

                            self.isLoading=false;
                        }).catch(e => {
                            self.errors.push(e)
                        }); 
        },
        
        SelectAllCli(){
            let self=this;
            //self.isSelectedRicercaAll = !self.isSelectedRicercaAll;
            self.ElencoCliRicerca.forEach(cli => cli.selected = self.isSelectedRicercaAll);
            //console.log('SelectAllCli --> dopo foreach',self.ElencoCliRicerca);
            if(!self.isSelectedRicercaAll){
                self.SelectedCliRicerca=[];
            }
            else{
                //console.log('SelectAllCli --> else',self.SelectedCliRicerca);
                self.SelectedCliRicerca=[];
                self.ElencoCliRicerca.forEach(cli=> self.SelectedCliRicerca.push(cli.cod));
            }
            //console.log('SelectAllCli --> dopo if',self.SelectedCliRicerca.length);
        },
        SelectCli(parcli){
            let self=this;
            //parcli.selected = !parcli.selected;
            self.isSelectedRicercaAll = false;

            const selected = self.ElencoCliRicerca.filter(cli => cli.selected);
            if(selected.length === self.ElencoCliRicerca.length) {
                self.isSelectedRicercaAll = true
            }
            else {
                self.isSelectedRicercaAll = false
            } 
            if(parcli.selected) {
                if (!self.SelectedCliRicerca.find(c => c.cod === parcli.cod)) {
                    self.SelectedCliRicerca.push(parcli.cod);
                }
            }
            else{
                const index = self.SelectedCliRicerca.indexOf(parcli.cod);
                self.SelectedCliRicerca.splice(index, 1); 
            }
        },

        SelectAllAssociati(){
            let self=this;
            //self.isSelectedAssociatiAll=!self.isSelectedAssociatiAll; //inverto il flag
            self.ElencoCliAssociati.forEach(cli => cli.sel = self.isSelectedAssociatiAll);
           
            if(!self.isSelectedAssociatiAll){
                self.SelectedCliAssociati=[];
            }
            else{
                //console.log('SelectAllCliAssociati --> else',self.SelectedCliAssociati);
                self.SelectedCliAssociati=[];
                self.ElencoCliAssociati.forEach(cli=> self.SelectedCliAssociati.push(cli.cod));
            }
        },
        SelectIntAssociati(parcli){
            let self=this;
            //parcli.selected = !parcli.selected;
            self.isSelectedAssociatiAll = false;
            
            const selected = self.ElencoCliAssociati.filter(cli => cli.sel);
            if(selected.length === self.ElencoCliAssociati.length) {
                self.isSelectedAssociatiAll = true
            }
            else {
                self.isSelectedAssociatiAll = false
            } 
            if(parcli.sel) {
                if (!self.SelectedCliAssociati.find(c => c.cod === parcli.cod)) {
                    self.SelectedCliAssociati.push(parcli.cod);
                }
            }
            else{
                const index = self.SelectedCliAssociati.indexOf(parcli.cod);
                self.SelectedCliAssociati.splice(index, 1); 
            }
        },
        Aggiungi(){
            let self=this;
            var cnt=0;
            var Link='api/_gen/Contratto_Int/';
            self.SelectedCliRicerca.forEach(i=>{
                axios.post(Link,{rifcon: self.IdContratto,rifint: i})//, state.Headers)
                            .then(response=>{
                                if(response){  
                                    cnt++;
                                    if(cnt== self.SelectedCliRicerca.length) {
                                        self.Initialize();
                                    }
                                    //self.$emit('change', 'Dettagli ('+self.ElencoCliAssociati.length+')'); 
                                }
                            }).catch(e => {
                                cnt++;
                                self.errors.push(e)
                            }); 
            })
        },
        Rimuovi(){
            let self=this;
            var cnt=0;
            var Link='api/_gen/Contratto_Int/';
            self.SelectedCliAssociati.forEach(i=>{
                
                var idConInt=self.ElencoContrattiInt.find(c=>c.rifint==i);
                if(idConInt){
                    axios.delete(Link+idConInt.keyconint)//, state.Headers)
                        .then(response=>{
                            if(response){        
                                cnt++;
                                if(cnt== self.SelectedCliAssociati.length) {                        
                                    self.Initialize();
                                }
                                //self.$emit('change', 'Dettagli ('+self.ElencoCliAssociati.length+')'); 
                            }
                        }).catch(e => {
                            cnt++;
                            self.errors.push(e)
                        }); 
                }
            })
        }
        ,
        Initialize(){
            let self=this; 
            self.isLoading=true;
            self.ElencoCliAssociati=[];
            
            //leggo i dati già associati 
            if(self.IdContratto!=0){
                var Link = 'api/v1/Intestatari/GetFromContract?tipo='+self.$store.state.TipoInt +'&rifcon='+ this.IdContratto ;
                axios.get(Link)//, state.Headers)
                            .then(response => {
                                var tElenco = response.data.result;
                                tElenco.forEach(el=> {
                                    el.sel=false;
                                    self.ElencoCliAssociati.push(el);
                                })
                                self.isLoading=false;
                                self.$emit('changeCntDet', ' ('+self.ElencoCliAssociati.length+')'); 
                            }).catch(e => {
                                self.errors.push(e)
                            }); 

                Link='api/_gen/Contratto_Int/childof/'+self.IdContratto;
                 axios.get(Link)//, state.Headers)
                            .then(response => {
                                self.ElencoContrattiInt = response.data.result;
                                self.isLoading=false;
                            }).catch(e => {
                                self.errors.push(e)
                            }); 
            }
        }
    },
    mounted(){
       this.Initialize();
    }
}
</script>