<template id="AnaSerie">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <h2 class="text-center mt-3 mb-2">
            Elenco Serie
            <b-button title="Aggiungi Serie" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="NuovaSerie()" v-b-modal.modal-edit><font-awesome-icon size="sm" icon="plus" /></b-button>
        </h2>
        <!--Spinner-->
        <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div>
        <div v-else>
            <div class="container">                
                <b-table :items="this.$store.state.Series" :fields="fields" no-footer-sorting sort-icon-left responsive class="table col-sm-8 offset-sm-2">
                    <!-- A virtual composite column -->
                    <template v-slot:cell(abilitata)="data">
                        <b-form-checkbox :id="'checkbox'+data.index"
                                         v-model="data.item.abilitata"
                                         :name="'checkbox-'+data.item.abilitata"
                                         value="true"
                                         unchecked-value="false"
                                         @change="UpdateRec(data.item)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(id)="data">
                        <font-awesome-icon size="lg" icon="edit" @click="OpenModal(data.item)"  v-b-modal.modal-edit style="color:darkorange" />
                        <font-awesome-icon class="icon" size="lg" icon="times" @click="Delete(data.item)" />
                    </template>
                </b-table>

            </div>
            <b-modal id="modal-edit" title="Serie" ref="modal"
                     @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Nome" :state="State" label-for="name-input" invalid-feedback="Nome is required">
                        <b-form-input id="name-input" v-model="currentSerie.nomeserie" :state="State" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Abilitata" label-for="swiabi_chkbox">
                        <b-form-checkbox id="swiabi_chkbox" v-model="currentSerie.abilitata"></b-form-checkbox>
                    </b-form-group>
                </form>
            </b-modal>
        </div>
    </div>
</template>

<style>

    .icon {
        color: red;
        margin-left: 10px;
    }


</style>

<script>    
    //require("@/assets/search.png")
    import axios from 'axios';
    //import EditAna from './EditAna.vue';

    export default {

        data() {
            return {
                errors: [],
                fields: [                    
                    {
                        key: 'nomeserie',
                        label: 'Descrizione',
                        sortable: true
                    },
                    {
                        key: 'abilitata',
                        label: 'Abilitata',
                        sortable: true                       
                    },
                    {
                        key: 'id',
                        sortable: false
                    }],
                isLoading: false,
                SelectedEntity: Object,
                classdef: Object,
                KeyPropDef: Object,
                //series: [],
                search: '',
                awaitingSearch: false,
                SelectedRowId: {
                    type: String,
                    default: ''
                },
                SwiEdit: false,
                currentSerie:{},
                showModal:false,
                State:null,
                isNew: false
            };
        },
        components: {         
        },
        watch: {            
        },

        methods: {
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.State = valid
                //if(!valid){console.log("form non valida :" +valid)}
                return valid
            },
            resetModal() {
                this.currentSerie = {}
                this.State = null
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                let self=this;
                // Exit when the form isn't valid
                if (!self.checkFormValidity()) {
                return
                }
                // Push the name to submitted names
               // this.submittedNames.push(this.name)
                this.SaveRec();
            },
            SaveRec() {
                let self = this;
                if (self.isNew) {
                    //var tnuova = {
                    //    id: 0,
                    //    nomeserie: self.currentSerie.nomeserie,
                    //    abilitata: self.currentSerie.abilitata
                    //};                    
                    axios.post('api/v1/Series/', self.currentSerie)
                        .then(resp => {
                            if (resp.data.result > 0) {

                                self.$store.commit('SetSeries');
                                
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.isNew = false;
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")
                                })
                            }
                            else {
                                alert("serie non salvata");
                            }

                        })
                        .catch(e => {
                            if (e.response) {
                                //console.log("e.response");
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                    
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {                                    
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });

                }
                else {
                    
                    axios.put('api/v1/Series/' + self.currentSerie.id, self.currentSerie)
                        .then(resp => {
                            if (resp.data.result) {
                                self.$store.commit('SetSeries');

                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")
                                })
                            }
                            else {
                                alert("serie non salvata");
                            }
                        })
                        .catch(e => {
                            if (e.response) {
                                //console.log("e.response");
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                    //console.log("Errore in catch 1:" + e.response.data.detail);
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {
                                    //console.log("Errore in catch 2:" + e.message);
                                    self.errors.push(e)
                                }
                            }
                            else {
                                //console.log("Errore in catch 3:" + e.message);
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });
                }
            },
            UpdateRec(serie) {
                this.currentSerie = {
                    id: serie.id,
                    nomeserie: serie.nomeserie,
                    abilitata: serie.abilitata
                };       
                this.SaveRec();
            },
            OpenModal(serie){
                this.currentSerie={
                    id: serie.id,
                    nomeserie: serie.nomeserie,
                    abilitata: serie.abilitata
                };                
                //this.showModal=true;
                //this.$bvModal.show('modal-edit');
            },
            NuovaSerie(){
                 this.currentSerie={
                    id: 0,
                    nomeserie: '',
                    abilitata: false
                };
                this.isNew=true;
            },
            Delete(serie){
                let self=this;

                if (confirm("Cancellare la serie "+serie.nomeserie+"?"))
                {
                    axios.delete('api/v1/Series/'+ serie.id)
                        .then(resp => {
                            if(!resp.data.result)
                            { 
                                //console.log("Errore in response:" + resp.data.errors.message);
                                self.errors.push({ message: resp.data.errors.message});
                            }
                            else
                            {
                                self.$store.commit('SetSeries');
                                //self.series = self.series.filter(function (item) {
                                //                    return item.id !== serie.id
                                //                });
                            }
                        })
                        .catch(e =>{
                            if (e.response) {
                                 //console.log("e.response");
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                    //console.log("Errore in catch 1:" + e.response.data.detail);
                                    self.errors.push({ message:  e.response.data.detail});
                                }
                                else {
                                    //console.log("Errore in catch 2:" + e.message);
                                    self.errors.push(e)
                                }
                            }
                            else {
                                //console.log("Errore in catch 3:" + e.message);
                                self.errors.push(e)
                            }
                        }
                    );
                }
            }
        },
        mounted() {            
            if(!this.$store.state.LoggedIn){
                this.$router.push('/');
            }
        },
        updated() {
            
        }

        
    }


</script>