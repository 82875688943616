<template id="ElencoProcessi">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <h2 class="text-center mt-3 mb-2">
            Aggiornamenti            
        </h2>
        <div class="container">
            <b-table :items="Processi" :primary-key="'proc_id'" :fields="fields" class="table_Cell">
                

                <template #cell(proc_status)="data">                    
                    <font-awesome-icon size="lg" :class="GetStatusColor(data.item.proc_status)" :icon="GetStatusIcon(data.item.proc_status)" :spin="data.item.proc_status==2" />
                </template>

                <template #cell(proc_id)="data">                    
                    <font-awesome-icon v-if="data.item.proc_status !==1" size="lg" style="color: forestgreen;cursor: pointer;" icon="play"  @click="ExecProc(data.item.proc_id)" />
                </template>

            

            </b-table>
        </div>


    </div>
</template>

<style>
    .icon_red {
        color: red;
    }

    .icon_orange {
        color: orange;
    }

    .icon_green {
        color: forestgreen;
    }

    .icon_azure {
        color: darkturquoise;
    }
</style>

<script>
    import axios from 'axios';    
    import UploadService from "../UploadFilesService";
    
    export default {
        name: 'ElencoProcessi',        
        data() {
            return {
                errors: [],                
                fields: [
                    {
                        key: 'proc_name',
                        label: 'Nome',
                        sortable: true
                    },                    
                    {
                        key: 'proc_message',
                        label: 'Info',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        sortable: true
                    },
                    {
                        key: 'proc_status',
                        label: 'Status',
                        sortable: false
                    },
                    {
                        key: 'proc_id',
                        label: '',
                        sortable: false
                    },

                ],
                Processi: [],
                IdFileUploaded: 0,
                JobStatus: {},
                //SelectedLayout: undefined,
                selectedFiles: undefined,
                sIcons: [
                    {
                        id: 0,
                        icon: 'check-circle',
                        class: 'icon_green'
                    },
                    {
                        id: 1,
                        icon: 'clock',
                        class: 'icon_orange'
                    },                    
                    {
                        id: 2,
                        icon: 'sync',
                        class: 'icon_orange'
                    },
                    {
                        id: 99,
                        icon: 'exclamation-triangle',
                        class: 'icon_red'
                    }
                ],
                currentFile: undefined,
                progress: 0,
                message: '',                
                timer: '',
            }
        },
        computed: {           
        },
        components: {            
        },
        methods: {
            GetProcessiList() {
                let self = this;
                self.isLoading = true;
                self.Processi = [];                
                var Link = 'api/v1/Processi';
                axios.get(Link)
                    .then(response => {
                        self.Processi = response.data.result;                  
                                                
                        self.isLoading = false;
                    }).catch(e => {
                        this.errors.push(e)
                        self.isLoading = false;
                    });


            },
            ExecProc(parId) {                           
                console.log('ExecProc : ' + parId);
                let self = this;
                var Link = '/api/v1/Processi/Avvia/' + parId;
                axios.put(Link)
                    .then(response => {
                        
                        
                        if (response.data.result == true){

                            let tProc = self.Processi.find(e => e.proc_id === parId);
                            tProc.proc_message = 'in coda';
                            tProc.proc_status = 0;


                            if (self.timer == '') {
                                self.timer = setInterval(this.GetProcessiList, 10000)
                            }
                        }
                        else {
                            clearInterval(this.timer);                            
                        }


                    }).catch(e => {
                        this.errors.push(e);
                    });
                

            },
            GetStatusColor: function (idstatus) {
                var tIcon = this.sIcons.find(e => e.id == idstatus);
                if (tIcon) {
                    return tIcon.class;
                }
                else {
                    return 'icon_green';
                }
            },
            GetStatusIcon: function (idstatus) {
                var tIcon = this.sIcons.find(e => e.id == idstatus);                
                if (tIcon) {
                    return tIcon.icon;
                }
                else {
                    return 'bug';
                }
            },
            UploadFile() {
                this.IdFileUploaded = 0;
                this.progress = 0;
                this.message = '';
                let self = this;
                this.currentFile = this.selectedFiles[0];

                // Parametri import
                var tParams = {};

                var Link ='api/v1/ImportFatturato/UploadFile';                                
                UploadService.upload(Link, this.currentFile, tParams,this.$store.state.Token, event => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                })
                    .then(response => {
                        self.message = response.data.message;
                        self.IdFileUploaded = response.data.result;                                                
                        self.GetProcessiList();                                              
                        self.currentFile = undefined;
                        if (self.IdFileUploaded > 0) {
                            self.GetJobStatus();
                            self.timer = setInterval(this.GetJobStatus, 10000)
                        }
                        
                    })
                    .catch(() => {
                        self.progress = 0;
                        self.message = "Could not upload the file!";
                        self.currentFile = undefined;
                    });

                this.selectedFiles = undefined;                
            }                                  
            

        },
        mounted() {            
                 
            //this.SelectedLayout = {};
            this.GetProcessiList();            
        },
    }
</script>