<template id="CampagneWeb_TipiObiettivi">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        <div v-if="!SwiEdit">
            <div>
                <b-button title="Nuovo Dettaglio" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="AddRow()"><font-awesome-icon size="sm" icon="plus" /></b-button>
            </div>
            <div class="row">
                <b-table :items="Tipiobiettivi" :fields="fields" no-footer-sorting sort-icon-left responsive class="table col-sm-10 offset-sm-1">
                    <template v-slot:cell(idtipoobiettivo)="data">
                        <span>{{GetTipObDesc(data.item.idtipoobiettivo)}}</span>
                        <model-list-select v-if="1==2"
                                           :list="$store.state.TipiOb"
                                           v-model="data.item.idtipoobiettivo"
                                           class="col-7 rounded-0"
                                           option-value="id"
                                           option-text="descon"
                                           placeholder="Select an item">
                        </model-list-select>
                    </template>


                    <template v-slot:cell(edit)="data">
                        <font-awesome-icon size="lg" icon="edit" @click="EditRow(data.item.idtipoobiettivo)" style="color:darkorange" />
                    </template>

                </b-table>



            </div>
        </div>

        <div v-else class="container">

            <b-form-group label="Nome"
                          label-for="name-input"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          invalid-feedback="Nome is required"
                          :horizontal="true">
                <model-list-select id="name-input"
                                   :list="$store.state.TipiOb"
                                   v-model="RowSel.idtipoobiettivo"
                                   class="col-7 rounded-0"
                                   option-value="id"
                                   option-text="descon"
                                   placeholder="Select an item">
                </model-list-select>
            </b-form-group>

            <b-form-group label="Ordinamento"
                          label-for="ord-input"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          invalid-feedback="Nome is required"
                          :horizontal="true">
                <b-form-input id="ord-input" class="col-7 rounded-0" v-model="RowSel.numord" required></b-form-input>
            </b-form-group>

            <b-form-group label="Perc. Increm."
                          label-for="perc-input"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          invalid-feedback="Nome is required"
                          :horizontal="true">
                <b-form-input id="perc-input" class="col-7 rounded-0" v-model="RowSel.percincpropweb" required></b-form-input>
            </b-form-group>

            <b-form-group label="Riferimento"
                          label-for="idtipobref-input"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          invalid-feedback="Nome is required"
                          :horizontal="true">
                <model-list-select id="idtipobref-input"
                                   :list="$store.state.TipiOb"
                                   v-model="RowSel.idtipobref"
                                   class="col-7 rounded-0"
                                   option-value="id"
                                   option-text="descon"
                                   placeholder="Select an item">
                </model-list-select>
            </b-form-group>

            <b-button class="buttonfooter" variant="outline-success" @click="SaveRow()">Salva</b-button>
            <b-button class="buttonfooter" variant="outline-secondary" @click="CancelEdit()">Annulla</b-button>
            <b-button class="buttonfooter" variant="outline-danger" @click="DeleteRow()">Elimina</b-button>

        </div>




    </div>
</template>

<style>

    .icon {
        color: red;
        margin-left: 10px;
    }

    .buttonfooter {        
        margin-left: 10px;
        margin-right: 10px;
    }

    .vdatetime-input {
        border: 0;
        text-align: center;
        width: 100%;
    }
</style>


<script>
    import axios from 'axios';
    import { ModelListSelect } from 'vue-search-select';

    export default {
        name: 'CampagneWeb_TipiObiettivi',
        props: {
            idcampagna: {
                type: Number,
                required: true
            }
        },
        components: { ModelListSelect },
        data() {
            return {
                errors: [],
                fields: [
                    {
                        key: 'idtipoobiettivo',
                        label: 'Tipo',
                        sortable: false
                    },
                    {
                        key: 'numord',
                        label: 'Ord.',
                        sortable: true
                    },
                    {
                        key: 'edit',
                        label: '',
                        sortable: false
                    }],
                RifRowSel: 0,
                RowSel: {},
                Tipiobiettivi: [],
                SwiEdit: false,
                SwiNewRec: false

            };
        },
        watch: {
        },
        methods: {
            AddRow() {
                let tNumOrd = 1;
                if (this.Tipiobiettivi.length > 0) {
                    tNumOrd = Math.max.apply(Math, this.Tipiobiettivi.map(function (o) { return o.numord; })) + 1;
                }

                let NewRow = {};
                NewRow.idcampagna = this.idcampagna;
                NewRow.idtipoobiettivo = 0;
                NewRow.numord = tNumOrd;
                NewRow.percincpropweb = 0;
                NewRow.idtipobref = 0;
                this.RowSel = NewRow;
                this.SwiEdit = true;
                this.SwiNewRec = true;

            },
            CancelEdit() {
                this.SwiEdit = false;
                this.SwiNewRec = false;
            },
            DeleteRow(riftipob) {
                console.log(riftipob);
                this.errors = [];
                let self = this;
                var Link = 'api/_gen/CampagneWeb_Tipiobiettivi/' + this.RowSel.id;
                axios.delete(Link)
                    .then(response => {
                        console.log(response);
                        self.SwiEdit = false;
                        self.SwiNewRec = false;
                        self.RowSel = {};
                        self.GetData();
                    }).catch(e => {
                        self.errors.push(e)
                    });
            },
            EditRow(riftipob) {
                this.RifRowSel = riftipob;
                this.RowSel = this.Tipiobiettivi.find(e => e.idtipoobiettivo == riftipob);
                this.SwiEdit = true;

            },
            GetData() {
                let self = this;
                var Link = 'api/_gen/CampagneWeb_Tipiobiettivi/childof/' + this.idcampagna;
                axios.get(Link)
                    .then(response => {
                        self.Tipiobiettivi = response.data.result.sort((a, b) => (a.numord > b.numord) ? 1 : ((b.numord > a.numord) ? -1 : 0));
                    }).catch(e => {
                        self.errors.push(e)
                    });

            },
            GetTipObDesc(riftipob) {
                var tTipo = this.$store.state.TipiOb.find(e => e.id == riftipob);
                return tTipo !== undefined ? tTipo.descon : '';
            },
            SaveRow() {
                this.errors = [];
                let self = this;
                var Link = 'api/_gen/CampagneWeb_Tipiobiettivi';
                if (!this.isNew) {
                    console.log('new');
                    axios.post(Link, this.RowSel)
                        .then(() => {
                            self.SwiEdit = false;
                            self.SwiNewRec = false;
                            self.RowSel = {};
                            self.GetData();
                        })
                        .catch(e => {
                            self.errors.push(e);
                        });
                }
                else {
                    console.log('edit');
                    axios.put(Link, this.RowSel)
                        .then(resp => {
                            console.log(resp);
                            self.SwiEdit = false;
                            self.SwiNewRec = false;
                            self.RowSel = {};
                            self.GetData();
                        })
                        .catch(e => {
                            self.errors.push(e);
                        });
                }
            }
        },
        mounted() {
            this.GetData();
        }
    }
</script>